<template>
    <v-dialog
        v-model="modalValue"
        scrollable
        max-width="550px"
        persistent
        content-class="ds-dialog"
    >
        <v-card class="ds-modal-card" elevation="2" dusk="saveCredentialModal">
            <modal-toolbar
                :title="credentialId ? 'Credential Details' : 'New Credential'"
                @close="onClose"/>

            <v-card-text class="ds-modal-content">
                <v-form
                    ref="credentialForm"
                    :lazy-validation="false"
                    class="pt-2"
                >
                    <v-row>
                        <div v-show="isFetchingDetails" style="height:100%">
                            <loading-spinner :value="true"></loading-spinner>
                        </div>

                        <v-col cols="12" class="ds-modal-input-container">
                            <v-text-field
                                name="credential-name"
                                label="Credential Name"
                                v-model.trim="credential.name"
                                :error-messages="api.hasError('name')"
                                :rules="rules.required"
                                class="ds-modal-input"
                                autocomplete="off"
                                outlined
                                clearable
                            />
                        </v-col>

                        <v-col cols="12" class="ds-modal-input-container">
                            <v-select
                                name="credential-locations"
                                label="Applied in Locations"
                                :items="locations"
                                item-value="_id"
                                item-text="name"
                                v-model="credential.locations"
                                :error-messages="api.hasError('locations')"
                                :rules="rules.requiredArrayNotEmpty"
                                :menu-props="{ 'offset-y': true, 'bottom': true, 'content-class': 'fixed-menu-content-class'}"
                                class="ds-modal-input"
                                autocomplete="off"
                                chips
                                deletable-chips
                                outlined
                                multiple
                                clearable
                                @change="onChangeLocations"
                            >
                                <template v-slot:prepend-item>
                                    <div class="d-flex flex-column justify-center align-center">
                                        <v-btn
                                            color="primary"
                                            text
                                            block
                                            @click="toggleSelectAllLocations"
                                        >
                                            {{ allLocationsSelected ? 'Deselect All' : 'Select All' }}
                                        </v-btn>
                                    </div>
                                </template>
                            </v-select>
                        </v-col>

                        <v-col cols="12" class="ds-modal-input-container">
                            <v-select
                                :key="staffGroupsKey"
                                name="credential-staff-groups"
                                label="Applied in Staff Groups"
                                :items="filteredStaffGroups"
                                item-value="_id"
                                item-text="name"
                                v-model="credential.staffGroups"
                                :error-messages="api.hasError('staff_groups')"
                                :disabled="staffGroupsDisabled"
                                :hint="staffGroupsHint"
                                :rules="rules.requiredArrayNotEmpty"
                                :menu-props="{ 'offset-y': true, 'bottom': true, 'content-class': 'fixed-menu-content-class'}"
                                persistent-hint
                                class="ds-modal-input"
                                autocomplete="off"
                                chips
                                deletable-chips
                                outlined
                                multiple
                                clearable
                            />
                        </v-col>
                    </v-row>

                    <v-row class="mt-0">
                        <v-col
                            :class="{ 'd-flex flex-row justify-space-between': Boolean(credentialId), 'd-flex flex-row justify-end': Boolean(!credentialId) }">
                            <v-btn
                                v-if="Boolean(credentialId)"
                                text
                                color="error"
                                @click="onClickDeleteCredential"
                            >
                                Delete Credential
                            </v-btn>
                            <v-btn
                                outlined
                                color="primary"
                                :disabled="isSaveDisabled"
                                :loading="isSaving"
                                @click="onSave"
                            >
                                Save
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <delete-modal
            v-model="deleteCredentialModalValue"
            title="Delete Credential"
            :message="deleteMessage"
            :is-deleting="isDeleting"
            @onDelete="onDeleteCredential"
        />
        <unsaved-changes-dialog ref="unsavedChangesDialog"/>
    </v-dialog>
</template>

<script>
import UnsavedChangesDialog from "../../../../../components/modals/UnsavedChangesDialog";
import ModalToolbar from "../../../../../components/modals/components/ModalToolbar";
import LoadingSpinner from "../../../../../components/loaders/LoadingSpinner";
import DeleteModal from "../DeleteModal";
import validationRules from "../../../../../lib/mixins/validationRules";

export default {
    name: "SaveCredentialModal",
    components: {UnsavedChangesDialog, ModalToolbar, LoadingSpinner, DeleteModal},
    mixins: [validationRules],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        credentialId: {
            type: String,
            default: null,
        }
    },
    data() {
        return {
            prevCredential: {},
            credential: {},
            deleteCredentialModalValue: false,
            isFetchingDetails: false,
            isSaving: false,
            isDeleting: false,
            deleteMessage: '',
            locations: [],
            staffGroups: [],
            filteredStaffGroups: [],
            staffGroupsKey: 1,
            api: new formHelper(),
        }
    },
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        credentialFormRef() {
            return this.$refs.credentialForm
        },
        isDirty() {
            return !_.isEqual(this.prevCredential, this.credential)
        },
        isSaveDisabled() {
            return this.isSaving || this.isDeleting || !this.isDirty
        },
        isDeleteDisabled() {
            return this.isSaving || this.isDeleting
        },
        staffGroupsDisabled() {
            return Array.isArray(this.credential.locations) ? this.credential.locations.length < 1 : true
        },
        staffGroupsHint() {
            return this.staffGroupsDisabled ? 'You must select a Location first' : ''
        },
        allLocationsSelected() {
            if (this.$authIsAdministrator) {
                return this.credential &&
                    Array.isArray(this.credential.locations) &&
                    Array.isArray(this.locations) &&
                    this.credential.locations.filter(location => !this.disabledLocationsMap.hasOwnProperty(location)).length === this.locations.filter(location => !location.disabled).length
            } else {
                return this.credential && Array.isArray(this.credential.locations) && Array.isArray(this.locations) && this.credential.locations.length === this.locations.length
            }
        },
        authUserLocationsMap() {
            if (this.$user && Array.isArray(this.$user.locations)) {
                return this.$user.locations.reduce((locations, _id) => ({
                    ...locations,
                    [_id]: true
                }), {})
            }
            return {}
        },
        disabledLocationsMap() {
            if (Array.isArray(this.locations)) {
                return this.locations.reduce((locations, location) => {
                    if (location.disabled) {
                        return {
                            ...locations,
                            [location._id]: true
                        }
                    }
                    return locations
                }, {})
            }
            return {}
        }
    },
    methods: {
        onClose() {
            if (this.isDirty) {
                this.showDiscardChangesModal()
                return
            }

            this.closeModal()
        },
        closeModal() {
            this.modalValue = false
            this.resetModal()
        },
        resetModal() {
            this.prevCredential = {}
            this.credential = {}
            this.deleteMessage = ''
            this.locations = []
            this.staffGroups = []
            this.filteredStaffGroups = []
            if (this.credentialFormRef && this.credentialFormRef.resetValidation) {
                this.credentialFormRef.resetValidation()
            }
            this.api.clearErrors()
        },
        showDiscardChangesModal() {
            this.$refs.unsavedChangesDialog.open()
                .then(() => {
                    this.closeModal()
                })
                .catch(() => {
                })
        },
        onClickDeleteCredential() {
            this.deleteCredentialModalValue = true
            this.deleteMessage = `
                <p>
                    You are about to delete <b>${this.prevCredential.name}</b> from all Locations and Staff Groups where it is currently applied.
                </p>
            `
        },
        onDeleteCredential() {
            this.isDeleting = true
            this.api.delete(`/requirement/${this.credentialId}`).then(() => {
                this.$snackNotify('success', 'Credential deleted.')
                this.deleteCredentialModalValue = false
                this.closeModal()
                this.$emit('onDelete')
            }).catch(console.log).finally(() => this.isDeleting = false)
        },
        fetchDetails(credentialId) {
            this.isFetchingDetails = true
            this.api.get(credentialId ? `/requirement/details-for-modal/${credentialId}` : '/requirement/details-for-modal')
                .then(result => {
                    if (result && result.data) {
                        if (Array.isArray(result.data.locations)) {
                            if (this.$authIsAdministrator) {
                                this.locations = result.data.locations.map(location => ({
                                    ...location,
                                    disabled: !this.authUserLocationsMap.hasOwnProperty(location._id)
                                }))
                            } else {
                                this.locations = Array.from(result.data.locations)
                            }
                        }
                        if (Array.isArray(result.data.staff_groups)) {
                            this.staffGroups = Array.from(result.data.staff_groups)
                        }
                        if (this.credentialId && result.data.requirement) {
                            const credential = {
                                name: result.data.requirement.name,
                                locations: _.intersectionBy(
                                    result.data.requirement.locations,
                                    this.locations,
                                    '_id'
                                ).map(location => location._id),
                                staffGroups: result.data.requirement.staff_groups.map(sg => sg._id),
                            }
                            this.prevCredential = {...credential}
                            this.credential = {...credential}
                            this.filterStaffGroupsByLocation()
                        }
                    }
                }).catch(console.log).finally(() => this.isFetchingDetails = false)
        },
        onSave() {
            if (!this.credentialFormRef.validate()) {
                return false
            }

            this.isSaving = true
            this.api.post('/requirement/save', {
                ...Boolean(this.credentialId) && {_id: this.credentialId},
                name: this.credential.name,
                location_ids: this.credential.locations,
                staff_group_ids: this.credential.staffGroups,
            }).then(() => {
                if (this.credentialId) {
                    this.$snackNotify('success', 'Changes to Credential saved.')
                } else {
                    this.$snackNotify('success', 'New Credential created.')
                }
                this.closeModal()
                this.$emit('onSave')
            }).catch(console.log).finally(() => this.isSaving = false)
        },
        onChangeLocations() {
            this.filterStaffGroupsByLocation()
        },
        filterStaffGroupsByLocation() {
            const hasLocations = Array.isArray(this.credential.locations) && this.credential.locations.length > 0;

            if (!hasLocations) {
                this.filteredStaffGroups = [];
                this.credential.staffGroups = [];
                this.staffGroupsKey++;
                return;
            }

            const locationIds = new Set(this.credential.locations);
            this.filteredStaffGroups = this.staffGroups.filter(staff_group =>
                locationIds.has(staff_group.location_id)
            );

            if (!Array.isArray(this.credential.staffGroups)) {
                return;
            }

            this.credential.staffGroups = this.credential.staffGroups.filter(id =>
                this.filteredStaffGroups.some(staff_group => staff_group._id === id)
            );
        },
        toggleSelectAllLocations() {
            this.$nextTick(() => {
                if (!this.$authIsAdministrator) {
                    this.credential = {
                        ...this.credential,
                        locations: this.allLocationsSelected ? [] : this.locations.map(location => location._id),
                    };

                    this.onChangeLocations()
                    return;
                }

                const disabledLocations = this.credential && Array.isArray(this.credential.locations)
                    ? this.credential.locations.filter(location => this.disabledLocationsMap.has(location))
                    : [];

                this.credential = {
                    ...this.credential,
                    locations: this.allLocationsSelected
                        ? disabledLocations
                        : [
                            ...disabledLocations,
                            ...this.locations
                                .filter(location => !location.disabled)
                                .map(location => location._id)
                        ],
                };

                this.onChangeLocations()
            });
        }
    },
    watch: {
        value(open) {
            if (open) {
                this.fetchDetails(this.credentialId)
            }
        }
    }
}
</script>

<style scoped>

</style>
