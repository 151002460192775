<template>
    <custom-icon icon="mdi-calendar-star" color="rgb(128, 159, 255)"></custom-icon>
</template>

<script>
import CustomIcon from "./CustomIcon";

export default {
    name: "BlueCalendarIcon",
    components: {CustomIcon}
}
</script>

<style scoped>

</style>
