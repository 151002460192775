<template>
    <v-dialog
        v-model="modalValue"
        scrollable
        persistent
        max-width="600px"
    >
        <v-card>
            <v-toolbar class="informational-modal" elevation="0">
                <v-toolbar-title class="text--h2">Time Off</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="onClose">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-form
                    ref="autoAssignFormRef"
                    :lazy-validation="false"
                >
                    <v-container>
                        <v-row class="mt-5 row-min-width">
                            <div v-show="isFetchingDetails" style="height:100%">
                                <loading-spinner :value="true"></loading-spinner>
                            </div>
                            <v-col v-if="readonly && status" cols="12" class="ds-modal-input-container">
                                <time-off-request-status :status="status"/>
                            </v-col>
                            <v-col v-if="readonly && status" cols="12" class="ds-modal-input-container my-6">
                                <event-details-row icon="mdi-account" :text="user" :font-size="24"/>
                            </v-col>
                            <v-col cols="12" class="ds-modal-input-container">
                                <p class="text--h4">
                                    Date or Date Range
                                </p>
                            </v-col>
                            <v-col class="ds-modal-input-container">
                                <date-range-picker
                                    v-model="selectedDateRange"
                                    :key="dateRangeKey"
                                    :readonly="readonly"
                                />
                            </v-col>
                            <v-col cols="auto" class="ds-modal-input-container">
                                <v-checkbox
                                    v-model="selectedAllDay"
                                    class="my-0 py-0"
                                    color="#4253c4"
                                    style="margin-top: 18px !important;"
                                    @change="onChangeAllDay"
                                    :readonly="readonly"
                                >
                                    <template v-slot:label>
                                        <span class="text--paragraph black--text font-weight-bold">
                                            All Day
                                        </span>
                                    </template>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="6" class="ds-modal-input-container">
                                <time-picker
                                    v-model="startTime"
                                    label="Start Time"
                                    :error-messages="api.hasError('start_time')"
                                    :max="maxStartTime"
                                    :disabled="selectedAllDay"
                                    :readonly="readonly"
                                    @onClearTime="onClearStartTime"/>
                            </v-col>
                            <v-col cols="12" lg="6" class="ds-modal-input-container">
                                <time-picker
                                    v-model="endTime"
                                    label="End Time"
                                    :error-messages="api.hasError('end_time')"
                                    :min="minEndTime"
                                    :disabled="selectedAllDay"
                                    :readonly="readonly"
                                    @onClearTime="onClearEndTime"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="ds-modal-input-container">
                                <p class="text--h4">
                                    Request Details
                                </p>
                            </v-col>
                            <v-col cols="12" class="ds-modal-input-container">
                                <v-select
                                    v-model="request"
                                    label="Request"
                                    :items="requestOptions"
                                    item-value="_id"
                                    item-text="name"
                                    :error-messages="api.hasError('request')"
                                    :rules="rules.required"
                                    :menu-props="{ 'offset-y': true }"
                                    class="ds-modal-input"
                                    autocomplete="off"
                                    outlined
                                    :clearable="!readonly"
                                    :readonly="readonly"
                                />
                            </v-col>
                            <v-col cols="12" class="ds-modal-input-container">
                                <v-textarea
                                    label="Note (Optional)"
                                    v-model="note"
                                    :error-messages="api.hasError('note')"
                                    rows="4"
                                    class="ds-modal-input"
                                    autocomplete="off"
                                    outlined
                                    :readonly="readonly"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <time-off-tooltip/>
                <v-spacer></v-spacer>
                <template v-if="timeOffRequestViewUrl">
                    <v-btn
                        v-if="!isFetchingDetails && status !== 'cancelled'"
                        color="primary"
                        outlined
                        :href="timeOffRequestViewUrl"
                    >
                        View
                    </v-btn>
                </template>
                <template v-else-if="!readonly">
                    <v-btn
                        color="primary"
                        text
                        :disabled="isCreating"
                        @click="onClose"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="secondary"
                        :loading="isCreating"
                        @click="onCreate"
                    >
                        Submit
                    </v-btn>
                </template>
                <template v-else-if="canCancel">
                    <v-btn
                        color="error"
                        text
                        :loading="isCancelling"
                        @click="onCancel"
                    >
                        Cancel
                    </v-btn>
                </template>
                <template v-else-if="canViewActions && status === 'requested'">
                    <v-btn
                        color="error"
                        text
                        :loading="isDenying"
                        :disabled="!canTakeActions || isApproving"
                        @click="onDeny"
                    >
                        Deny
                    </v-btn>
                    <v-btn
                        color="primary"
                        outlined
                        :loading="isApproving"
                        :disabled="!canTakeActions || isDenying"
                        @click="onApprove"
                    >
                        Approve
                    </v-btn>
                </template>
            </v-card-actions>
        </v-card>
        <unsaved-changes-dialog ref="unsavedChangesDialog"/>
    </v-dialog>
</template>

<script>
import validationRules from "../../../lib/mixins/validationRules";
import TimeOffRequestStatus from "./TimeOffRequestStatus";
import EventDetailsRow from "../../common/EventDetails/EventDetailsRow";
import DateRangePicker from "./DateRangePicker";
import TimePicker from "../../common/TimeRangePicker/TimePicker";
import TimeOffTooltip from "./TimeOffTooltip";
import LoadingSpinner from "../../loaders/LoadingSpinner";
import UnsavedChangesDialog from "../../modals/UnsavedChangesDialog";

export default {
    name: "TimeOffRequestModal",
    mixins: [validationRules],
    components: {
        TimeOffRequestStatus,
        EventDetailsRow,
        DateRangePicker,
        TimePicker,
        TimeOffTooltip,
        LoadingSpinner,
        UnsavedChangesDialog
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        userId: {
            type: String,
            default: null
        },
        timeOffRequestId: {
            type: String,
            default: null
        },
        adminHasOneSameLocationWithStaff: {
            type: Boolean,
            default: false,
        },
        timeOffRequestViewUrl: {
            type: String,
            default: null,
        }
    },
    data: () => ({
        status: null,
        user: null,
        selectedDateRange: [],
        dateRangeKey: 1111,
        selectedAllDay: false,
        startTime: null,
        endTime: null,
        request: null,
        requestOptions: [{
            _id: 'unpaid',
            name: 'Unpaid Time Off'
        }, {
            _id: 'paid',
            name: 'Vacation/PTO'
        }],
        note: null,
        matchAgainst: {
            selectedDateRange: [],
            selectedAllDay: false,
            startTime: null,
            endTime: null,
            request: null,
            note: null,
        },
        isFetchingDetails: false,
        isCreating: false,
        isCancelling: false,
        isDenying: false,
        isApproving: false,
        api: new formHelper()
    }),
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        autoAssignFormRef() {
            return this.$refs.autoAssignFormRef
        },
        maxStartTime() {
            if (Array.isArray(this.selectedDateRange) &&
                this.selectedDateRange.length === 2 &&
                this.selectedDateRange[0] !== this.selectedDateRange[1]
            ) {
                return null
            }
            return this.endTime
        },
        minEndTime() {
            if (Array.isArray(this.selectedDateRange) &&
                this.selectedDateRange.length === 2 &&
                this.selectedDateRange[0] !== this.selectedDateRange[1]
            ) {
                return null
            }
            return this.startTime
        },
        readonly() {
            return Boolean(!!this.timeOffRequestId)
        },
        canCancel() {
            return this.$authIsStaff && this.status === 'requested'
        },
        canViewActions() {
            return this.$authIsOwner || this.$authIsAdministrator
        },
        canTakeActions() {
            if (this.$authIsOwner) {
                return true
            } else if (this.$authIsAdministrator) {
                return this.adminHasOneSameLocationWithStaff && this.$userHasPermission(this.$user, this.$config.permissions.TIME_OFF_REQUEST.RESPOND)
            } else {
                return false
            }
        },
        isDirty() {
            if (this.readonly) {
                return false
            }
            return !_.isEqual(this.matchAgainst, {
                selectedDateRange: this.selectedDateRange,
                selectedAllDay: this.selectedAllDay,
                startTime: this.startTime,
                endTime: this.endTime,
                request: this.request,
                note: this.note,
            })
        },
        globalTimezone() {
            return this.$root.globalTimezone
        },
    },
    methods: {
        onClose() {
            if (this.isDirty) {
                this.showDiscardChangesModal()
                return
            }

            this.closeModal()
        },
        closeModal() {
            this.modalValue = false
            this.$emit('onClose')
        },
        showDiscardChangesModal() {
            this.$refs.unsavedChangesDialog.open()
                .then(() => {
                    this.closeModal()
                })
                .catch(() => {
                })
        },
        onClearStartTime() {
            this.startTime = null
        },
        onClearEndTime() {
            this.endTime = null
        },
        onChangeAllDay() {
            if (this.selectedAllDay) {
                this.startTime = '00:00'
                this.endTime = '23:59'
            } else {
                this.startTime = null
                this.endTime = null
            }
        },
        fetchDetails(timeOffRequestId) {
            this.isFetchingDetails = true
            this.api.get(`/time-off-requests/details/${timeOffRequestId}`)
                .then(({data}) => {
                    if (data) {
                        this.status = data.status
                        this.user = data.user ? `${data.user.first_name} ${data.user.last_name}` : null
                        const start = moment(data.start_date).tz(data.timezone)
                        const end = moment(data.end_date).tz(data.timezone)
                        const startDate = start.format('YYYY-MM-DD')
                        const endDate = end.format('YYYY-MM-DD')
                        const startTime = start.format('HH:mm')
                        const endTime = end.format('HH:mm')
                        this.dateRangeKey = this.dateRangeKey + 1
                        if (startDate !== endDate) {
                            this.selectedDateRange = [startDate, endDate]
                        } else {
                            this.selectedDateRange = [startDate]
                        }
                        this.startTime = startTime
                        this.endTime = endTime
                        if (startTime === '00:00' && endTime === '23:59') {
                            this.selectedAllDay = true
                        }
                        this.request = data.type
                        if (data.note) {
                            this.note = data.note
                        }
                    }
                })
                .catch(console.log)
                .finally(() => {
                    this.isFetchingDetails = false
                })
        },
        validateForm() {
            if (this.autoAssignFormRef) {
                return this.autoAssignFormRef.validate()
            }
            return false
        },
        onCreate() {
            if (this.validateForm()) {
                const startDate = Array.isArray(this.selectedDateRange) && this.selectedDateRange.length > 0 ? this.selectedDateRange[0] : null
                const endDate = Array.isArray(this.selectedDateRange) ? this.selectedDateRange.length === 1 ? this.selectedDateRange[0] : this.selectedDateRange[1] : null
                if (startDate && endDate) {
                    this.isCreating = true
                    this.api.post('/time-off-requests/create', {
                        user_id: this.userId,
                        start_date: `${startDate} ${this.startTime}`,
                        end_date: `${endDate} ${this.endTime}`,
                        timezone: this.globalTimezone,
                        type: this.request,
                        note: this.note
                    }).then(({message}) => {
                        message('Time Off Request submitted. You will be notified when the request is reviewed by a Manager.')
                        this.closeModal()
                        this.$emit('onCreate')
                    }).catch(console.log).finally(() => {
                        this.isCreating = false
                    })
                }
            }
        },
        onCancel() {
            this.isCancelling = true
            this.api.post(`/time-off-requests/cancel/${this.timeOffRequestId}`)
                .then(({message}) => {
                    message('Time Off Request cancelled.')
                    this.closeModal()
                    this.$emit('onCancel')
                }).catch(console.log).finally(() => {
                this.isCancelling = false
            })
        },
        onDeny() {
            this.isDenying = true
            this.api.post(`/time-off-requests/deny/${this.timeOffRequestId}`)
                .then(({message}) => {
                    message('Time Off Request denied.')
                    this.closeModal()
                    this.$emit('onDeny')
                }).catch(console.log).finally(() => {
                this.isDenying = false
            })
        },
        onApprove() {
            this.isApproving = true
            this.api.post(`/time-off-requests/approve/${this.timeOffRequestId}`)
                .then(({message}) => {
                    message('Time Off Request approved.')
                    this.closeModal()
                    this.$emit('onApprove')
                }).catch(console.log).finally(() => {
                this.isApproving = false
            })
        }
    },
    mounted() {
        if (this.timeOffRequestId) {
            this.fetchDetails(this.timeOffRequestId)
        }
    }
}
</script>

<style scoped>

</style>
