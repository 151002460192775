<template>
    <card-display>
        <div class="d-flex flex-column" style="row-gap: 10px;">
            <user-details
                :first-name="user.first_name"
                :last-name="user.last_name"
                :avatar="user.avatar"
                :shifts-count="user.shift_count"
                :shifts-duration="user.total_shift_duration"
            />
            <div class="d-flex flex-column" style="row-gap: 10px;">
                <shift-time-counts
                    v-if="user.shift_time_counts.length > 0"
                    :shift-time-counts="user.shift_time_counts"
                />
                <div class="d-flex flex-column" style="row-gap: 8px;">
                    <payroll-period
                        v-for="(payrollPeriod, index) in user.payroll_periods"
                        :key="index"
                        :payroll-period="payrollPeriod"
                        :timezone="timezone"
                    />
                </div>
            </div>
        </div>
    </card-display>
</template>

<script>
import CardDisplay from "../CardDisplay";
import UserDetails from "../UserDetails";
import ShiftTimeCounts from "../ShiftTimeCounts";
import PayrollPeriod from "../PayrollPeriod";

export default {
    name: "UserDetailsCard",
    components: {UserDetails, CardDisplay, ShiftTimeCounts, PayrollPeriod},
    props: {
        user: {
            type: Object,
            default: () => ({})
        },
        timezone: {
            type: String,
            default: null
        }
    }
}
</script>

<style scoped>

</style>
