<template>
    <div class="d-flex flex-row justify-space-between align-center" style="column-gap: 20px;">
        <div class="d-flex flex-row align-center" style="column-gap: 10px; min-width: 0;">
            <custom-avatar
                :user="user"
                :size="36"
                user-chip/>
            <div class="text--paragraph full-name-container">
                {{ firstName }} {{ lastName }}
            </div>
        </div>
        <div class="d-flex flex-row align-center" style="column-gap: 10px;">
            <shifts-count :count="shiftsCount"/>
            <shifts-duration :duration="shiftsDuration"/>
        </div>
    </div>
</template>

<script>
import CustomAvatar from "../../../avatar/CustomAvatar";
import ShiftsCount from "./Stat/ShiftsCount";
import ShiftsDuration from "./Stat/ShiftsDuration";

export default {
    name: "UserDetails",
    components: {CustomAvatar, ShiftsCount, ShiftsDuration},
    props: {
        firstName: {
            type: String,
            default: null
        },
        lastName: {
            type: String,
            default: null
        },
        avatar: {
            type: String,
            default: null
        },
        shiftsCount: {
            type: Number,
            default: 0
        },
        shiftsDuration: {
            type: Number,
            default: 0
        }
    },
    computed: {
        user() {
            return {
                first_name: this.firstName,
                last_name: this.lastName,
                avatar: this.avatar,
            }
        }
    }
}
</script>

<style scoped>
.full-name-container {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
