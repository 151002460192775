<template>
    <v-row v-if="showLoadingSkeleton">
        <v-col cols="12" class="pb-0">
            <v-skeleton-loader
                type='table-thead, table-row-divider@12, table-tfoot'
            ></v-skeleton-loader>
        </v-col>
    </v-row>
    <v-container fluid v-else>
        <v-row>
            <v-col cols="12" md="auto" class="pt-1 text-center text-md-left">
                <v-btn-toggle
                    v-model="calendar_type"
                    color="secondary"
                    mandatory
                >
                    <v-btn value="week">
                        Weekly
                    </v-btn>
                    <v-btn value="month">
                        Monthly
                    </v-btn>
                </v-btn-toggle>
            </v-col>

            <v-col cols="12" md="auto" class="pt-1 text-center mx-auto">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-btn
                            icon
                            @click="calendar_prev"
                            :disabled="api.busy"
                            dusk="calendarPrevious"
                        >
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>

                        <span class="text-center mx-1 d-inline-block text--h2" style="color: #000000;">{{ calendar_date_label }}</span>

                        <v-btn
                            icon
                            @click="calendar_next"
                            :disabled="api.busy"
                            dusk="calendarNext"
                        >
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12" md="auto" class="pt-1 text-center text-md-right">
                <options-select
                    title="Add"
                    color="secondary"
                    :items="availabilityTypes"
                    :disabled="api.busy"
                    :block="$vuetify.breakpoint.xsOnly"
                    @onOptionChanged="onAdd"
                ></options-select>
                <v-tooltip right v-if="!editable">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            color="error"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            small
                        >
                            mdi-information-outline
                        </v-icon>
                    </template>
                    <span>You may not be authorized to edit availability</span>
                </v-tooltip>
            </v-col>

            <v-col cols="12" class="pa-0 loading-bar">
                <v-progress-linear v-if="api.busy" indeterminate></v-progress-linear>
            </v-col>

            <v-col cols="12" class="pt-0">
                <div id="calendar-wrapper">
                    <div v-if="showCalendarOverlay" id="calendar-loading-overlay">
                        <span><v-progress-circular color="primary" dusk="calendarCircularLoader" indeterminate></v-progress-circular></span>
                    </div>

                    <v-calendar
                        ref="availability_calendar"
                        id="availability-calendar"
                        v-model="selected_date"
                        :type="calendar_type"
                        :interval-minutes="120"
                        :interval-count="12"
                        :weekdays="[0, 1, 2, 3, 4, 5, 6]"
                        :events="formatted_events"
                        :event-color="getEventColor"
                        event-start="start"
                        event-end="end"
                        @click:event="availabilityPeriodClicked"
                    >
                        <template v-slot:event="{ event }">
                            <div class="text-center">{{ event.name }}</div>
                        </template>
                    </v-calendar>
                </div>
            </v-col>
        </v-row>

        <availability-event-modal
            v-model="availabilityPeriodModal"
            :editable="editable"
            :user="user"
            :availability-period="selectedEvent"
            :key="refresh_modal_component"
            :availability-calendar-date="selected_date"
            @availabilityUpdated="availabilityUpdated"
            @availabilityRemoved="availabilityRemoved"
            @availabilityMerged="loadEvents"
            @refreshRequired="loadEvents"
            @upcomingAvailabilityInfoReceived="upcomingAvailabilityInfoReceived"
        ></availability-event-modal>

        <time-off-request-modal
            v-if="timeOffRequestModal"
            v-model="timeOffRequestModal"
            :user-id="userProp?._id"
            :time-off-request-id="timeOffRequestId"
            :admin-has-one-same-location-with-staff="adminHasOneSameLocationWithStaff"
            @onClose="timeOffRequestId = null"
            @onCreate="loadEvents"
            @onCancel="loadEvents"
            @onDeny="loadEvents"
            @onApprove="loadEvents"
        />
    </v-container>
</template>

<script>
    import AvailabilityEventModal from "./AvailabilityEventModal";
    import TimeOffRequestModal from "./TimeOffRequestModal";
    import OptionsSelect from "../../reporting/FormInputs/OptionsSelect";

    export default {
        name: "NewUserAvailability",
        components: {AvailabilityEventModal, TimeOffRequestModal, OptionsSelect},
        props: {
            userProp: {
                default: Object,
                type: Object
            },
        },
        data() {
            return {
                days: [
                    {short: 'Mon', full: 'Monday'},
                    {short: 'Tue', full: 'Tuesday'},
                    {short: 'Wed', full: 'Wednesday'},
                    {short: 'Thu', full: 'Thursday'},
                    {short: 'Fri', full: 'Friday'},
                    {short: 'Sat', full: 'Saturday'},
                    {short: 'Sun', full: 'Sunday'},
                ],
                availabilityTypes: [
                    {text: 'Availability', value: 'availability'}
                ],
                api: new formHelper(),
                ownProfile: this.userProp._id === this.$user._id,
                themePrimaryColor: this.$vuetify.theme.themes.light.primary,
                typeToLabel: {
                    week: 'Weekly',
                    month: 'Monthly'
                },
                calendar_type: 'month',
                selected_date: null,

                availabilityPeriods: [],
                availabilityPeriodModal: false,
                timeOffRequestModal: false,
                timeOffRequestId: null,
                refresh_modal_component: 0,
                selectedEvent: null,
                user: Object.assign({}, this.userProp),
                showLoadingSkeleton: true,
                showCalendarOverlay: true,
                PERMISSIONS: this.$config.permissions,

                authUser: this.$user,
            }
        },
        computed: {
            editable() {

                if (this.$authIsOwner) {
                    return true
                }

                if (this.ownProfile) {
                    return this.$userHasPermission(this.$user, this.PERMISSIONS.AVAILABILITY.EDIT_OWN)
                }

                return this.$userHasPermission(this.$user, this.PERMISSIONS.AVAILABILITY.EDIT_OTHERS)
            },
            globalTimezone() {
                return this.$root.globalTimezone
            },

            formatted_events() {
                return this.availabilityPeriods.map(ap => {
                    ap.start = moment(ap.start_date).tz(this.globalTimezone).format('YYYY-MM-DD HH:mm')
                    ap.end = moment(ap.end_date).tz(this.globalTimezone).format('YYYY-MM-DD HH:mm')
                    ap.name = moment(ap.start_date).tz(this.globalTimezone).format('h:mm A') + ' - ' + moment(ap.end_date).tz(this.globalTimezone).format('h:mm A')

                    return ap
                })
            },

            calendar_date_label() {
                if (!this.selected_date) {
                    return '---'
                }

                let date = moment(this.selected_date)

                if (this.calendar_type === 'week') {
                    return date.clone().startOf('week').format('MMM D') + ' - ' + date.clone().endOf('week').format('MMM D')
                }

                return date.format('MMMM YYYY')
            },

            adminHasOneSameLocationWithStaff() {
                if (this.$authIsAdministrator) {
                    const adminLocations = this.$user?.locations
                    const staffLocations = this.userProp?.locations
                    if (Array.isArray(adminLocations) && Array.isArray(staffLocations)) {
                        return _.intersection(adminLocations, staffLocations).length > 0
                    }
                }
                return false
            },

            canCreateTimeOffRequest() {
                if (this.$authIsOwner) {
                    return true
                } else if (this.$authIsAdministrator) {
                    return this.adminHasOneSameLocationWithStaff && this.$userHasPermission(this.$user, this.PERMISSIONS.TIME_OFF_REQUEST.RESPOND)
                } else if (this.$authIsManager) {
                    return false
                } else if (this.$authIsStaff) {
                    return this.$userHasPermission(this.$user, this.PERMISSIONS.TIME_OFF_REQUEST.REQUEST)
                } else {
                    return false
                }
            }
        },
        methods: {
            openAvailabilityPeriodModal() {
              this.availabilityPeriodModal = true
            },

            onAdd(type) {
                if (type.value === 'availability') {
                    this.openAvailabilityPeriodModal()
                } else if (type.value === 'time-off') {
                    this.timeOffRequestModal = true
                }
            },

            loadEvents_debounced: _.debounce(function () {
                this.loadEvents()
            }, 400),

            loadEvents() {
                let start_date, end_date

                if (!this.selected_date) {
                    return
                }

                let date = moment(this.selected_date)

                if (this.calendar_type === 'week') {
                    start_date = date.clone().startOf('week').format('YYYY-MM-DD')
                    end_date = date.clone().endOf('week').format('YYYY-MM-DD')
                } else {
                    start_date = date.clone().startOf('month').format('YYYY-MM-DD')
                    end_date = date.clone().endOf('month').format('YYYY-MM-DD')
                }

                this.clearEvents()
                this.showCalendarOverlay = true

                this.api.get('/availability/list/' + this.user._id, {params: {start_date: start_date, end_date: end_date, timezone: this.globalTimezone}})
                    .then(({data, message}) => {
                        this.availabilityPeriods = data
                        this.showLoadingSkeleton = false
                        this.showCalendarOverlay = false
                    })
            },

            clearEvents() {
                this.availabilityPeriods = []
            },

            upcomingAvailabilityInfoReceived(data) {
                this.$emit('upcomingAvailabilityInfoReceived', data)
            },

            availabilityUpdated(availability) {
                this.availabilityPeriods = this.availabilityPeriods.filter(event => event._id !== availability._id)
                this.availabilityPeriods.push(availability)
            },

            availabilityRemoved(removed_ids) {
                this.availabilityPeriods = this.availabilityPeriods.filter(event => !removed_ids.includes(event._id))
            },

            availabilityPeriodClicked(availability_period) {
                if (this.api.busy) {
                    return
                }

                if (availability_period?.event?.event_type === 'time_off_request') {
                    this.timeOffRequestModal = true
                    this.timeOffRequestId = availability_period?.event?._id
                } else {
                    let event = this.availabilityPeriods.find(period => period._id === availability_period.event._id)

                    if (event) {
                        this.selectedEvent = event
                        this.availabilityPeriodModal = true
                    }
                }
            },

            formatEventName(event) {
                return "<center>" + event.input.name + "</center>"
            },

            getEventColor(event) {
                if (event.event_type === 'time_off_request') {
                    if (event.status === this.$config.TIME_OFF_REQUEST_STATUS.APPROVED) {
                        return this.$vuetify.theme.themes.light.success
                    } else if (event.status === this.$config.TIME_OFF_REQUEST_STATUS.DENIED) {
                        return this.$vuetify.theme.themes.light.error
                    } else if (event.status === this.$config.TIME_OFF_REQUEST_STATUS.REQUESTED) {
                        return this.$vuetify.theme.themes.light.warning
                    } else {
                        return this.$vuetify.theme.themes.light.primary
                    }
                } else {
                    return this.$vuetify.theme.themes.light.primary
                }
            },

            setCalendarType(type) {
                if (this.calendar_type === type) {
                    return
                }

                this.clearEvents()
                this.showCalendarOverlay = true
                this.calendar_type = type
                this.loadEvents()
            },

            calendar_prev() {
                this.clearEvents()
                this.showCalendarOverlay = true
                this.$refs.availability_calendar.prev()
                this.loadEvents_debounced()
            },

            calendar_next() {
                this.clearEvents()
                this.showCalendarOverlay = true
                this.$refs.availability_calendar.next()
                this.loadEvents_debounced()
            },
        },

        mounted() {
            this.selected_date = moment().tz(this.globalTimezone).format('YYYY-MM-DD')

            if (this.userProp._id === this.$appData?.preselected_user?._id && this.$appData?.preselected_user?._id === this.$appData?.preselected_time_off_request?.user_id) {
                if (this.$appData?.preselected_time_off_request.start_date && this.$appData?.preselected_time_off_request.timezone) {
                    this.selected_date = moment(this.$appData?.preselected_time_off_request.start_date).tz(this.$appData?.preselected_time_off_request.timezone).format('YYYY-MM-DD')
                }
            }

            if (typeof this.userProp._id !== 'undefined') {
                this.loadEvents()
            }
        },

        created() {
            if (this.canCreateTimeOffRequest) {
                this.availabilityTypes = [
                    ...this.availabilityTypes,
                    {text: 'Time Off', value: 'time-off'}
                ]
            }
          if (this.userProp._id === this.$appData?.preselected_user?._id && this.$appData?.preselected_user?._id === this.$appData?.preselected_time_off_request?.user_id) {
            this.timeOffRequestModal = true
            this.timeOffRequestId = this.$appData?.preselected_time_off_request?._id
          }
        },

        watch: {
            availabilityPeriodModal(value) {
                if (!value) {
                    this.selectedEvent = null
                    this.refresh_modal_component++
                }
            }
        },

    }
</script>

<style scoped>
    #availability-calendar {
        border-left: none;
    }

    #availability-calendar.v-calendar-monthly {
        min-height: 550px;
    }

    #availability-calendar :deep(.v-calendar-weekly__day-label) {
        pointer-events: none !important;
    }

    #availability-calendar :deep(.v-calendar-daily_head-day-label) {
        pointer-events: none !important;
    }

    .calendar-date-label {
        width: 170px;
    }

    .loading-bar {
        min-height: 4px;
    }

    #calendar-wrapper {
        position: relative;
    }

    #calendar-loading-overlay {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.15);
        z-index: 2;
    }

    #calendar-loading-overlay span {
        position: absolute;
        top: 50%;
        left: 50%;
    }

    .weekly-btn {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }

    .monthly-btn {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
</style>
