<template>
    <card-display
        :height="cardHeight"
        :background-color="backgroundColor"
        clickable
        @onClick="onClick"
    >
        <div class="d-flex flex-column" style="row-gap: 15px;">
            <div class="text--h4">
                Open Shifts
            </div>
            <div class="d-flex flex-column" style="row-gap: 8px;">
                <open-shift-card
                    v-for="(shift, index) in openShifts"
                    :key="index"
                    :start="shift.start"
                    :end="shift.end"
                    :shifts-count="shift.shift_count"
                    :timezone="timezone"/>
            </div>
        </div>
    </card-display>
</template>

<script>
import CardDisplay from "../CardDisplay";
import OpenShiftCard from "./OpenShiftCard";

export default {
    name: "OpenShiftsCard",
    components: {CardDisplay, OpenShiftCard},
    props: {
        openShifts: {
            type: Array,
            default: () => ([])
        },
        timezone: {
            type: String,
            default: null
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        cardHeight() {
            // 12 - card display padding y
            // 20 - open shifts label
            // 15 - gap between label and shifts
            // 8 - gap between shifts
            // 50 - open shift card height
            const length = this.openShifts.length
            return 2 * 12 + 20 + 15 + 50 * length + 8 * (length - 1)
        },
        backgroundColor() {
            return this.selected ? 'rgba(200, 230, 255)' : null
        }
    },
    methods: {
        onClick() {
            this.$emit('onClick')
        }
    }
}
</script>

<style scoped>

</style>
