<template>
    <v-dialog
        v-model="modalValue"
        scrollable
        persistent
        max-width="1200px"
    >
        <v-card>
            <v-toolbar class="informational-modal" elevation="0">
                <v-toolbar-title class="text--h2">Auto-Assign Results</v-toolbar-title>
            </v-toolbar>
            <v-card-text :style="containerStyle" class="pb-0">
                <v-container fluid class="fill-height pb-0">
                    <v-row class="fill-height">
                        <v-col md="6" cols="12" :style="lhsContainerStyle">
                            <version-header
                                :start="data.schedule_start"
                                :end="data.schedule_end"
                                :timezone="data.timezone"
                                :version-index="versionIndex"
                                :version-count="data.draft.length"
                                :sync-disabled="!isCurrentVersionDirty"
                                style="margin-bottom: 12px;"
                                @onGoToPrevVersion="onGoToPrevVersion"
                                @onGoToNextVersion="onGoToNextVersion"
                                @onSyncSchedule="onSyncSchedule"
                            />
                            <scrollable-container :height="72">
                                <open-shifts-card
                                    :open-shifts="openShifts"
                                    :timezone="data.timezone"
                                    :selected="selectedOpenShifts"
                                    @onClick="onSelectOpenShifts"
                                />
                                <v-divider v-if="users.length > 0"/>
                                <div class="d-flex flex-column" style="row-gap: 8px;">
                                    <user-card
                                        v-for="user in users"
                                        :key="user._id"
                                        :first-name="user.first_name"
                                        :last-name="user.last_name"
                                        :avatar="user.avatar"
                                        :shifts-count="user.shift_count"
                                        :shifts-duration="user.total_shift_duration"
                                        :selected="user._id === selectedUserId"
                                        @onClick="onSelectUser(user._id)"
                                    />
                                </div>
                            </scrollable-container>
                        </v-col>
                        <v-col cols="12" v-if="matchDownMd">
                            <v-divider/>
                        </v-col>
                        <v-col md="6" cols="12" :style="rhsContainerStyle">
                            <OpenShiftsDetails
                                v-if="selectedOpenShifts"
                                :open-shifts="currentSchedule.open_shifts"
                                :timezone="data.timezone"
                                @onDelete="onDelete"
                            />
                            <user-details
                                v-else-if="currentUserDetails"
                                :user="currentUserDetails"
                                :timezone="data.timezone"
                                @onDelete="onDelete"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    :disabled="isPublishing"
                    @click="onClose"
                >
                    Close
                </v-btn>
                <v-btn
                    color="secondary"
                    @click="onPublishSchedule"
                    :loading="isPublishing"
                    :disabled="publishDisabled"
                >
                    Publish Schedule
                </v-btn>
            </v-card-actions>
            <unsaved-changes-dialog ref="unsavedChangesDialog"/>
        </v-card>
    </v-dialog>
</template>

<script>
import VersionHeader from "./VersionHeader";
import ScrollableContainer from "./ScrollableContainer";
import CardDisplay from "./CardDisplay";
import OpenShiftCard from "./OpenShiftsCard/OpenShiftCard";
import UserCard from "./UserCard";
import ShiftCard from "./ShiftCard";
import OpenShiftsCard from "./OpenShiftsCard/OpenShiftsCard";
import OpenShiftsExpandedCard from "./OpenShiftsCard/OpenShiftsExpandedCard";
import OpenShiftsDetails from "./Details/OpenShiftsDetails";
import UserDetails from "./Details/UserDetails";
import UnsavedChangesDialog from "../../../modals/UnsavedChangesDialog";

export default {
    name: "AutoAssignResultsModal",
    components: {
        ScrollableContainer,
        OpenShiftsExpandedCard,
        OpenShiftCard,
        CardDisplay,
        VersionHeader,
        UserCard,
        ShiftCard,
        OpenShiftsCard,
        OpenShiftsDetails,
        UserDetails,
        UnsavedChangesDialog
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        data: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            innerWidth: window.innerWidth,
            versionIndex: 0,
            selectedOpenShifts: false,
            selectedUserId: null,
            stateData: _.cloneDeep(this.data),
            isPublishing: false,
            api: new formHelper()
        }
    },
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        innerHeight() {
            return window.innerHeight
        },
        containerStyle() {
            return {
                height: `${this.innerHeight}px`,
                overflowY: this.matchDownMd ? 'auto' : 'hidden',
                paddingLeft: '12px',
                paddingRight: '12px'
            }
        },
        lhsContainerStyle() {
            return this.matchDownMd ? {} : {
                height: '100%',
                borderRight: '1px solid rgba(0, 0, 0, .12)'
            }
        },
        rhsContainerStyle() {
            return this.matchDownMd ? {} : {
                height: '100%',
            }
        },
        matchDownMd() {
            return this.innerWidth < 960
        },
        currentSchedule() {
            if (this.versionIndex >= 0 && this.versionIndex < this.stateData.draft.length) {
                return this.stateData.draft[this.versionIndex]
            }
            return null
        },
        publishDisabled() {
            if (this.currentSchedule) {
                return !(this.currentSchedule.schedule && Object.keys(this.currentSchedule.schedule).length > 0)
            }
            return true
        },
        openShifts() {
            if (this.currentSchedule) {
                return this.currentSchedule.open_shifts.shifts_by_month
            }
            return []
        },
        users() {
            if (this.currentSchedule) {
                return this.currentSchedule.users
            }
            return []
        },
        currentUserDetails() {
            if (this.currentSchedule && this.selectedUserId) {
                const currentUser = this.currentSchedule.users.find(user => user._id === this.selectedUserId)
                if (currentUser) {
                    return currentUser
                }
            }
            return null
        },
        isCurrentVersionDirty() {
            if (Array.isArray(this.data?.draft) && Array.isArray(this.stateData?.draft)) {
                if (this.versionIndex >= 0 && this.versionIndex < this.data.draft.length && this.versionIndex < this.stateData.draft.length) {
                    return !_.isEqual(
                        this.data.draft[this.versionIndex].schedule,
                        this.stateData.draft[this.versionIndex].schedule
                    )
                }
            }
            return false
        },
        isDirty() {
            return !_.isEqual(this.data, this.stateData)
        }
    },
    methods: {
        onGoToPrevVersion() {
            this.selectedOpenShifts = false
            this.selectedUserId = null
            this.versionIndex = this.versionIndex - 1
        },
        onGoToNextVersion() {
            this.selectedOpenShifts = false
            this.selectedUserId = null
            this.versionIndex = this.versionIndex + 1
        },
        onSyncSchedule() {
            this.stateData = _.cloneDeep({
                ...this.data,
                draft: this.stateData.draft.map((draft, draftIndex) => {
                    if (this.versionIndex === draftIndex) {
                        return this.data.draft[draftIndex]
                    }
                    return draft
                })
            })
        },
        onSelectOpenShifts() {
            this.selectedOpenShifts = true
            this.selectedUserId = null
        },
        onSelectUser(userId) {
            this.selectedOpenShifts = false
            this.selectedUserId = userId
        },
        onResize() {
            this.innerWidth = window.innerWidth
        },
        onDelete(shiftRequestId, groupingIndex) {
            if (this.stateData && this.selectedUserId && shiftRequestId) {
                if (this.versionIndex >= 0 && this.versionIndex < this.stateData.draft.length) {
                    const userIndex = this.stateData.draft[this.versionIndex].users.findIndex(user => user._id === this.selectedUserId)
                    if (userIndex >= 0) {
                        if (groupingIndex >= 0 && groupingIndex < this.stateData.draft[this.versionIndex].users[userIndex].shifts_by_month.length) {
                            this.stateData.draft[this.versionIndex].users[userIndex].shifts_by_month[groupingIndex].shifts =
                                this.stateData.draft[this.versionIndex].users[userIndex].shifts_by_month[groupingIndex].shifts.filter(shift => shift.shift_request_id !== shiftRequestId)
                            if (this.stateData.draft[this.versionIndex].schedule.hasOwnProperty(shiftRequestId)) {
                                this.stateData.draft[this.versionIndex].schedule[shiftRequestId] =
                                    this.stateData.draft[this.versionIndex].schedule[shiftRequestId].filter(userId => userId !== this.selectedUserId)
                            }
                        }
                    }
                }
            }
        },
        onClose() {
            if (this.isDirty) {
                this.showUnsavedChangesModal()
            } else {
                this.closeModal()
            }
        },
        showUnsavedChangesModal() {
            this.$refs.unsavedChangesDialog.open()
                .then(() => {
                    this.closeModal()
                })
                .catch(() => {
                })
        },
        closeModal() {
            this.modalValue = false
        },
        onPublishSchedule() {
            if (this.versionIndex >= 0 && this.versionIndex < this.stateData.draft.length) {
                this.isPublishing = true
                const draft = this.stateData.draft[this.versionIndex]
                this.api.post(`/auto-schedules/publish/${this.data._id}`, {
                    schedule_index: draft.schedule_index,
                    schedule: draft.schedule
                }).then(({message}) => {
                    message('Auto-Assign is being published. This process can take 1 or 2 minutes.You’ll receive a notification when complete.', 10000)
                    this.closeModal()
                }).catch(console.log).finally(() => {
                    this.isPublishing = false
                })
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize)
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    },
}
</script>

<style scoped>

</style>
