<template>
    <v-text-field
        :name="name"
        :label="label"
        :hint="hint"
        :persistent-hint="!!hint"
        :placeholder="placeholder"
        :error-messages="errorMessages"
        :readonly="readonly"
        :disabled="disabled"
        :rules="rules"

        prepend-inner-icon="mdi-clock-outline"
        autocomplete="off"
        v-model="time_string"
        v-mask="{mask: ['hH:mM'], tokens: tokens}"

        @change="changeEvent"
        @input="inputEvent"
        :outlined="outlined"

        class="koroid-time-input"
    >
        <template v-if="!readonly && !disabled" v-slot:append>
            <v-icon
                v-if="clearable"
                class="time-input-control time-input-clear-icon"
                :class="{'visible-clear-icon': !!time_string}"
                v-ripple
                @click="clear"
            >
                mdi-close
            </v-icon>
            <v-icon
                v-if="controls"
                class="time-input-control"
                v-ripple
                @click="decrement"
            >
                mdi-minus
            </v-icon>
            <v-icon
                v-if="controls"
                class="time-input-control"
                v-ripple
                @click="increment"
            >
                mdi-plus
            </v-icon>
        </template>
    </v-text-field>
</template>

<script>
import {mask} from 'vue-the-mask'

export default {
    name: "TimeInput",
    directives: {mask},
    props: {
        value: {
            type: Number,
            default: null
        },
        name: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: null
        },
        hint: {
            type: String,
            default: null
        },
        errorMessages: {
            type: Array,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        controls: {
            type: Boolean,
            default: false
        },
        incrementInterval: {
            type: Number,
            default: 30
        },
        decrementInterval: {
            type: Number,
            default: 30
        },
        required: {
            type: Boolean,
            default: false
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: null
        },
        maskTokens: {
            type: Object,
            default: null
        },
        regexRule: {
            type: String,
            default: null
        },
        outlined: {
            type: Boolean,
            default: true
        }
    },

    data() {
        const time_string = this.value !== null ? this.minutesToTimeString(this.value) : null
        const tokens = {
            'h': {pattern: /[0-9]/},
            'H': {pattern: /[0-9]/},
            'm': {pattern: /[0-5]/},
            'M': {pattern: /[0-9]/},
        }

        return {
            time_string: time_string,
            tokens: this.maskTokens || tokens
        }
    },

    computed: {
        rules() {
            let rules = []

            if (this.required) {
                rules.push(v => !!v || 'Required')
            }

            if (this.min > 0) {
                rules.push(() => this.minutes >= this.min || this.min_time_hint)
            }

            if (this.max) {
                rules.push(() => this.minutes <= this.max || this.max_time_hint)
            }

            if (this.regexRule) {
                rules.push(v => new RegExp(this.regexRule).test(v) || 'Invalid format')
            } else {
                if (this.required) {
                    rules.push(v => new RegExp('[0-9][0-9]:[0-5][0-9]').test(v) || 'Invalid format')
                } else {
                    rules.push(v => Boolean(!v) || new RegExp('[0-9][0-9]:[0-5][0-9]').test(v) || 'Invalid format')
                }
            }

            return rules
        },

        minutes() {
            return this.timeStringToMinutes(this.time_string)
        },

        min_time_hint() {
            let hours = this.min / 60

            if (hours > 1) {
                return 'Must be at least ' + hours + ' hours'
            }

            return 'Must be at least 1 hour'
        },

        max_time_hint() {
            let hours = this.max / 60

            if (hours > 1) {
                return 'Cannot exceed ' + hours + ' hours'
            }

            return 'Cannot exceed 1 hour'
        }
    },

    methods: {
        setValue(minutes) {
            this.time_string = this.minutesToTimeString(minutes)
        },

        increment() {
            if (this.max) {
                this.time_string = this.minutes + this.incrementInterval < this.max ?
                    this.minutesToTimeString(this.minutes + this.incrementInterval) :
                    this.minutesToTimeString(this.max)
            } else {
                this.time_string = this.minutesToTimeString(this.minutes + this.incrementInterval)
            }

            this.inputEvent()
        },

        decrement() {
            this.time_string = this.minutes - this.decrementInterval >= this.min ?
                this.minutesToTimeString(this.minutes - this.decrementInterval) :
                this.minutesToTimeString(this.min)

            this.inputEvent()
        },

        clear() {
            this.time_string = null
            this.inputEvent()
        },

        timeStringToMinutes(value) {
            if (!value) {
                return null
            }

            value = value.split(':')

            if (value.length !== 2) {
                return null
            }

            return (parseInt(value[0]) * 60) + parseInt(value[1])
        },

        minutesToTimeString(value) {
            let hours = Math.floor(value / 60).toString()
            let min = (value % 60).toString()
            return this.padNumber(hours) + ':' + this.padNumber(min)
        },

        padNumber(number) {
            return number.length === 1 ? '0' + number : number
        },

        changeEvent() {
            if (this.time_string === '') {
                this.time_string = '00:00'
                this.inputEvent()
            }

            this.$emit('change', this.minutes)
        },

        inputEvent() {
            this.$emit('fieldInput', this.minutes)
        },
    },

    watch: {
        time_string(value) {
            this.$emit('input', this.timeStringToMinutes(value))
        }
    },
}
</script>

<style scoped>
    .koroid-time-input .time-input-control {
        visibility: hidden;
        opacity: 0;
        transition: opacity .3s cubic-bezier(.25,.8,.5,1)
    }

    .koroid-time-input:hover .time-input-control {
        visibility: visible;
        opacity: 1;
        transition: opacity .3s cubic-bezier(.25,.8,.5,1)
    }

    .koroid-time-input.v-input.v-input--is-focused .time-input-control {
        visibility: visible;
        color: #0D0A32;
        opacity: 1;
        transition: opacity .3s cubic-bezier(.25,.8,.5,1)
    }

    .koroid-time-input .time-input-clear-icon {
        opacity: 0 !important;
        pointer-events: none;
        transition: opacity .3s cubic-bezier(.25,.8,.5,1)
    }

    .koroid-time-input .time-input-clear-icon.visible-clear-icon {
        opacity: 1 !important;
        pointer-events: auto;
        transition: opacity .3s cubic-bezier(.25,.8,.5,1)
    }
</style>
