import { render, staticRenderFns } from "./PresentationalUserList.vue?vue&type=template&id=55f03c29&scoped=true"
import script from "./PresentationalUserList.vue?vue&type=script&lang=js"
export * from "./PresentationalUserList.vue?vue&type=script&lang=js"
import style0 from "./PresentationalUserList.vue?vue&type=style&index=0&id=55f03c29&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55f03c29",
  null
  
)

export default component.exports