<template>
    <v-dialog
        v-model="modalValue"
        scrollable
        persistent
        max-width="600px"
    >
        <v-card>
            <v-toolbar class="informational-modal" elevation="0">
                <v-toolbar-title class="text--h2">Auto-Assign</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-form
                    ref="autoAssignFormRef"
                    :lazy-validation="false"
                >
                    <v-container>
                        <v-row class="mt-5 row-min-width">
                            <v-col cols="12" class="ds-modal-input-container">
                                <p class="text--h4">
                                    Schedule Period
                                </p>
                            </v-col>
                            <v-col cols="12" class="ds-modal-input-container">
                                <date-range-picker
                                    v-model="selectedDateRange"
                                    :disabled="disabled"
                                />
                            </v-col>
                        </v-row>
                        <v-row class="row-min-width">
                            <v-col cols="12" class="ds-modal-input-container">
                                <p class="text--h4">
                                    Staffing
                                </p>
                            </v-col>
                            <v-col cols="12" class="ds-modal-input-container">
                                <v-autocomplete
                                    v-model="selectedStaffGroupId"
                                    label="Staff Group"
                                    :items="staffGroups"
                                    :loading="staffGroupsLoading"
                                    :disabled="disabled"
                                    :rules="rules.required"
                                    item-value="_id"
                                    item-text="name"
                                    outlined
                                    clearable
                                    @click="onChangeStaffGroup"
                                >
                                    <template v-slot:prepend-inner>
                                        <v-icon color="primary">mdi-account-group</v-icon>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" class="ds-modal-input-container">
                                <v-autocomplete
                                    v-model="selectedJobTitleId"
                                    label="Job Title"
                                    :items="jobTitles"
                                    :loading="staffGroupsLoading"
                                    :disabled="jobTitlesDisabled || disabled"
                                    :rules="rules.required"
                                    :hint="jobTitlesHint"
                                    persistent-hint
                                    item-value="_id"
                                    item-text="title"
                                    outlined
                                    clearable
                                >
                                    <template v-slot:prepend-inner>
                                        <v-icon color="primary">mdi-clipboard-account</v-icon>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="row-min-width">
                            <v-col cols="12" class="ds-modal-input-container">
                                <div class="d-flex flex-row align-center">
                                    <p class="text--h4" style="margin-bottom: 0;">
                                        Utilization Rate
                                    </p>
                                    <utilization-rate-tooltip/>
                                </div>
                            </v-col>
                            <v-col cols="12" class="ds-modal-input-container">
                                <number-input
                                    v-model.number="utilizationRate"
                                    :disabled="disabled"
                                    label="Target Percentage"
                                    :min="1"
                                    :max="100"
                                    controls
                                    required
                                    prepend-inner="mdi-account-group"
                                ></number-input>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    :disabled="isAutoAssigning"
                    @click="onClose"
                >
                    Close
                </v-btn>
                <v-btn
                    v-if="!disabled"
                    color="primary"
                    outlined
                    :loading="isAutoAssigning"
                    @click="onNext"
                >
                    Next
                </v-btn>
                <v-btn
                    v-if="disabled"
                    color="primary"
                    outlined
                    :href="autoScheduleDraftUrl"
                >
                    View Results
                </v-btn>
            </v-card-actions>
            <unsaved-changes-dialog ref="unsavedChangesDialog"/>
        </v-card>
    </v-dialog>
</template>

<script>
import validationRules from "../../../../lib/mixins/validationRules";
import DateRangePicker from "./DateRangePicker";
import NumberInput from "../../../common/NumberInput/NumberInput";
import UtilizationRateTooltip from "./UtilizationRateTooltip";
import UnsavedChangesDialog from "../../../modals/UnsavedChangesDialog";

export default {
    name: "AutoAssignModal",
    mixins: [validationRules],
    components: {DateRangePicker, NumberInput, UtilizationRateTooltip, UnsavedChangesDialog},
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        autoScheduleDraft: {
            type: Object,
            default: null
        }
    },
    data: () => ({
        selectedDateRange: [],
        selectedStaffGroupId: null,
        staffGroups: [],
        staffGroupsLoading: false,
        selectedJobTitleId: null,
        utilizationRate: null,
        isAutoAssigning: false,
        api: new formHelper()
    }),
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        jobTitles() {
            if (this.selectedStaffGroupId) {
                const staffGroup = this.staffGroups.find(staffGroup => staffGroup._id === this.selectedStaffGroupId)
                if (staffGroup && Array.isArray(staffGroup.job_titles)) {
                    return Array.from(staffGroup.job_titles)
                }
            }
            return []
        },
        jobTitlesDisabled() {
            return Boolean(!this.selectedStaffGroupId)
        },
        jobTitlesHint() {
            if (this.selectedStaffGroupId) {
                return ''
            }
            return '(Select a Staff Group to View)'
        },
        autoAssignFormRef() {
            return this.$refs?.autoAssignFormRef
        },
        disabled() {
            return !!this.autoScheduleDraft
        },
        autoScheduleDraftUrl() {
            if (this.autoScheduleDraft) {
                return `/schedules?auto_schedule_draft_id=${this.autoScheduleDraft._id}`
            }
            return null
        },
        globalLocation() {
            return this.$root.globalLocation;
        },
        isDirty() {
            if (this.autoScheduleDraft) {
                return false
            }
            return Array.isArray(this.selectedDateRange) && this.selectedDateRange.length > 0 ||
                this.selectedStaffGroupId || this.selectedJobTitleId || this.utilizationRate !== null
        }
    },
    methods: {
        fetchStaffGroups() {
            if (this.globalLocation) {
                this.staffGroupsLoading = true
                this.api.post('/auto-schedules/filter-data', {location_id: this.globalLocation._id})
                    .then(d => {
                        if (Array.isArray(d?.data?.staff_groups)) {
                            this.staffGroups = Array.from(d.data.staff_groups)
                        }
                    })
                    .catch(console.log)
                    .finally(() => {
                        this.staffGroupsLoading = false
                    })
            }
        },
        onChangeStaffGroup() {
            this.selectedJobTitleId = null
        },
        onClose() {
            if (this.isDirty) {
                this.showUnsavedChangesModal()
            } else {
                this.closeModal()
            }
        },
        showUnsavedChangesModal() {
            this.$refs.unsavedChangesDialog.open()
                .then(() => {
                    this.closeModal()
                })
                .catch(() => {
                })
        },
        closeModal() {
            this.modalValue = false
        },
        onNext() {
            if (this.autoAssignFormRef && this.autoAssignFormRef.validate()) {
                if (this.globalLocation && Array.isArray(this.selectedDateRange) && this.selectedDateRange.length > 1) {
                    this.isAutoAssigning = true
                    this.api.post('/auto-schedules/generate', {
                        schedule_start: this.selectedDateRange[0],
                        schedule_end: this.selectedDateRange[1],
                        staff_group_id: this.selectedStaffGroupId,
                        job_title_id: this.selectedJobTitleId,
                        parameters: {
                            target_utilization: this.utilizationRate
                        },
                        timezone: this.globalLocation.timezone
                    }).then(data => {
                        if (data) {
                            this.$emit('onSuccess', data.data)
                            data.message('Auto-Assign process is running. This process can take up to 15 minutes. You’ll receive a notification when the results are ready for review.', 10000)
                            this.closeModal()
                        }
                    }).catch(console.log).finally(() => {
                        this.isAutoAssigning = false
                    })
                }
            }
        }
    },
    mounted() {
        this.fetchStaffGroups()
    },
    created() {
        if (this.autoScheduleDraft) {
            const {
                schedule_start,
                schedule_end,
                timezone,
                staff_group_id,
                job_title_id,
                parameters
            } = this.autoScheduleDraft
            if (schedule_start && schedule_end && timezone) {
                this.selectedDateRange = [
                    moment(schedule_start).tz(timezone).format('YYYY-MM-DD'),
                    moment(schedule_end).tz(timezone).format('YYYY-MM-DD'),
                ]
            }
            if (staff_group_id) {
                this.selectedStaffGroupId = staff_group_id
            }
            if (job_title_id) {
                this.selectedJobTitleId = job_title_id
            }
            if (parameters) {
                this.utilizationRate = parameters.target_utilization
            }
        }
    }
}
</script>

<style scoped>

</style>
