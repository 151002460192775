<template>
    <div class="d-flex flex-column" style="row-gap: 10px;">
        <div class="d-flex flex-row align-center flex-wrap" style="column-gap: 25px;">
            <div
                v-for="({shift_time, shift_count}) in shiftTimeCounts"
                class="d-flex flex-row align-center"
                style="column-gap: 8px;"
            >
                <div class="text--h4">
                    {{ shift_time }}
                </div>
                <div class="text--paragraph">
                    {{ shift_count }}
                </div>
            </div>
        </div>
        <v-divider/>
    </div>
</template>

<script>
export default {
    name: "ShiftTimeCounts",
    props: {
        shiftTimeCounts: {
            type: Array,
            default: () => ([])
        },
    }
}
</script>

<style scoped>

</style>
