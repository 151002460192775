const state = {
    datesById: {},
    selectedRequestFormId: null,
    requestForm: null,
    selectedDate: null,
    toBeAssignedIds: [],
    toBeUnassignedIds: [],
    toBeOfferedIds: [],
    toBeHistoricAssignedIds: [],
    assignedCount: 0,
    isAssignableLoading: false,
    isOfferableLoading: false,
    isRequestFormDetailsLoading: false,
    selectedShiftRequestId: null,
    isAssignableView: true,
    predictedAvailability: null,
    predictionsEnabled: null,
    selectedView: 'all',
    usersLoading: false,
    searchBy: null,
    prevSelectedIds: {},
    actionableUsers: [],
    historicActionableUsers: [],
    changesMade: false,
    showRejectedOffers: true,
}

const getters = {
    getDatesById (state) {
        return state.datesById
    },
    getSelectedRequestFormId (state) {
        return state.selectedRequestFormId
    },
    getRequestForm (state) {
        return state.requestForm
    },
    getSelectedDate (state) {
        return state.selectedDate
    },
    getToBeHistoricAssignedIds (state) {
        return state.toBeHistoricAssignedIds
    },
    getToBeAssignedIds (state) {
        return state.toBeAssignedIds
    },
    getToBeUnassignedIds (state) {
        return state.toBeUnassignedIds
    },
    getToBeOfferedIds (state) {
        return state.toBeOfferedIds
    },
    getAssignedCount (state) {
        return state.assignedCount
    },
    getIsAssignableLoading (state) {
        return state.isAssignableLoading
    },
    getIsOfferableLoading (state) {
        return state.isOfferableLoading
    },
    getIsRequestFormDetailsLoading (state) {
        return state.isRequestFormDetailsLoading
    },
    getSelectedShiftRequestId (state) {
        return state.selectedShiftRequestId
    },
    getIsAssignableView (state) {
        return state.isAssignableView
    },
    getPredictedAvailability (state) {
        return state.predictedAvailability
    },
    getPredictionsEnabled (state) {
        return state.predictionsEnabled
    },
    getSelectedView (state) {
        return state.selectedView
    },
    getUsersLoading (state) {
        return state.usersLoading
    },
    getSearchBy (state) {
        return state.searchBy
    },
    getPrevSelectedIds (state) {
        return state.prevSelectedIds
    },
    getActionableUsers (state) {
        return state.actionableUsers
    },
    getHistoricActionableUsers (state) {
        return state.historicActionableUsers
    },
    getChangesMade (state) {
        return state.changesMade
    },
    getShowRejectedOffers (state) {
        return state.showRejectedOffers
    }
}

// Shouldn't actions use a common convention like update? ie: updateDatesById, updateToBeOfferedIds
const actions = {
    setDatesById ({ commit }, payload) {
        commit('setDatesById', payload)
    },
    setSelectedRequestFormId ({ commit }, payload) {
        commit('setSelectedRequestFormId', payload)
    },
    setRequestForm ({ commit }, payload) {
        commit('setRequestForm', payload)
    },
    setSelectedDate ({ commit }, payload) {
        commit('setSelectedDate', payload)
    },
    setToBeHistoricAssignedIds ({ commit }, payload) {
        commit('setToBeHistoricAssignedIds', payload)
    },
    setToBeAssignedIds ({ commit }, payload) {
        commit('setToBeAssignedIds', payload)
    },
    setToBeUnassignedIds ({ commit }, payload) {
        commit('setToBeUnassignedIds', payload)
    },
    setToBeOfferedIds ({ commit }, payload) {
        commit('setToBeOfferedIds', payload)
    },
    setAssignedCount ({ commit }, payload) {
        commit('setAssignedCount', payload)
    },
    clearScheduleState ({ commit }) {
        commit('clearScheduleState')
    },
    setIsAssignableLoading ( { commit }, payload) {
        commit('setIsAssignableLoading', payload)
    },
    setIsOfferableLoading ( { commit }, payload) {
        commit('setIsOfferableLoading', payload)
    },
    setIsRequestFormDetailsLoading ( { commit }, payload) {
        commit('setIsRequestFormDetailsLoading', payload)
    },
    setSelectedShiftRequestId ( { commit }, payload) {
        commit('setSelectedShiftRequestId', payload)
    },
    setIsAssignableView ( { commit }, payload) {
        commit('setIsAssignableView', payload)
    },
    setPredictedAvailability ( { commit }, payload) {
        commit('setPredictedAvailability', payload)
    },
    setPredictionsEnabled ({ commit }, payload) {
        commit('setPredictionsEnabled', payload)
    },
    clearAll ({ commit }) {
        commit('clearAll')
    },
    setSelectedView ({ commit }, payload) {
        commit('setSelectedView', payload)
    },
    setUsersLoading ({ commit }, payload) {
        commit('setUsersLoading', payload)
    },
    setSearchBy ({ commit }, payload) {
        commit('setSearchBy', payload)
    },
    setPrevSelectedIds ({ commit }, payload) {
        commit('setPrevSelectedIds', payload)
    },
    setActionableUsers ({ commit }, payload) {
        commit('setActionableUsers', payload)
    },
    setHistoricActionableUsers ({ commit }, payload) {
        commit('setHistoricActionableUsers', payload)
    },
    setChangesMade ({ commit }, payload) {
        commit('setChangesMade', payload)
    },
    setShowRejectedOffers ({ commit }, payload) {
        commit('setShowRejectedOffers', payload)
    }
}

const mutations = {
    setDatesById (state, { datesById, preselectedValues }) {
        if (Array.isArray(datesById)) {
            state.datesById = datesById.reduce((mapping, { request_form_id, date }) => {
                if (request_form_id && date) {
                    return {
                        ...mapping,
                        byId: {
                            ...mapping.byId,
                            [request_form_id]: date.format('YYYY-MM-DD'),
                        },
                        byDate: {
                            ...mapping.byDate,
                            [date.format('YYYY-MM-DD')]: request_form_id,
                        },
                        byMoment: {
                            ...mapping.byMoment,
                            [request_form_id]: date,
                        }
                    }
                }
                return mapping
            }, { byId: {}, byDate: {}, byMoment: {}, values: datesById })
            if (datesById.length > 0) {
                let index = 0
                if (preselectedValues && preselectedValues.requestFormId) {
                    index = datesById.findIndex(({ request_form_id }) => request_form_id === preselectedValues.requestFormId)
                }
                const { request_form_id, date } = datesById[index >= 0 ? index : 0]
                if (request_form_id && date) {
                    state.selectedRequestFormId = request_form_id
                    state.selectedDate = date.format('YYYY-MM-DD')
                }
            }
        }
    },
    setSelectedRequestFormId (state, { selectedRequestFormId }) {
        if (state.datesById.hasOwnProperty('byId')) {
            if (state.datesById.byId.hasOwnProperty(selectedRequestFormId)) {
                state.selectedRequestFormId = selectedRequestFormId
                state.selectedDate = state.datesById.byId[selectedRequestFormId]
            }
        }
    },
    setRequestForm (state, { requestForm }) {
        state.requestForm = {...requestForm}
    },
    setSelectedDate (state, { selectedDate }) {
        if (state.datesById.hasOwnProperty('byDate')) {
            if (state.datesById.byDate.hasOwnProperty(selectedDate)) {
                state.selectedRequestFormId = state.datesById.byDate[selectedDate]
                state.selectedDate = selectedDate
            }
        }
    },
    setToBeHistoricAssignedIds (state, { toBeHistoricAssignedIds }) {
        state.toBeHistoricAssignedIds = toBeHistoricAssignedIds
    },
    setToBeAssignedIds (state, { toBeAssignedIds }) {
        state.toBeAssignedIds = toBeAssignedIds
    },
    setToBeUnassignedIds (state, { toBeUnassignedIds }) {
        state.toBeUnassignedIds = toBeUnassignedIds
    },
    setToBeOfferedIds (state, { toBeOfferedIds }) {
        state.toBeOfferedIds = toBeOfferedIds
    },
    setAssignedCount (state, { assignedCount }) {
        const { requestForm, selectedShiftRequestId } = state
        state.assignedCount = assignedCount
        if (requestForm && selectedShiftRequestId && Array.isArray(requestForm.shift_requests)) {
            state.requestForm = {
                ...requestForm,
                shift_requests: requestForm.shift_requests.map(req => {
                    if (req._id === selectedShiftRequestId) {
                        return {
                            ...req,
                            filled_shifts_count: assignedCount,
                        }
                    }
                    return req
                })
            }
        }
    },
    clearScheduleState (state) {
        state.toBeAssignedIds = []
        state.toBeHistoricAssignedIds = []
        state.toBeUnassignedIds = []
        state.toBeOfferedIds = []
        state.searchBy = null
        state.prevSelectedIds = {}
        state.actionableUsers = []
        state.historicActionableUsers = []
    },
    setIsAssignableLoading (state, { isAssignableLoading }) {
        state.isAssignableLoading = isAssignableLoading
    },
    setIsOfferableLoading (state, { isOfferableLoading }) {
        state.isOfferableLoading = isOfferableLoading
    },
    setIsRequestFormDetailsLoading (state, { isRequestFormDetailsLoading }) {
        state.isRequestFormDetailsLoading = isRequestFormDetailsLoading
    },
    setSelectedShiftRequestId (state, { selectedShiftRequestId }) {
        state.selectedShiftRequestId = selectedShiftRequestId
    },
    setIsAssignableView (state, { isAssignableView }) {
        state.isAssignableView = isAssignableView
    },
    setPredictedAvailability (state, { predictedAvailability }) {
        state.predictedAvailability = predictedAvailability
    },
    setPredictionsEnabled (state, { enabled }) {
        state.predictionsEnabled = enabled
    },
    clearAll (state) {
        state.datesById = {}
        state.selectedRequestFormId = null
        state.requestForm = null
        state.selectedDate = null
        state.toBeHistoricAssignedIds = []
        state.toBeAssignedIds =  []
        state.toBeUnassignedIds = []
        state.toBeOfferedIds = []
        state.assignedCount = 0
        state.isAssignableLoading = false
        state.isOfferableLoading = false
        state.isRequestFormDetailsLoading = false
        state.selectedShiftRequestId = null
        state.isAssignableView = true
        state.predictedAvailability = null
        state.predictionsEnabled = null
        state.selectedView = 'all'
        state.usersLoading = false
        state.searchBy = null
        state.prevSelectedIds = {}
        state.actionableUsers = []
        state.historicActionableUsers = []
        state.showRejectedOffers = true
    },
    setSelectedView (state, { selectedView }) {
        state.selectedView = selectedView
    },
    setUsersLoading (state, { usersLoading }) {
        state.usersLoading = usersLoading
    },
    setSearchBy (state, { searchBy }) {
        state.searchBy = searchBy
    },
    setPrevSelectedIds (state, { prevSelectedIds }) {
        state.prevSelectedIds = prevSelectedIds
    },
    setActionableUsers (state, { actionableUsers }) {
        state.actionableUsers = actionableUsers
    },
    setHistoricActionableUsers (state, { historicActionableUsers }) {
        state.historicActionableUsers = historicActionableUsers
    },
    setChangesMade (state, { changesMade }) {
        state.changesMade = changesMade
    },
    setShowRejectedOffers (state, { showRejectedOffers }) {
        state.showRejectedOffers = showRejectedOffers
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}
