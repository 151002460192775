<template>
    <div class="card-display-container" :style="containerStyle" @click="onClick">
        <slot/>
    </div>
</template>

<script>
export default {
    name: "CardDisplay",
    props: {
        height: {
            type: Number,
            default: 0,
        },
        backgroundColor: {
            type: String,
            default: null
        },
        borderColor: {
            type: String,
            default: null
        },
        clickable: {
            type: Boolean,
            default: false
        },
        fullWidth: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        containerStyle() {
            return {
                ...(this.height > 0) && {height: `${this.height}px`},
                ...this.backgroundColor && {backgroundColor: this.backgroundColor},
                ...this.clickable && {cursor: 'pointer'},
                ...this.fullWidth && {width: '100%'},
                border: `1px solid ${this.borderColor ? this.borderColor : 'rgb(119, 118, 128)'}`
            }
        }
    },
    methods: {
        onClick() {
            if (this.clickable) {
                this.$emit('onClick')
            }
        }
    }
}
</script>

<style scoped>
.card-display-container {
    padding: 12px;
    border-radius: 8px;
}
</style>
