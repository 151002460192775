<template>
    <v-container grid-list-xl fluid>
        <v-layout wrap row>
            <v-flex md12>
                <v-window class="pa-4" v-model="window">
                    <v-window-item value="newUser">
                        <UserProfileTabs
                            :key="newUserProfileTabsKey"
                            :user="newUser"
                            :profile-view="false"
                            :can-go-back="true"
                            :new-user="isNewUser"
                            :new-user-first-step-only="isNewUserFirstStepOnly"
                            :roles="roles"
                            :job-titles-dict="jobTitlesDict"
                            :staffing-details="staffingDetails"
                            :email-domains="emailDomains"
                            @user:saved="userCreated"
                            @user-profile:updated="userProfileUpdated"
                            @user-jobs:updated="userJobsUpdated"
                            @loadUsers="loadUsers"
                            @onBackButtonPressed="back"
                            @status:updated="loadUsers"
                        ></UserProfileTabs>
                    </v-window-item>

                    <v-window-item value="bulkUsers">
                        <bulk-upload-users
                            :key="bulkUploadUsersKey"
                            @onBulkUpload="onBulkUploadUsers"
                            @onExit="onExitBulkUploadUsers"
                        />
                    </v-window-item>

                    <v-window-item value="search">
                        <v-card>
                            <v-tabs
                                v-model="tab"
                                dark
                                grow
                                height="56"
                                slider-size="4"
                                @change="onTabChange"
                            >
                                <v-toolbar-title style="line-height: 56px; color: #000D60;" class="ml-4 mr-4 text--h2">Users</v-toolbar-title>
                                <v-tab
                                    v-for="item in filteredTabs"
                                    :key="item.tab"
                                    :name="item.name"
                                    :disabled="loadingUsers"
                                >
                                    {{ item.name }}
                                </v-tab>
                                <div style="line-height: 56px; margin-right: 10px; padding-left: 10px;">
                                    <OptionsSelect
                                        dusk-selector="addUsersMenu"
                                        v-if="canCreateUsers"
                                        :key="addNewUserKey"
                                        title="New User"
                                        color="primary"
                                        :items="addNewUserTypes"
                                        @onOptionChanged="onChangeAddNewUser"
                                    />
                                </div>
                            </v-tabs>
                            <v-tabs-items v-model="tab">
                                <v-tab-item v-for="tab in filteredTabs" :key="tab.tab">
                                    <user-search
                                        :ref="tab.ref"
                                        :loading-users="loadingUsers"
                                        :role="tab.tab"
                                        :departments="departments"
                                        @onSearch="onSearch"></user-search>
                                    <owners-table
                                        v-if="tab.name === 'Owner'"
                                        :key="ownersTableKey"
                                        :users="ownerUsers"
                                        :total-users="totalUsers"
                                        :loading-users="loadingUsers"
                                        :should-update-table-options="shouldUpdateOwnersTableOptions"
                                        @update:options="onUpdatePaginationOptions"
                                        @user-clicked="viewUser"
                                    ></owners-table>
                                    <administrators-table
                                        v-else-if="tab.name === 'Administrator'"
                                        :key="administratorsTableKey"
                                        :users="AdminUsers"
                                        :total-users="totalUsers"
                                        :loading-users="loadingUsers"
                                        :locations-map="locationsMap"
                                        :should-update-table-options="shouldUpdateAdministratorTableOptions"
                                        @update:options="onUpdatePaginationOptions"
                                        @user-clicked="viewUser"
                                    ></administrators-table>
                                    <managers-table
                                        v-else-if="tab.name === 'Manager'"
                                        :key="managersTableKey"
                                        :users="ManagerUsers"
                                        :total-users="totalUsers"
                                        :loading-users="loadingUsers"
                                        :locations-map="locationsMap"
                                        :staffing-details="staffingDetails"
                                        :should-update-table-options="shouldUpdateManagerTableOptions"
                                        @update:options="onUpdatePaginationOptions"
                                        @user-clicked="viewUser"
                                    ></managers-table>
                                    <staff-table
                                        v-else-if="tab.name === 'Staff'"
                                        :key="staffTableKey"
                                        :users="staffUsers"
                                        :total-users="totalUsers"
                                        :loading-users="loadingUsers"
                                        :search-params="searchParams"
                                        :should-update-table-options="shouldUpdateStaffTableOptions"
                                        @update:options="onUpdatePaginationOptions"
                                        @user-clicked="viewUser"></staff-table>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-card>
                    </v-window-item>

                    <v-window-item value="user">
                        <template v-if="selectedUser">
                            <UserProfileTabs
                                :key="existingUserProfileTabsKey"
                                :user="selectedUser"
                                :profile-view="false"
                                :can-go-back="true"
                                :new-user="false"
                                :preselected-tab="preselectedTab"
                                :roles="roles"
                                :job-titles-dict="jobTitlesDict"
                                :staffing-details="staffingDetails"
                                :email-domains="emailDomains"
                                @user:saved="userUpdated"
                                @user:deleted="userDeleted"
                                @onBackButtonPressed="back"
                                @loadUsers="loadUsers"
                                @status:updated="loadUsers"
                            ></UserProfileTabs>
                        </template>
                    </v-window-item>

                </v-window>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import UserSearch from "../../components/search/UserSearch";
    import OwnersTable from "./OwnersTable";
    import AdministratorsTable from "./AdministratorsTable";
    import ManagersTable from "./ManagersTable";
    import StaffTable from "./StaffTable";
    import UserProfileTabs from "../../components/profile/UserProfileTabs";
    import BulkUploadUsers from "../../components/profile/BulkUploadUsers/BulkUploadUsers";
    import OptionsSelect from "../../components/reporting/FormInputs/OptionsSelect";
    import _ from 'lodash';

    export default {
        name: "UsersPage",
        components: {
            ManagersTable,
            AdministratorsTable,
            UserProfileTabs,
            UserSearch,
            StaffTable,
            OwnersTable,
            BulkUploadUsers,
            OptionsSelect,
        },
        data() {
            const locationsMap = this.$appData.locations.reduce((locations, loc) => ({
                ...locations,
                [loc._id]: loc.name
            }), {})
            return {
                staffUsers: [],
                ownerUsers: [],
                AdminUsers: [],
                ManagerUsers: [],
                totalUsers: 0,
                loadingUsers: false,
                isAdmin: this.$authIsOwner,
                window: "search",
                tabs: [
                    { tab: 'Owner', name: 'Owner', ref: 'ownerSearchRef', disabled: false, },
                    { tab: 'Administrator', name: 'Administrator', ref: 'adminSearchRef', disabled: false, },
                    { tab: 'Manager', name: 'Manager', ref: 'managersSearchRef', disabled: false, },
                    { tab: 'Staff', name: 'Staff', ref: 'staffSearchRef', disabled: false, },
                ],
                tab: 0,
                selectedUser: null,
                api: new formHelper(),
                newUser: {},
                isNewUser: true,
                isNewUserFirstStepOnly: false,
                searchParams: null,
                paginationOptions: null,
                newUserProfileTabsKey: 0,
                existingUserProfileTabsKey: 0,
                roles: this.$appData.roles,
                locations: this.$appData.locations,
                jobTitles: this.$appData.job_titles,
                hospitalGroup: this.$appData.hospital_group,
                jobTitlesDict: null,
                locationsMap,
                ownersTableKey: 10000,
                administratorsTableKey: 20000,
                managersTableKey: 30000,
                staffTableKey: 40000,
                departments: this.$appData.departments,
                addNewUserKey: 2912,
                addNewUserTypes: [
                    { text: 'New User', value: 'single' },
                    { text: 'Bulk Upload Users', value: 'bulk' },
                ],
                bulkUploadUsersKey: 9128,
                PERMISSIONS: this.$config.permissions,
                preselectedTab: null
            }
        },
        methods: {
            viewUser(user) {
                this.existingUserProfileTabsKey = this.existingUserProfileTabsKey + 1
                this.selectedUser = user;
                this.window = "user";
            },

            back() {
                this.window = "search";
                this.isNewUser = true
                this.newUser = {}
                this.newUserProfileTabsKey = this.newUserProfileTabsKey + 1
                this.existingUserProfileTabsKey = this.existingUserProfileTabsKey + 1
            },

            userCreated(user, onUserCreated) {
                this.newUser = Object.assign({}, user)
                this.isNewUser = false
                this.isNewUserFirstStepOnly = false
                this.loadUsers()
                if(onUserCreated) {
                    onUserCreated()
                }
            },

            userProfileUpdated(user, selectedRole) {
                this.isNewUserFirstStepOnly = false
                const updateNewUser = () => {
                    this.newUser = {
                        ...user,
                        jobs: this.newUser && this.newUser.jobs && Array.isArray(this.newUser.jobs) ?
                            this.newUser.jobs : user && user.jobs && Array.isArray(user.jobs) ? user.jobs : []
                    }
                }
                const updateAlreadyExistingUser = () => {
                    this.selectedUser = {
                        ...user,
                        jobs: this.newUser && this.newUser.jobs && Array.isArray(this.newUser.jobs) ?
                            this.newUser.jobs : user && user.jobs && Array.isArray(user.jobs) ? user.jobs : []
                    }
                    this.window = 'user'
                }
                const role = this.roles.find(r => r._id === selectedRole)
                if(role && role.name) {
                    if(role.name === 'Staff') {
                        if(this.$userHasPermission(this.$user, this.PERMISSIONS.USERS.EDIT_OTHERS_JOBS) ||
                            this.$userHasPermission(this.$user, this.PERMISSIONS.USERS.EDIT_OTHERS_PERMISSIONS) ||
                            this.$userHasPermission(this.$user, this.PERMISSIONS.AVAILABILITY.EDIT_OTHERS)) {
                            updateNewUser()
                        } else {
                            updateAlreadyExistingUser()
                        }
                    } else if(role.name === 'Administrator' || role.name === 'Manager') {
                        if(this.$userHasPermission(this.$user, this.PERMISSIONS.USERS.EDIT_OTHERS_PERMISSIONS)) {
                            updateNewUser()
                        } else {
                            updateAlreadyExistingUser()
                        }
                    } else {
                        updateAlreadyExistingUser()
                    }
                } else {
                    updateNewUser()
                }
            },

            userJobsUpdated({jobs,requirements, staff_type, max_hours_per_week}) {
                if(this.$userHasPermission(this.$user, this.PERMISSIONS.USERS.EDIT_OTHERS_PERMISSIONS) ||
                    this.$userHasPermission(this.$user, this.PERMISSIONS.AVAILABILITY.EDIT_OTHERS)) {
                    this.newUser = {
                        ...this.newUser,
                        jobs,
                        requirements,
                        staff_type,
                        max_hours_per_week
                    }
                } else {
                    this.selectedUser = {
                        ...this.newUser,
                        jobs,
                        requirements,
                        staff_type,
                        max_hours_per_week
                    }
                    this.window = 'user'
                }
            },

            loadUsers() {
                if(this.searchParams && this.paginationOptions) {
                    this.staffUsers = []
                    this.ownerUsers = []
                    this.AdminUsers = []
                    this.ManagerUsers = []
                    this.loadingUsers = true
                    this.api.post('/users/findBySearchCriteria', {
                        ...this.paginationOptions,
                        ...this.searchParams,
                        role_name: this.filteredTabs[this.tab].name
                    })
                        .then(({ data }) => {
                            if(this.currentTab.name === 'Owner') {
                                this.ownerUsers = data.data
                            } else if(this.currentTab.name === 'Administrator') {
                                this.AdminUsers = data.data
                            } else if(this.currentTab.name === 'Manager') {
                                this.ManagerUsers = data.data
                            } else {
                                this.staffUsers = data.data
                            }
                            this.totalUsers = data.total
                            this.loadingUsers = false
                        })
                        .catch(() => {
                            this.users = []
                            this.loadingUsers = false
                        })
                }
            },

            userUpdated(user, callback) {
                this.loadUsers()
                this.selectedUser = Object.assign({}, user)
                if(callback) {
                    callback()
                }
            },

            userDeleted(user_id) {
                this.loadUsers()
            },

            onSearch: _.debounce(function (searchParams) {
                this.searchParams = Object.assign({}, searchParams)
                if (this.$authIsOwner) {
                    if (this.tab === 0) {
                        this.ownersTableKey = this.ownersTableKey + 1
                    } else if (this.tab === 1) {
                        this.administratorsTableKey = this.administratorsTableKey + 1
                    } else if (this.tab === 2) {
                        this.managersTableKey = this.managersTableKey + 1
                    } else if (this.tab === 3) {
                        this.staffTableKey = this.staffTableKey + 1
                    }
                } else if (this.$authIsAdministrator) {
                    if (this.tab === 0) {
                        this.managersTableKey = this.managersTableKey + 1
                    } else if (this.tab === 1) {
                        this.staffTableKey = this.staffTableKey + 1
                    }
                } else if (this.$authIsManager) {
                    this.staffTableKey = this.staffTableKey + 1
                }
            }, 500),

            onUpdatePaginationOptions(paginationOptions) {
                this.paginationOptions = Object.assign({}, paginationOptions)
                this.loadUsers()
            },

            onCreateNewUser() {
                this.isNewUserFirstStepOnly = true
                this.window = 'newUser'
            },

            onTabChange() {
                const {
                    $authIsOwner,
                    $authIsAdministrator,
                    $authIsManager,
                    $authIsStaff
                } = this
                let ref = null
                const assignOwnerRef = () => {
                    const { ownerSearchRef } = this.$refs
                    if(ownerSearchRef) {
                        ref = ownerSearchRef[0]
                    }
                    this.ownersTableKey = this.ownersTableKey + 1
                }
                const assignAdministratorRef = () => {
                    const { adminSearchRef } = this.$refs
                    if(adminSearchRef) {
                        ref = adminSearchRef[0]
                    }
                    this.administratorsTableKey = this.administratorsTableKey + 1
                }
                const assignManagerRef = () => {
                    const { managersSearchRef } = this.$refs
                    if(managersSearchRef) {
                        ref = managersSearchRef[0]
                    }
                    this.managersTableKey = this.managersTableKey + 1
                }
                const assignStaffRef = () => {
                    const { staffSearchRef } = this.$refs
                    if(staffSearchRef) {
                        ref = staffSearchRef[0]
                    }
                    this.staffTableKey = this.staffTableKey + 1
                }
                if(this.tab === 0) {
                    if($authIsOwner) {
                        assignOwnerRef()
                    } else if($authIsAdministrator) {
                        assignManagerRef()
                    } else if($authIsManager) {
                        assignStaffRef()
                    }
                } else if(this.tab === 1) {
                    if($authIsOwner) {
                        assignAdministratorRef()
                    } else if($authIsAdministrator) {
                        assignStaffRef()
                    }
                } else if(this.tab === 2) {
                    assignManagerRef()
                } else if(this.tab === 3) {
                    assignStaffRef()
                }
                if(ref) {
                    const { searchParams } = ref
                    if(searchParams) {
                        this.searchParams = Object.assign({}, searchParams)
                    }
                }
            },

            onChangeAddNewUser(option) {
                if (option.value === 'single') {
                    this.isNewUserFirstStepOnly = true
                    this.window = 'newUser'
                    this.addNewUserKey = this.addNewUserKey + 1
                } else {
                    this.bulkUploadUsersKey = this.bulkUploadUsersKey + 1
                    this.window = 'bulkUsers'
                    this.addNewUserKey = this.addNewUserKey + 1
                }
            },

            onBulkUploadUsers() {
                this.loadUsers()
            },

            onExitBulkUploadUsers() {
                this.window = 'search'
            }
        },

        computed: {
            globalLocation() {
                return this.$root.globalLocation;
            },

            canCreateUsers() {
                return this.$userHasPermission(this.$user, this.PERMISSIONS.USERS.EDIT_OTHERS_PROFILE)
            },

            filteredTabs() {
                if(this.$authIsOwner) {
                    return this.tabs
                } else if(this.$authIsAdministrator) {
                    return this.tabs.slice(2, 4)
                } else if(this.$authIsManager) {
                    return this.tabs.slice(3, 4)
                } else {
                    return this.tabs
                }
            },

            currentTab() {
                const { filteredTabs, tab } = this
                if(Array.isArray(filteredTabs) && tab < filteredTabs.length) {
                    return filteredTabs[tab]
                }
                return null
            },

            shouldUpdateStaffTableOptions() {
                if(this.currentTab) {
                    return this.currentTab.name === 'Staff'
                }
                return false
            },

            shouldUpdateManagerTableOptions() {
                if(this.currentTab) {
                    return this.currentTab.name === 'Manager'
                }
                return false
            },

            shouldUpdateAdministratorTableOptions() {
                if(this.currentTab) {
                    return this.currentTab.name === 'Administrator'
                }
                return false
            },

            shouldUpdateOwnersTableOptions() {
                if(this.currentTab) {
                    return this.currentTab.name === 'Owner'
                }
                return false
            },

            staffingDetails() {
                const allStaffingDetails = this.departments.map(department => {
                    if(Array.isArray(department.staffing_details)) {
                        return department.staffing_details.map(sd => ({
                            ...sd,
                            department_name: department.name
                        }))
                    }
                    return []
                })
                return _.uniqBy(_.flattenDeep(allStaffingDetails), '_id')
            },

            emailDomains() {
                if(this.hospitalGroup && Array.isArray(this.hospitalGroup.email_domain)) {
                    return this.hospitalGroup.email_domain
                }
                return []
            }
        },

        watch: {
            globalLocation: {
                immediate: true,
                handler() {
                    this.searchParams = {
                        ...this.searchParams,
                        status: null,
                        name: null,
                        staff_group: null,
                        department: null,
                        job_title: null,
                        managers: null,
                    }
                }
            },

            window(newVal, oldVal) {
                if (oldVal !== 'search' && newVal === 'search') {
                    this.loadUsers()
                }
            }
        },

        created() {
            if(this.$authIsOwner) {
                this.tab = 3
            } else if(this.$authIsAdministrator) {
                this.tab = 1
            } else {
                this.tab = 0
            }
            this.jobTitlesDict = this.jobTitles.reduce((jobTitles, jobTitle) => ({
                ...jobTitles,
                [jobTitle._id]: jobTitle
            }), {})
            if (this.$appData.preselected_user) {
                this.viewUser(this.$appData.preselected_user)
                if (this.$appData?.preselected_user?._id === this.$appData?.preselected_time_off_request?.user_id) {
                    this.preselectedTab = 'availability'
                }
            }
        }
    }
</script>

<style scoped>

</style>
