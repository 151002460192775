<template>
    <v-card>
        <v-row class="ma-0">
            <v-col cols="12" class="pa-0">
                <div class="d-flex flex-row align-center profile-tabs-header">
                    <div style="line-height: 56px; margin-left: 10px;" v-if="canGoBack">
                        <v-btn icon dusk="backFromProfileButton" @click="onBackButtonPressed">
                            <v-icon style="color: #000D60;">arrow_back</v-icon>
                        </v-btn>
                    </div>

                    <div class="ml-4 mr-4" v-if="showUsername">
                        <v-icon style="color: #000D60;">mdi-account</v-icon>
                    </div>

                    <div style="line-height: 56px; color: #000D60;" class="text--h2" v-if="showUsername">
                        {{ user.first_name }} {{ user.last_name }}
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-tabs
            v-model="tab"
            dark
            grow
            height="56"
            slider-color="#4253C4 !important"
            slider-size="3"
            style="padding: 8px 16px 0;"
            @change="onTabChange"
            :key="tabsKey"
        >
            <v-tab
                v-for="item in filteredTabs"
                :disabled="item.disabled"
                :key="item.tab"
                :name="item.name"
                @click="onTabClick"
            >
                <v-tooltip
                    v-if="item.hasIcon"
                    :disabled="!item.tooltip"
                    content-class="koroid-tooltip"
                    bottom
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                            left
                            color="warning">
                            mdi-alert-circle
                        </v-icon>
                    </template>
                    <span>{{ item.tooltip }}</span>
                </v-tooltip>

               {{ item.name }}
            </v-tab>

        </v-tabs>
        <div style="border-bottom: 1px solid #777680; margin: 0 16px;"/>

        <v-tabs-items v-model="tab" :key="tabItemsKey">
            <v-tab-item
                v-for="item in filteredTabs"
                :key="item.tab"
            >
                <v-card flat>
                    <v-card-text>
                        <UserProfile
                            v-if="item.tab === 'Profile'"
                            ref="profileRef"
                            :key="profileKey"
                            :user="profile"
                            :profile-view="profileView"
                            :new-user="newUser"
                            :new-user-first-step-only="newUserFirstStepOnly"
                            :user-has-all-permissions="userHasAllPermissions"
                            :roles="roles"
                            :staffing-details="staffingDetails"
                            :email-domains="emailDomains"
                            @onNextTab="onUserProfileNext"
                            @onUpdateUserLocations="onUpdateUserLocations"
                            @loadUsers="onLoadUsers"
                            @user:saved="onSaveProfileRemount"
                            @user:deleted="onUserDeleted"
                            @status:updated="onUserStatusUpdated"
                        ></UserProfile>
                        <UserJobsTab
                            v-else-if="item.tab === 'Jobs'"
                            ref="jobsRef"
                            :key="jobsKey"
                            :user="profile"
                            :new-user="newUser"
                            @onPreviousTab="onPreviousTab"
                            @onNextTab="onUserJobsNext"
                            @user:saved="onSaveJobs"></UserJobsTab>
                        <UserPermissions
                            v-else-if="item.tab === 'Permissions'"
                            ref="permissionsRef"
                            :key="permissionsKey"
                            :user="profile"
                            :new-user="newUser"
                            :roles="roles"
                            @onPreviousTab="onPreviousTab"
                            @onNextTab="onNextTab"
                            @user:saved="onSaveProfile"
                        >
                        </UserPermissions>
                        <UserAvailability
                            name="user-availability-tab"
                            v-else-if="item.tab === 'Availability'"
                            @upcomingAvailabilityInfoReceived="upcomingAvailabilityInfoReceived"
                            :user-prop="profile"
                        ></UserAvailability>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>

        <unsaved-changes-dialog ref="unsavedChangesDialog"/>
    </v-card>
</template>

<script>
    import UserProfile from "./UserProfile";
    import UserJobsTab from "./user-jobs/UserJobsTab";
    import UserAvailability from "./availability/UserAvailability";
    import UserPermissions from "./UserPermissions";
    import UnsavedChangesDialog from "../modals/UnsavedChangesDialog";
    export default {
        name: "UserProfileTabs",
        components: {UserJobsTab, UnsavedChangesDialog, UserPermissions, UserAvailability, UserProfile },
        props: {
            user: {
                type: Object,
                default: () => {}
            },
            profileView: {
                type: Boolean,
                default: true,
            },
            newUser: {
                type: Boolean,
                default: false,
            },
            newUserFirstStepOnly: {
                type: Boolean,
                default: false,
            },
            canGoBack: {
                type: Boolean,
                default: false,
            },
            userHasAllPermissions: {
                type: Boolean,
                default: false,
            },
            roles: {
                type: Array,
                default: () => []
            },
            emailDomains: {
                type: Array,
                default: () => []
            },
            jobTitlesDict: {
                type: Object,
                default: () => {}
            },
            staffingDetails: {
                type: Array,
                default: () => []
            },
            preselectedTab: {
                type: String,
                default: null
            }
        },
        data() {
            let selectedRole
            if(this.newUser) {
                selectedRole = this.roles.find(role => role.name === 'Staff')._id
            } else {
                selectedRole = this.user.role_id
            }

            return {
                tab: 0,
                prevTab: 0,
                nextTab: 0,
                tabs: [
                    { tab: 'Profile', name: 'Profile', disabled: false, },
                    { tab: 'Jobs', name: 'Jobs', disabled: false, },
                    { tab: 'Permissions', name: 'Permissions', disabled: false, },
                    { tab: 'Availability', name: 'Availability', disabled: false, },
                ],
                profile: Object.assign({}, this.user),
                workingLocations: this.newUser ? [] : Array.isArray(this.user.locations) ? Array.from(this.user.locations) : [],
                savedUser: {},
                PERMISSIONS: this.$config.permissions,
                tabsKey: 10000,
                profileKey: 20000,
                jobsKey: 30000,
                permissionsKey: 40000,
                tabItemsKey: 50000,
                savingChanges: false,
                savingBackChanges: false,
                selectedRole
            }
        },
        computed: {
            filteredTabs() {
                const {
                    profileView,
                    $authIsOwner,
                    $authIsAdministrator,
                    $authIsManager,
                    $userIsOwner,
                    $userIsAdministrator,
                    $userIsManager,
                    $userIsStaff,
                    $userHasPermission,
                    $user,
                    user,
                    tabs,
                    userHasAllPermissions,
                    newUser,
                    PERMISSIONS,
                    selectedRole,
                    roles
                } = this
                if(profileView) {
                    const workScheduleDisabled = Boolean(user.staff_type?.work_schedule_config !== 'user_defined')
                    if($authIsOwner ||
                        $authIsAdministrator ||
                        $authIsManager || (!$userHasPermission($user, PERMISSIONS.AVAILABILITY.VIEW_OWN) && workScheduleDisabled &&
                            !$userHasPermission($user, PERMISSIONS.USERS.VIEW_OWN_JOBS))) {
                        return [
                            {
                                tab: 'Profile',
                                name: `${user.first_name} ${user.last_name}`,
                                disabled: false,
                            }
                        ]
                    }
                    const availableTabs = [tabs[0]]
                    if($userHasPermission($user, PERMISSIONS.USERS.VIEW_OWN_JOBS)) {
                        availableTabs.push(tabs[1])
                    }
                    if($userHasPermission($user, PERMISSIONS.AVAILABILITY.VIEW_OWN) && !workScheduleDisabled) {
                        availableTabs.push(tabs[3])
                    }
                    return this.appendIcons(availableTabs)
                }
                if(userHasAllPermissions) {
                    return tabs.slice(0, 1)
                }
                if(newUser) {
                    const role = roles.find(r => r._id === selectedRole)
                    const { name } = role
                    if(name) {
                        let availableTabs = []
                        availableTabs.push(tabs[0])
                        if(name === 'Staff') {
                            if($userHasPermission($user, PERMISSIONS.USERS.EDIT_OTHERS_JOBS)) {
                                availableTabs.push(tabs[1])
                            }
                            if($userHasPermission($user, PERMISSIONS.USERS.EDIT_OTHERS_PERMISSIONS)) {
                                availableTabs.push(tabs[2])
                            }
                            if($userHasPermission($user, PERMISSIONS.AVAILABILITY.EDIT_OTHERS)) {
                                availableTabs.push(tabs[3])
                            }
                        } else if(name === 'Administrator' || name === 'Manager') {
                            if($userHasPermission($user, PERMISSIONS.USERS.EDIT_OTHERS_PERMISSIONS)) {
                                availableTabs.push(tabs[2])
                            }
                        }
                        return availableTabs.map(tab => {
                            if(tab.name === 'Jobs') {
                                return {
                                    ...tab,
                                    disabled: true,
                                    hasIcon: this.user && Array.isArray(this.user.jobs) ? this.user.jobs.length < 1 : true
                                }
                            } else if(tab.name === 'Availability') {
                                return {
                                    ...tab,
                                    disabled: true,
                                    hasIcon: true
                                }
                            } else {
                                return {
                                    ...tab,
                                    disabled: true
                                }
                            }
                        })
                    }
                    return tabs.map(tab => {
                        if(tab.name === 'Jobs') {
                            return {
                                ...tab,
                                disabled: true,
                                hasIcon: this.user && Array.isArray(this.user.jobs) ? this.user.jobs.length < 1 : true
                            }
                        } else if(tab.name === 'Availability') {
                            return {
                                ...tab,
                                disabled: true,
                                hasIcon: true
                            }
                        } else {
                            return {
                                ...tab,
                                disabled: true
                            }
                        }
                    })
                } else {
                    let availableTabs = []
                    if($userIsStaff(user)) {
                        if($userHasPermission($user, PERMISSIONS.USERS.VIEW_OTHERS_PROFILE)) {
                            availableTabs.push(tabs[0])
                        }
                        if($userHasPermission($user, PERMISSIONS.USERS.VIEW_OTHERS_JOBS)) {
                            availableTabs.push(tabs[1])
                        }
                        if($userHasPermission($user, PERMISSIONS.USERS.VIEW_OTHERS_PERMISSIONS)) {
                            availableTabs.push(tabs[2])
                        }
                        if($userHasPermission($user, PERMISSIONS.AVAILABILITY.VIEW_OTHERS)) {
                            availableTabs.push(tabs[3])
                        }
                    } else if($userIsAdministrator(user) || $userIsManager(user)) {
                        if($userHasPermission($user, PERMISSIONS.USERS.VIEW_OTHERS_PROFILE)) {
                            availableTabs.push(tabs[0])
                        }
                        if($userHasPermission($user, PERMISSIONS.USERS.VIEW_OTHERS_PERMISSIONS)) {
                            availableTabs.push(tabs[2])
                        }
                    } else if($userIsOwner(user)) {
                        if($userHasPermission($user, PERMISSIONS.USERS.VIEW_OTHERS_PROFILE)) {
                            availableTabs.push(tabs[0])
                        }
                    }
                    return this.appendIcons(availableTabs)
                }
            },
            showUsername() {
                if(this.profileView) {
                    if(!this.$authIsOwner) {
                        return this.$userHasPermission(this.$user, this.PERMISSIONS.AVAILABILITY.VIEW_OWN)
                    }
                    return false
                }
                return !this.newUser
            },
        },
        methods: {
            onSaveProfile(user, onUserSaved) {
                this.profile = user
                this.$emit('user:saved', user, onUserSaved)
            },
            onSaveJobs(user) {
                this.profile = user

                if (!this.newUser) {
                    this.$emit('user:saved', user)
                }
            },
            onSaveProfileRemount(user, onUserSaved) {
                this.onSaveProfile(user, onUserSaved)
                this.selectedRole = user.role_id
                this.tabsKey = this.tabsKey + 1
                this.tabItemsKey = this.tabItemsKey + 1
                this.profileKey = this.profileKey + 1
                this.jobsKey = this.jobsKey + 1
                this.permissionsKey = this.permissionsKey + 1
            },
            onUserStatusUpdated({status, status_changed_at}) {
                this.profile.status = status
                this.profile.status_changed_at = status_changed_at
                this.$emit('status:updated', {status: status, status_changed_at: status_changed_at})
            },
            upcomingAvailabilityInfoReceived(data) {
                this.profile = {
                    ...this.profile,
                    has_future_availability: data
                }
                if(this.profileView) {
                    this.$emit('upcomingAvailabilityInfoReceived', Object.assign({}, this.profile))
                } else {
                    this.$emit('user:saved', Object.assign({}, this.profile))
                }
            },
            onUserDeleted() {
                this.$emit('user:deleted', this.profile._id)
                this.goBack()
            },
            goBack() {
                this.$emit('onBackButtonPressed')
                this.tab = 0
            },
            onBackButtonPressed() {
                if (this.newUser) {
                    this.onLoadUsers()
                    this.goBack()
                } else {
                    this.popSavedChangesModalWhenGoingBack()
                }
            },
            onPreviousTab() {
                this.tab = this.tab - 1
            },
            onNextTab() {
                this.tab = this.tab + 1
            },
            onUserProfileNext(user, selectedRole) {
                this.selectedRole = selectedRole
                this.$emit('user-profile:updated', user, selectedRole)
                this.tabsKey = this.tabsKey + 1
                this.tabItemsKey = this.tabItemsKey + 1
                this.profileKey = this.profileKey + 1
                this.jobsKey = this.jobsKey + 1
                this.permissionsKey = this.permissionsKey + 1
                const role = this.roles.find(r => r._id === selectedRole)
                const PERMISSIONS = this.$config.permissions
                if(role && role.name) {
                    if(role.name === 'Staff') {
                        if(this.$userHasPermission(this.$user, PERMISSIONS.USERS.EDIT_OTHERS_JOBS) ||
                            this.$userHasPermission(this.$user, PERMISSIONS.USERS.EDIT_OTHERS_PERMISSIONS) ||
                            this.$userHasPermission(this.$user, PERMISSIONS.AVAILABILITY.EDIT_OTHERS)) {
                            this.$nextTick(this.onNextTab)
                        }
                    } else if(role.name === 'Administrator' || role.name === 'Manager') {
                        if(this.$userHasPermission(this.$user, PERMISSIONS.USERS.EDIT_OTHERS_PERMISSIONS)) {
                            this.$nextTick(this.onNextTab)
                        }
                    }
                } else {
                    this.$nextTick(this.onNextTab)
                }
            },
            onUserJobsNext() {
                const PERMISSIONS = this.$config.permissions
                if(this.$userHasPermission(this.$user, PERMISSIONS.USERS.EDIT_OTHERS_PERMISSIONS) ||
                    this.$userHasPermission(this.$user, PERMISSIONS.AVAILABILITY.EDIT_OTHERS)) {
                    const { jobsRef } = this.$refs
                    if(jobsRef && jobsRef[0].isDirty()) {
                        this.$refs.unsavedChangesDialog.open()
                            .then(() => {
                                this.jobsKey = this.jobsKey + 1
                                this.tab = this.tab + 1
                            })
                            .catch(() => {
                            })
                    } else {
                        this.onNextTab()
                    }
                }
            },
            onUpdateUserLocations(locations) {
                this.workingLocations = Array.isArray(locations) ? Array.from(locations) : []
            },
            onTabChange() {
                if(!this.newUser) {

                    const onNextTabDirty = () => {
                        this.nextTab = this.tab
                        this.tab = this.prevTab
                        this.showUnsavedChangesDialog()
                    }

                    const onNextTabDirtyFromProfile = () => {
                        const { profileRef } = this.$refs
                        if(profileRef && profileRef[0].isDirty()) {
                            onNextTabDirty()
                        }
                    }

                    const onNextTabDirtyFromJobs = () => {
                        const { jobsRef } = this.$refs
                        if(jobsRef && jobsRef[0].isDirty()) {
                            onNextTabDirty()
                        }
                    }

                    const onNextTabDirtyFromPermissions = () => {
                        const { permissionsRef } = this.$refs
                        if(permissionsRef && permissionsRef[0].isDirty()) {
                            onNextTabDirty()
                        }
                    }

                    this.executeCallbackFromPreviousTab(
                        onNextTabDirtyFromProfile,
                        onNextTabDirtyFromJobs,
                        onNextTabDirtyFromPermissions
                    )
                }
            },
            onTabClick() {
                this.prevTab = this.tab
            },
            onSaveBackChanges() {
                this.savingBackChanges = true
                const onGoingBack = () => {
                    this.savingBackChanges = false
                    this.tabsKey = this.tabsKey + 1
                    this.goBack()
                }

                const onSaveProfile = () => {
                    this.$refs.profileRef[0].onSaveProfile(() => {
                        onGoingBack()
                        this.profileKey = this.profileKey + 1
                    }, () => {
                        this.savingBackChanges = false
                    })
                }

                const onSaveJobs = () => {
                    this.$refs.jobsRef[0].onSaveProfile(() => {
                        onGoingBack()
                        this.jobsKey = this.jobsKey + 1
                    })
                }

                const onSavePermissions = () => {
                    this.$refs.permissionsRef[0].onSaveProfile(() => {
                        onGoingBack()
                        this.permissionsKey = this.permissionsKey + 1
                    })
                }

                this.executeCallbackFromTab(onSaveProfile, onSaveJobs, onSavePermissions, this.tab)
            },
            showCancelBackChangesModal() {
                this.$refs.unsavedChangesDialog.open()
                    .then(() => {
                        this.onCancelBackChangesAndContinue()
                    })
                    .catch(() => {
                        this.onCancelBackChanges()
                    })
            },
            onCancelBackChangesAndContinue() {
                this.goBack()
            },
            onCancelBackChanges() {
                this.tabsKey = this.tabsKey + 1
            },
            onSaveChanges() {
                this.savingChanges = true

                const onNextTab = () => {
                    this.savingChanges = false
                    this.tabsKey = this.tabsKey + 1
                    this.tab = this.nextTab
                }

                const onSaveProfile = () => {
                    this.$refs.profileRef[0].onSaveProfile(() => {
                        onNextTab()
                        this.profileKey = this.profileKey + 1
                    }, () => {
                        this.savingChanges = false
                        this.tabsKey = this.tabsKey + 1
                    })
                }

                const onSaveJobs = () => {
                    this.$refs.jobsRef[0].onSaveProfile(() => {
                        onNextTab()
                        this.jobsKey = this.jobsKey + 1
                    })
                }

                const onSavePermissions = () => {
                    this.$refs.permissionsRef[0].onSaveProfile(() => {
                        onNextTab()
                        this.permissionsKey = this.permissionsKey + 1
                    })
                }

                this.executeCallbackFromPreviousTab(
                    onSaveProfile,
                    onSaveJobs,
                    onSavePermissions
                )
            },

            showUnsavedChangesDialog() {
                this.$refs.unsavedChangesDialog.open()
                    .then(() => {
                        this.onCancelSaveChangesAndContinue()
                    })
                    .catch(() => {
                        this.onCancelSaveChanges()
                    })
            },

            onCancelSaveChangesAndContinue() {
                this.tabsKey = this.tabsKey + 1

                const resetProfile = () => {
                    this.profileKey = this.profileKey + 1
                }

                const resetJobs = () => {
                    this.jobsKey = this.jobsKey + 1
                }

                const resetPermissions = () => {
                    this.permissionsKey = this.permissionsKey + 1
                }

                this.executeCallbackFromPreviousTab(
                    resetProfile,
                    resetJobs,
                    resetPermissions
                )
                this.tab = this.nextTab
            },
            onCancelSaveChanges() {
                this.tabsKey = this.tabsKey + 1
                this.tab = this.prevTab
            },
            onLoadUsers() {
                this.$emit('loadUsers')
            },

            executeCallbackFromPreviousTab(profileCallback, jobsCallback, permissionsCallback) {
                this.executeCallbackFromTab(profileCallback, jobsCallback, permissionsCallback, this.prevTab)
            },

            executeCallbackFromTab(profileCallback, jobsCallback, permissionsCallback, tab) {
                const {
                    PERMISSIONS,
                    $userIsStaff,
                    $userHasPermission,
                    $user,
                    user,
                } = this

                if($userIsStaff(user)) {
                    if(tab === 0) {
                        profileCallback()
                    } else if(tab === 1) {
                        if($userHasPermission($user, PERMISSIONS.USERS.VIEW_OTHERS_JOBS)) {
                            jobsCallback()
                        } else {
                            if($userHasPermission($user, PERMISSIONS.USERS.VIEW_OTHERS_PERMISSIONS)) {
                                permissionsCallback()
                            }
                        }
                    } else if(tab === 2) {
                        if($userHasPermission($user, PERMISSIONS.USERS.VIEW_OTHERS_JOBS)) {
                            if($userHasPermission($user, PERMISSIONS.USERS.VIEW_OTHERS_PERMISSIONS)) {
                                permissionsCallback()
                            }
                        }
                    }
                } else {
                    if(tab === 0) {
                        profileCallback()
                    } else if(tab === 1) {
                        permissionsCallback()
                    }
                }
            },

            popSavedChangesModalWhenGoingBack() {
                const {
                    PERMISSIONS,
                    $userIsStaff,
                    $userHasPermission,
                    $user,
                    user,
                } = this

                const profileCallback = () => {
                    const { profileRef } = this.$refs
                    if(profileRef && profileRef[0].isDirty()) {
                        this.showCancelBackChangesModal()
                    } else {
                        this.goBack()
                    }
                }

                const jobsCallback = () => {
                    const { jobsRef } = this.$refs
                    if(jobsRef && jobsRef[0].isDirty()) {
                        this.showCancelBackChangesModal()
                    } else {
                        this.goBack()
                    }
                }

                const permissionsCallback = () => {
                    const { permissionsRef } = this.$refs
                    if(permissionsRef && permissionsRef[0].isDirty()) {
                        this.showCancelBackChangesModal()
                    } else {
                        this.goBack()
                    }
                }

                if($userIsStaff(user)) {
                    if(this.tab === 0) {
                        profileCallback()
                    } else if(this.tab === 1) {
                        if($userHasPermission($user, PERMISSIONS.USERS.VIEW_OTHERS_JOBS)) {
                            jobsCallback()
                        } else {
                            if($userHasPermission($user, PERMISSIONS.USERS.VIEW_OTHERS_PERMISSIONS)) {
                                permissionsCallback()
                            } else {
                                if($userHasPermission($user, PERMISSIONS.AVAILABILITY.VIEW_OTHERS)) {
                                    this.goBack()
                                }
                            }
                        }
                    } else if(this.tab === 2) {
                        if($userHasPermission($user, PERMISSIONS.USERS.VIEW_OTHERS_JOBS)) {
                            if($userHasPermission($user, PERMISSIONS.USERS.VIEW_OTHERS_PERMISSIONS)) {
                                permissionsCallback()
                            } else {
                                if($userHasPermission($user, PERMISSIONS.AVAILABILITY.VIEW_OTHERS)) {
                                    this.goBack()
                                }
                            }
                        } else {
                            if($userHasPermission($user, PERMISSIONS.AVAILABILITY.VIEW_OTHERS)) {
                                this.goBack()
                            }
                        }
                    } else if(this.tab === 3) {
                        this.goBack()
                    }
                } else {
                    if(this.tab === 0) {
                        profileCallback()
                    } else if(this.tab === 1) {
                        permissionsCallback()
                    }
                }
            },

            appendIcons(availableTabs) {
                return availableTabs.map(tab => {
                    let hasIcon = false
                    let tooltip = null

                    if (tab.name === 'Jobs') {
                        hasIcon = this.user && Array.isArray(this.user.jobs) && this.user.jobs.length < 1

                        if (hasIcon) {
                            tooltip = this.profileView ?
                                'A job title is required to be assigned to shifts' :
                                'A job title is required to assign this user to shifts'
                        }
                    } else if (tab.name === 'Availability') {
                        let has_availability = false

                        if (typeof this.user.has_future_availability !== 'undefined' && this.user.has_future_availability) {
                            has_availability = true
                        } else if (typeof this.user.has_upcoming_availability !== 'undefined' && this.user.has_upcoming_availability) {
                            has_availability = true
                        }

                        hasIcon = !has_availability

                        if (hasIcon) {
                            tooltip = this.profileView ?
                                'No current Availability. Keep your availability up to date to help build a schedule that works for you.' :
                                'No current Availability. Add availability to directly assign this user to shifts'
                        }

                    }

                    return {
                        ...tab,
                        hasIcon: hasIcon,
                        tooltip: tooltip
                    }
                })
            }
        },
        created() {
            if (this.preselectedTab === 'availability') {
                let tab_index = this.filteredTabs.findIndex(tab_item => tab_item.name === 'Availability')

                if (tab_index !== -1) {
                    this.tab = tab_index
                }
            }
        },
        watch: {
            user(newUser) {
                this.profile = newUser
                this.workingLocations = newUser.locations ? Array.from(newUser.locations) : []
            }
        }
    }
</script>

<style scoped>
:deep(.v-tabs-bar) {
    background-color: transparent !important;
}

.theme--dark.v-tabs > .v-tabs-bar .v-tab--disabled, .theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active), .theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn, .theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon {
    color: #74747B !important;
}

.theme--dark.v-tabs > .v-tabs-bar, .theme--dark.v-tabs > .v-tabs-bar .v-tab, .theme--dark.v-tabs > .v-tabs-bar .v-tab > .v-btn, .theme--dark.v-tabs > .v-tabs-bar .v-tab > .v-icon {
    color: #4253C4 !important;
}

.v-tab {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-transform: none;
}

.profile-tabs-header {
    background-color: #DFE0FF;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}
</style>
