<template>
    <stat icon="mdi-clock-outline" :value="formattedDuration"/>
</template>

<script>
import Stat from "./Stat";
import {formatMinutes} from "../../../../../lib/helpers/formatMinutes";

export default {
    name: "ShiftsDuration",
    components: {Stat},
    props: {
        duration: {
            type: Number,
            default: 0
        },
        maxDuration: {
            type: Number,
            default: 0
        }
    },
    computed: {
        formattedDuration() {
            const duration = formatMinutes(this.duration)
            if (this.maxDuration > 0) {
                const maxDuration = formatMinutes(this.maxDuration)
                return `${duration}/${maxDuration}`
            }
            return duration
        }
    }
}
</script>

<style scoped>

</style>
