<template>
    <v-dialog v-model="showModal" scrollable width="400px" persistent>
        <v-card>
            <v-toolbar class="informational-modal" elevation="0">
                <v-toolbar-title>View Details</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeModal">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text class="mt-6">
                <div v-if="duration" class="tooltip-container">
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" class="d-flex flex-row align-center label-margin mb-2">
                                <v-icon color="primary" class="mr-2" title="Duration">mdi-timer-outline</v-icon>
                                <span class="black--text">{{ duration }}</span>
                            </div>
                        </template>
                        <span>Duration</span>
                    </v-tooltip>
                </div>

                <div v-if="shift_time" class="tooltip-container">
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" class="d-flex flex-row align-center label-margin mb-2">
                                <v-icon color="primary" class="mr-2" title="Shift Time Preset">mdi-clock-outline</v-icon>
                                <span class="black--text">{{ shift_time }}</span>
                            </div>
                        </template>
                        <span>Shift Time Preset</span>
                    </v-tooltip>
                </div>

                <div v-if="created_by" class="tooltip-container">
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" class="d-flex flex-row align-center label-margin mb-2">
                                <v-icon color="primary" class="mr-2" title="Created By">mdi-account-multiple</v-icon>
                                <span class="black--text">{{ created_by }}</span>
                            </div>
                        </template>
                        <span>Created By</span>
                    </v-tooltip>
                </div>

                <div v-if="location" class="tooltip-container">
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" class="d-flex flex-row align-center label-margin mb-2">
                                <v-icon color="primary" class="mr-2" title="Location">mdi-city</v-icon>
                                <span class="black--text">{{ location }}</span>
                            </div>
                        </template>
                        <span>Location</span>
                    </v-tooltip>
                </div>

                <div class="shift-request-section" v-for="(shift_request, index) in shift_requests" :key="index">
                    <div class="tooltip-container">
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on" class="d-flex flex-row align-center label-margin mb-2">
                                    <v-icon color="primary" class="mr-2" title="Job Title">mdi-clipboard-account</v-icon>
                                    <span class="black--text">{{ shift_request.job_title }}</span>
                                </div>
                            </template>
                            <span>Job Title</span>
                        </v-tooltip>
                    </div>

                    <div class="tooltip-container">
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on" class="d-flex flex-row align-center label-margin mb-2">
                                    <v-icon color="primary" class="mr-2" title="Credentials">mdi-license</v-icon>
                                    <span class="black--text">{{ shift_request.credentials }}</span>
                                </div>
                            </template>
                            <span>Credentials</span>
                        </v-tooltip>
                    </div>
                    
                </div>
            </v-card-text>

        </v-card>
    </v-dialog>
</template>

<script>
import StaffSchedulerLabel from "./StaffSchedulerLabel";
export default {
    name: "RequestDetailsPanel",
    components: {StaffSchedulerLabel},
    props: {},

    data() {
        return {
            showModal: false,
            created_by: null,
            location: null,
            duration: null,
            shift_requests: [],
            shift_time: null
        }
    },

    methods: {
        open(created_by, location, duration, shift_requests, shift_time) {
            this.created_by = created_by
            this.location = location
            this.duration = duration
            this.shift_requests = this.format_shift_requests(shift_requests)
            this.shift_time = shift_time ?? 'Custom'

            this.showModal = true
        },

        format_shift_requests(shift_requests) {
            return shift_requests.map(function (shift_request) {
                let credentials = 'N/A'

                if (shift_request.credentials.length) {
                    credentials = shift_request.credentials.map(c => c.name).join(', ')
                }

                return {
                    job_title: `${shift_request.job_title} (${shift_request.filled_shifts}/${shift_request.amount})`,
                    credentials: credentials
                }
            })
        },

        closeModal() {
            this.showModal = false
            this.created_by = null
            this.location = null,
            this.duration = null
            this.shift_requests = []
            this.shift_time = null
        }
    },
}
</script>

<style scoped>
    @media only screen and (min-width: 960px) {
        .label-margin {
            margin-left: 0;
        }
    }
    @media only screen and (max-width: 959px) {
        .label-margin {
            margin-left: 10px;
        }
    }

    .shift-request-section {
        padding-top: 10px;
        border-top: 1px solid #D3D3D3;
    }

    .tooltip-container {
        display: flex;
        align-items: center;
        gap: 6px; /* Adjust spacing between item and tooltip */
    }

    .tooltip-wrapper {
        display: inline-flex;
        align-items: center;
    }
</style>
