<template>
    <v-dialog
        v-model="modalValue"
        scrollable
        max-width="1100px"
        persistent
        content-class="ds-dialog"
    >
        <v-card class="ds-modal-card" elevation="2" dusk="saveEmploymentTypeModal">
            <modal-toolbar
                :title="employmentTypeId ? 'Employment Type Details' : 'New Employment Type'"
                @close="onClose"/>

            <v-card-text class="ds-modal-content" :style="contentStyle">
                <v-form
                    ref="employmentTypeForm"
                    :lazy-validation="false"
                    class="pt-2"
                >
                    <v-row>
                        <div v-show="isFetchingDetails" style="height:100%">
                            <loading-spinner :value="true"></loading-spinner>
                        </div>

                        <v-col cols="12" class="ds-modal-input-container">
                            <v-text-field
                                name="employment-type-name"
                                label="Employment Type Name"
                                v-model.trim="employmentType.name"
                                :error-messages="api.hasError('name')"
                                :rules="rules.required"
                                class="ds-modal-input"
                                autocomplete="off"
                                outlined
                                :clearable="!readonly"
                                :readonly="readonly"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" class="mb-1" style="margin-top: 0 !important; padding-top: 0 !important;">
                            <span class="text--h3" style="color: #000000 !important;">Payroll Period Type</span>
                        </v-col>

                        <payroll-period
                            v-model="payrollPeriod"
                            :key="payrollPeriodKey"
                            :readonly="readonly"
                        />
                    </v-row>

                    <v-row>
                        <v-col cols="12" class="mb-1">
                            <span class="text--h3" style="color: #000000 !important;">Payroll Period Limits</span>
                        </v-col>

                        <v-col cols="4" class="ds-modal-input-container">
                            <number-input
                                :key="maxHoursKey"
                                name="employment-type-max-weekly-hours"
                                label="Max Hours"
                                v-model="employmentType.maxWeeklyHours"
                                :error-messages="api.hasError('max_work_hours')"
                                :min="1"
                                :max="maxHoursLimit"
                                controls
                                required
                                clearable
                                :readonly="readonly"
                                class="ds-modal-input"
                            />
                        </v-col>

                        <v-col cols="4" class="ds-modal-input-container">

                            <v-tooltip top :disabled="employmentType.enforceMaxHoursLimit">
                                <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    <number-input
                                        :key="overtimeHoursKey"
                                        name="employment-type-overtime-hours"
                                        label="Overtime Hours"
                                        v-model="employmentType.overtimeHours"
                                        :error-messages="api.hasError('overtime_hours')"
                                        :min="0"
                                        :max="maxHoursLimit"
                                        controls
                                        :required="employmentType.enforceMaxHoursLimit"
                                        :disabled="!employmentType.enforceMaxHoursLimit"
                                        clearable
                                        :readonly="readonly"
                                        class="ds-modal-input"
                                    />
                                </div>
                                </template>
                                <span>Enforce limit to add a payroll period restriction.</span>
                            </v-tooltip>

                        </v-col>

                        <v-col cols="4" class="ds-modal-input-container">
                            <v-checkbox
                                v-model="employmentType.enforceMaxHoursLimit"
                                :error-messages="api.hasError('enforce_max_hours_limit')"
                                hide-details
                                :readonly="readonly"
                                style="margin-top: 11px !important; padding-top: 0 !important;"
                                @change="onChangeEnforceMaxHoursLimit"
                            >
                                <template v-slot:label>
                                    <div class="d-flex flex-column ml-2">
                                        <div class="text--h5" style="margin-bottom: 2px !important;">
                                            Enforce Limit
                                        </div>
                                        <div class="text--body-sm">
                                            Do not allow shifts to exceed payroll period limits.
                                        </div>
                                    </div>
                                </template>
                            </v-checkbox>
                        </v-col>

                        <v-col cols="12" class="ds-modal-input-container" style="margin-bottom: 12px;">
                            <v-divider/>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" class="mb-1">
                            <span class="text--h3" style="color: #000000 !important;">Employment Limits</span>
                        </v-col>

                        <v-col class="ds-modal-input-container">
                            <time-input
                                :key="maxDailyHoursKey"
                                name="employment-type-max-daily-hours"
                                label="Max Hours/Day"
                                v-model="employmentType.maxDailyHours"
                                placeholder="HH:MM"
                                :min="60"
                                :max="1440"
                                :error-messages="api.hasError('max_daily_hours')"
                                controls
                                required
                                clearable
                                :readonly="readonly"
                                class="ds-modal-input"
                            />
                        </v-col>

                        <v-col class="ds-modal-input-container">
                            <time-input
                                :key="maxConsecutiveHoursKey"
                                name="employment-type-max-consecutive-hours"
                                label="Max Consecutive Hours"
                                v-model="employmentType.maxConsecutiveHours"
                                placeholder="HH:MM"
                                :min="0"
                                :max="2160"
                                :error-messages="api.hasError('max_consecutive_hours')"
                                controls
                                clearable
                                :readonly="readonly"
                                class="ds-modal-input"
                            />
                        </v-col>

                        <v-col class="ds-modal-input-container">
                            <time-input
                                :key="requiredBreakKey"
                                name="employment-type-max-required-breaks"
                                label="Required Break"
                                v-model="employmentType.requiredBreak"
                                placeholder="HH:MM"
                                :min="0"
                                :max="1440"
                                :error-messages="api.hasError('required_break')"
                                controls
                                clearable
                                :readonly="readonly"
                                class="ds-modal-input"
                            />
                        </v-col>

                        <v-col cols="auto" style="padding-left: 0; padding-top: 0; padding-bottom: 0;">
                            <employment-type-tooltip/>
                        </v-col>

                        <v-col cols="12" class="ds-modal-input-container" style="margin-bottom: 12px;">
                            <v-divider/>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <span class="text--h3" style="color: #000000 !important;">Availability Type</span>
                        </v-col>

                        <employment-type-working-hours
                            v-model="selectedWorkingHours"
                            :readonly="readonly"
                        />

                    </v-row>

                    <v-expand-transition>
                        <v-row v-if="selectedWorkingHours === 'assigned'">
                            <v-col cols="12">
                                <employment-type-assigned-working-hours
                                    v-model="assignedWorkingHours"
                                    :readonly="readonly"
                                />
                            </v-col>
                        </v-row>
                    </v-expand-transition>

                    <v-expand-transition>
                        <v-row v-show="selectedWorkingHours === 'submitted'">
                            <v-col cols="12">
                                <div class="d-flex justify-center align-center text--paragraph">
                                    Each staff member independently submits their availability.
                                </div>
                            </v-col>
                        </v-row>
                    </v-expand-transition>

                    <v-expand-transition>
                        <v-row v-show="selectedWorkingHours === 'none'">
                            <v-col cols="12">
                                <div class="d-flex justify-center align-center text--paragraph">
                                    Shifts are scheduled without availability from staff.
                                </div>
                            </v-col>
                        </v-row>
                    </v-expand-transition>

                    <v-row v-if="selectedWorkingHours">
                        <v-col cols="12">
                            <v-divider/>
                        </v-col>
                    </v-row>


                    <v-expand-transition>
                        <v-row v-show="selectedWorkingHours">
                            <v-col cols="12" style="margin-bottom: 6px;">
                                <span class="text--h3" style="color: #000000 !important;">Scheduling Options</span>
                            </v-col>

                            <v-col cols="12" class="ds-modal-input-container">
                                <employment-type-open-shifts
                                    v-model="openShifts"
                                    :working-hours="selectedWorkingHours"
                                    :readonly="readonly"
                                />
                            </v-col>
                        </v-row>
                    </v-expand-transition>
                </v-form>
            </v-card-text>
            <v-card-actions v-if="!readonly">
                <v-btn
                    v-if="Boolean(employmentTypeId)"
                    text
                    color="error"
                    @click="onClickDeleteEmploymentType"
                    :disabled="isDeleteDisabled"
                >
                    Delete Employment Type
                </v-btn>
                <v-spacer/>
                <v-btn
                    outlined
                    color="primary"
                    :disabled="isSaveDisabled"
                    :loading="isSaving"
                    @click="onSave"
                    class="mr-2"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
        <delete-modal
            v-model="deleteEmploymentTypeModalValue"
            title="Delete Employment Type"
            :message="deleteMessage"
            :is-deleting="isDeleting"
            @onDelete="onDeleteEmploymentType"
        />
        <unsaved-changes-dialog ref="unsavedChangesDialog"/>
    </v-dialog>
</template>

<script>
import PayrollPeriod from "./PayrollPeriod/PayrollPeriod";
import UnsavedChangesDialog from "../../../../../components/modals/UnsavedChangesDialog";
import ModalToolbar from "../../../../../components/modals/components/ModalToolbar";
import LoadingSpinner from "../../../../../components/loaders/LoadingSpinner";
import DeleteModal from "../DeleteModal";
import TimeInput from "../../../../../components/common/TimeInput/TimeInput";
import NumberInput from "../../../../../components/common/NumberInput/NumberInput";
import EmploymentTypeTooltip from "./EmploymentTypeTooltip";
import EmploymentTypeWorkingHours from "./WorkingHours/EmploymentTypeWorkingHours";
import EmploymentTypeAssignedWorkingHours from "./WorkingHours/EmploymentTypeAssignedWorkingHours";
import EmploymentTypeOpenShifts from "./OpenShifts/EmploymentTypeOpenShifts";
import validationRules from "../../../../../lib/mixins/validationRules";

export default {
    name: "SaveEmploymentTypeModal",
    components: {
        PayrollPeriod,
        UnsavedChangesDialog,
        ModalToolbar,
        LoadingSpinner,
        DeleteModal,
        TimeInput,
        NumberInput,
        EmploymentTypeTooltip,
        EmploymentTypeWorkingHours,
        EmploymentTypeAssignedWorkingHours,
        EmploymentTypeOpenShifts
    },
    mixins: [validationRules],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        employmentTypeId: {
            type: String,
            default: null,
        },
        readonly: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            prevEmploymentType: {},
            employmentType: {},
            deleteEmploymentTypeModalValue: false,
            isFetchingDetails: false,
            maxHoursKey: 3333,
            overtimeHoursKey: 4444,
            maxDailyHoursKey: 5555,
            maxConsecutiveHoursKey: 6666,
            requiredBreakKey: 7777,
            isSaving: false,
            isDeleting: false,
            deleteMessage: '',
            prevPayrollPeriod: {
                period: this.employmentTypeId ? null : 'weekly',
                startsOn: 0,
            },
            payrollPeriod: {
                period: this.employmentTypeId ? null : 'weekly',
                startsOn: 0,
            },
            payrollPeriodKey: 1234,
            prevSelectedWorkingHours: 'none',
            selectedWorkingHours: 'none',
            prevOpenShifts: {
                whDirectAssign: false,
                whSelfAssign: false,
                owhOffer: false,
                owhSelfAssign: false
            },
            openShifts: {
                whDirectAssign: false,
                whSelfAssign: false,
                owhOffer: false,
                owhSelfAssign: false
            },
            prevAssignedWorkingHours: [
                {
                    enabled: false,
                    startTime: null,
                    endTime: null,
                },
                {
                    enabled: false,
                    startTime: null,
                    endTime: null,
                },
                {
                    enabled: false,
                    startTime: null,
                    endTime: null,
                },
                {
                    enabled: false,
                    startTime: null,
                    endTime: null,
                },
                {
                    enabled: false,
                    startTime: null,
                    endTime: null,
                },
                {
                    enabled: false,
                    startTime: null,
                    endTime: null,
                },
                {
                    enabled: false,
                    startTime: null,
                    endTime: null,
                }
            ],
            assignedWorkingHours: [
                {
                    enabled: false,
                    startTime: null,
                    endTime: null,
                },
                {
                    enabled: false,
                    startTime: null,
                    endTime: null,
                },
                {
                    enabled: false,
                    startTime: null,
                    endTime: null,
                },
                {
                    enabled: false,
                    startTime: null,
                    endTime: null,
                },
                {
                    enabled: false,
                    startTime: null,
                    endTime: null,
                },
                {
                    enabled: false,
                    startTime: null,
                    endTime: null,
                },
                {
                    enabled: false,
                    startTime: null,
                    endTime: null,
                }
            ],
            api: new formHelper(),
        }
    },
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        employmentTypeFormRef() {
            return this.$refs.employmentTypeForm
        },
        isDirty() {
            const equalPeriod = _.isEqual(
                this.prevPayrollPeriod.period,
                this.payrollPeriod.period
            )

            let isDirty = !_.isEqual(this.prevEmploymentType, this.employmentType) ||
                !_.isEqual(this.prevSelectedWorkingHours, this.selectedWorkingHours) ||
                !_.isEqual(this.prevOpenShifts, this.openShifts) || !equalPeriod

            if (!isDirty && equalPeriod) {
                isDirty = isDirty || !_.isEqual(
                    this.prevPayrollPeriod.startsOn,
                    this.payrollPeriod.startsOn,
                )
            }

            if (this.selectedWorkingHours === 'assigned') {
                return isDirty || !_.isEqual(this.prevAssignedWorkingHours, this.assignedWorkingHours)
            }
            return isDirty
        },
        isSaveDisabled() {
            return this.isSaving || this.isDeleting || !this.isDirty
        },
        isDeleteDisabled() {
            return this.isSaving || this.isDeleting
        },
        innerHeight() {
            return window.innerHeight
        },
        contentStyle() {
            return {
                height: `${this.innerHeight}px`
            }
        },
        maxHoursLimit() {
            const {period} = this.payrollPeriod
            if (period === 'weekly') {
                return 168
            } else if (period === 'bi_weekly') {
                return 336
            } else if (period === 'bi_monthly') {
                return 360
            } else if (period === 'monthly') {
                return 744
            } else {
                return 168
            }
        },
    },
    methods: {
        onClose() {
            if (this.isDirty) {
                this.showDiscardChangesModal()
                return
            }

            this.closeModal()
        },
        closeModal() {
            this.modalValue = false
            this.resetModal()
        },
        resetModal() {
            this.prevEmploymentType = {}
            this.employmentType = {}
            this.deleteMessage = ''
            if (this.employmentTypeFormRef && this.employmentTypeFormRef.resetValidation) {
                this.employmentTypeFormRef.resetValidation()
            }
            this.api.clearErrors()
        },
        showDiscardChangesModal() {
            this.$refs.unsavedChangesDialog.open()
                .then(() => {
                    this.closeModal()
                })
                .catch(() => {
                })
        },
        onClickDeleteEmploymentType() {
            this.deleteEmploymentTypeModalValue = true
            this.deleteMessage = `
                <p>
                    You are about to delete <b>${this.prevEmploymentType.name}</b> from your Organization. This will remove <b>${this.prevEmploymentType.name}</b> from all Users assigned that Employment Type.
                </p>
                <p>
                    These Users will be unavailable to schedule until a new Employment Type is selected for them.
                </p>
            `
        },
        onDeleteEmploymentType() {
            this.isDeleting = true
            this.api.delete(`/staff-type/${this.employmentTypeId}`).then(() => {
                this.$snackNotify('success', 'Employment Type deleted.')
                this.deleteEmploymentTypeModalValue = false
                this.closeModal()
                this.$emit('onDelete')
            }).catch(console.log).finally(() => this.isDeleting = false)
        },
        onChangeEnforceMaxHoursLimit(enforceMaxHoursLimit) {
            this.employmentType = {
                ...this.employmentType,
                overtimeHours: enforceMaxHoursLimit ? 0 : null,
            }
        },
        fetchDetails(employmentTypeId) {
            this.isFetchingDetails = true
            this.api.get(`/staff-type/details-for-edit/${employmentTypeId}`).then(result => {
                if (result && result.data) {
                    this.setEmploymentTypeFromBody(result.data)
                    this.setPayrollPeriodFromBody(result.data)
                    this.setWorkingHoursFromBody(result.data)
                    this.setOpenShiftsFromBody(result.data)
                    this.setAssignedWorkingHoursFromBody(result.data)
                }
            }).catch(console.log).finally(() => this.isFetchingDetails = false)
        },
        onSave() {
            if (!this.employmentTypeFormRef.validate()) {
                return false
            }

            this.isSaving = true
            this.api.post('/staff-type/save', {
                ...Boolean(this.employmentTypeId) && {_id: this.employmentTypeId},
                ...this.setEmploymentTypeToBody(),
                ...this.setPayrollPeriodToBody(),
                ...this.setWorkingHoursToBody(),
                ...this.setOpenShiftsToBody(),
                ...this.setAssignedWorkingHoursToBody(),
            }).then(() => {
                if (this.employmentTypeId) {
                    this.$snackNotify('success', 'Changes to Employment Type saved.')
                } else {
                    this.$snackNotify('success', 'New Employment Type created.')
                }
                this.closeModal()
                this.$emit('onSave')
            }).catch(console.log).finally(() => this.isSaving = false)
        },
        setEmploymentTypeToBody() {
            const {employmentType} = this
            return {
                name: employmentType.name || '',
                max_daily_hours: employmentType.maxDailyHours || 1,
                max_work_hours: Math.round(employmentType.maxWeeklyHours * 60),
                enforce_max_hours_limit: Boolean(employmentType.enforceMaxHoursLimit),
                ...employmentType.hasOwnProperty('overtimeHours') && {overtime_hours: employmentType.overtimeHours ? Math.round(employmentType.overtimeHours * 60) : 0},
                ...employmentType.hasOwnProperty('maxConsecutiveHours') && {max_consecutive_hours: typeof employmentType.maxConsecutiveHours === 'number' ? employmentType.maxConsecutiveHours : null},
                ...employmentType.hasOwnProperty('requiredBreak') && {required_break: typeof employmentType.requiredBreak === 'number' ? employmentType.requiredBreak : null},
            }
        },
        setEmploymentTypeFromBody(body) {
            const enforceMaxHoursLimit = Boolean(body?.enforce_max_hours_limit)
            const employmentType = {
                name: body?.name || '',
                maxDailyHours: body?.max_daily_hours || 0,
                maxWeeklyHours: parseFloat(Number(body?.max_work_hours / 60).toFixed(2)),
                enforceMaxHoursLimit,
                ...body?.hasOwnProperty('overtime_hours') && enforceMaxHoursLimit && {overtimeHours: parseFloat(Number(body.overtime_hours / 60).toFixed(2))},
                ...body?.hasOwnProperty('max_consecutive_hours') && {maxConsecutiveHours: body.max_consecutive_hours},
                ...body?.hasOwnProperty('required_break') && {requiredBreak: body.required_break},
            }
            this.prevEmploymentType = {...employmentType}
            this.employmentType = {...employmentType}
        },
        setPayrollPeriodToBody() {
            const {period, startsOn} = this.payrollPeriod
            return {
                payroll_period: period,
                ...((period === 'weekly' || period === 'bi_weekly') && {
                    payroll_period_week_start: startsOn
                })
            }
        },
        setPayrollPeriodFromBody(body) {
            const payrollPeriod = {
                period: body?.payroll_period || 'weekly',
                startsOn: body?.payroll_period_week_start || 0,
            }
            this.prevPayrollPeriod = {...payrollPeriod}
            this.payrollPeriod = {...payrollPeriod}
            this.payrollPeriodKey = this.payrollPeriodKey + 1
        },
        setWorkingHoursToBody() {
            let work_schedule_config = 'none'
            if (this.selectedWorkingHours === 'assigned') {
                work_schedule_config = 'staff_type_defined'
            } else if (this.selectedWorkingHours === 'submitted') {
                work_schedule_config = 'user_defined'
            }
            return {
                work_schedule_config
            }
        },
        setWorkingHoursFromBody(body) {
            if (body) {
                const {work_schedule_config} = body
                if (work_schedule_config === 'staff_type_defined') {
                    this.selectedWorkingHours = 'assigned'
                } else if (work_schedule_config === 'user_defined') {
                    this.selectedWorkingHours = 'submitted'
                }
            } else {
                this.selectedWorkingHours = 'none'
            }
            this.prevSelectedWorkingHours = this.selectedWorkingHours
        },
        setOpenShiftsToBody() {
            return {
                working_hours_direct_assign_enabled: this.openShifts.whDirectAssign,
                working_hours_self_assign_enabled: this.openShifts.whSelfAssign,
                out_of_work_hours_shift_offer_enabled: this.openShifts.owhOffer,
                out_of_work_hours_self_assign_enabled: this.openShifts.owhSelfAssign,
            }
        },
        setOpenShiftsFromBody(body) {
            this.openShifts = {
                whDirectAssign: body && body.hasOwnProperty('working_hours_direct_assign_enabled') ?
                    body.working_hours_direct_assign_enabled : false,
                whSelfAssign: body && body.hasOwnProperty('working_hours_self_assign_enabled') ?
                    body.working_hours_self_assign_enabled : false,
                owhOffer: body && body.hasOwnProperty('out_of_work_hours_shift_offer_enabled') ?
                    body.out_of_work_hours_shift_offer_enabled : false,
                owhSelfAssign: body && body.hasOwnProperty('out_of_work_hours_self_assign_enabled') ?
                    body.out_of_work_hours_self_assign_enabled : false,
            }
            this.prevOpenShifts = {...this.openShifts}
        },
        setAssignedWorkingHoursToBody() {
            const {assignedWorkingHours} = this
            if (Array.isArray(assignedWorkingHours)) {
                return {
                    staff_type_work_schedules: assignedWorkingHours
                        .map((wh, index) => ({
                            ...wh,
                            day_of_week: index,
                        }))
                        .filter(wh => wh.enabled)
                        .map(wh => ({
                            day_of_week: wh.day_of_week,
                            start_time: wh.startTime,
                            end_time: wh.endTime,
                        }))
                }
            }
            return {
                staff_type_work_schedules: []
            }
        },
        setAssignedWorkingHoursFromBody(body) {
            const workSchedules = body?.work_schedules
            if (Array.isArray(workSchedules) && workSchedules.length > 0) {
                const assignedWorkingHours = Array.from(this.assignedWorkingHours)
                workSchedules.forEach(({day_of_week, start_time, end_time}) => {
                    if (day_of_week >= 0 && day_of_week < assignedWorkingHours.length) {
                        assignedWorkingHours[day_of_week] = {
                            enabled: true,
                            startTime: start_time,
                            endTime: end_time,
                        }
                    }
                })
                this.assignedWorkingHours = [...assignedWorkingHours]
                this.prevAssignedWorkingHours = [...assignedWorkingHours]
            }
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(open) {
                if (open && this.employmentTypeId) {
                    this.fetchDetails(this.employmentTypeId)
                }
            }
        },
        payrollPeriod() {
            this.maxHoursKey = this.maxHoursKey + 1
            this.overtimeHoursKey = this.overtimeHoursKey + 1
            this.maxDailyHoursKey = this.maxDailyHoursKey + 1
            this.maxConsecutiveHoursKey = this.maxConsecutiveHoursKey + 1
            this.requiredBreakKey = this.requiredBreakKey + 1
        }
    }
}
</script>

<style scoped>

</style>
