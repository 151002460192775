import {NOTIFICATION_TYPES} from "../helpers/getNotificationDetails";

const notificationUrlMixin = {
    methods: {
        buildUrlForNotification(notification) {
            switch (notification.data.type) {
                case NOTIFICATION_TYPES.ASSIGN_SHIFT:
                case NOTIFICATION_TYPES.SHIFT_UPDATED:
                case NOTIFICATION_TYPES.CANCEL_SHIFT_DECLINED:
                    if (notification.shift) {
                        return `/shifts/${notification.shift._id}`;
                    }
                    return '/shifts';

                case NOTIFICATION_TYPES.OFFER_SHIFT:
                case NOTIFICATION_TYPES.SHIFT_OFFER_UPDATED:
                    if (notification.shift_offer) {
                        return `/shifts/${notification.shift_offer._id}`;
                    }
                    return '/shifts';

                case NOTIFICATION_TYPES.ACCEPT_SHIFT:
                case NOTIFICATION_TYPES.CANCEL_SHIFT_REQUESTED:
                case NOTIFICATION_TYPES.SHIFT_FILLED:
                    if (notification.shift) {
                        return `/schedules/?type=shift&shift_id=${notification.shift._id}`;
                    }
                    return '/schedules';

                case NOTIFICATION_TYPES.CANCEL_SHIFT_APPROVED:
                    if (notification.shift) {
                        return `/schedules/?type=shift_request&shift_id=${notification.shift.shift_request_id}`;
                    }
                    return '/schedules';

                case NOTIFICATION_TYPES.AVAILABILITY_REMINDER:
                    return notification.data.url;

                case NOTIFICATION_TYPES.OWN_STAFFING_AUTOMATION_RUN_COMPLETED:
                case NOTIFICATION_TYPES.MANAGED_STAFFING_AUTOMATION_RUN_COMPLETED:
                    if (notification.first_shift_request_id) {
                        return `/schedules/?type=shift&shift_id=${notification.first_shift_request_id}`;
                    }

                    return '/schedules';

                case NOTIFICATION_TYPES.SHARED_REPORT:
                    return `/reports/${notification.data.report_id}`

                case NOTIFICATION_TYPES.AUTO_ASSIGN_RESULTS:
                    return `/schedules?auto_schedule_draft_id=${notification.data.auto_schedule_draft_id}`

                case NOTIFICATION_TYPES.TIME_OFF_REQUESTED:
                case NOTIFICATION_TYPES.TIME_OFF_REQUEST_RESPONSE:
                    return notification?.data?.time_off_request_status === 'cancelled' ?
                        `/notifications?notification_id=${notification._id}` :
                        `/users/${notification.time_off_request?.user_id}?preselected_time_off_request_id=${notification.time_off_request?._id}`

                default:
                    if (this.$authIsStaff) {
                        return `/shifts`;
                    } else {
                        return `/schedules`;
                    }
            }
        }
    }
}

export default notificationUrlMixin
