export const formatMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60)
    const remainingMinutes = minutes % 60
    if (remainingMinutes > 0) {
        if (hours > 0) {
            return `${hours}h ${remainingMinutes}m`
        }
        return `${remainingMinutes}m`
    }
    return `${hours}h`
}
