<template>
    <div class="d-flex flex-column" style="height: 72px;">
        <div class="d-flex flex-row justify-center align-center" style="column-gap: 10px;">
            <div class="text--h2" style="color: #000D60 !important;">
                {{ startDate }}
            </div>
            <div style="font-size: 2.25rem; margin-bottom: 0.2rem; color: #000D60;">
                -
            </div>
            <div class="text--h2" style="color: #000D60 !important;">
                {{ endDate }}
            </div>
        </div>
        <div class="d-flex flex-row justify-space-between align-center" style="column-gap: 10px;">
            <v-btn
                icon
                :disabled="!hasPrevVersion"
                @click="onGoToPrevVersion"
            >
                <v-icon style="color: #000000;">mdi-chevron-left</v-icon>
            </v-btn>
            <div class="d-flex flex-row align-center">
                <div class="text--h3">
                    Version {{ versionIndex + 1 }} of {{ versionCount }}
                </div>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            :disabled="syncDisabled"
                            class="ml-1"
                            @click="onSyncSchedule"
                        >
                            <v-icon color="primary">mdi-sync</v-icon>
                        </v-btn>
                    </template>
                    <span>Clear changes to version</span>
                </v-tooltip>
            </div>
            <v-btn
                icon
                :disabled="!hasNextVersion"
                @click="onGoToNextVersion"
            >
                <v-icon style="color: #000000;">mdi-chevron-right</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    name: "VersionHeader",
    props: {
        start: {
            type: String,
            default: null
        },
        end: {
            type: String,
            default: null
        },
        timezone: {
            type: String,
            default: null
        },
        versionIndex: {
            type: Number,
            default: 0
        },
        versionCount: {
            type: Number,
            default: 0
        },
        syncDisabled: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        startDate() {
            return this.parseDate(this.start)
        },
        endDate() {
            return this.parseDate(this.end)
        },
        hasPrevVersion() {
            return this.versionIndex > 0
        },
        hasNextVersion() {
            return this.versionIndex < this.versionCount - 1
        }
    },
    methods: {
        onGoToPrevVersion() {
            this.$emit('onGoToPrevVersion')
        },
        onGoToNextVersion() {
            this.$emit('onGoToNextVersion')
        },
        onSyncSchedule() {
            this.$emit('onSyncSchedule')
        },
        parseDate(date) {
            return moment(date).tz(this.timezone).format('MM/DD/YYYY')
        }
    }
}
</script>

<style scoped>

</style>
