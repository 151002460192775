<template>
    <v-row class="pa-3">
        <v-col cols="12" class="d-flex flex-row justify-space-between align-center">
            <span>
                Define key work parameters to help standardize employment policies and ensure compliance with labor regulations. Select a row to edit its settings.
            </span>
            <v-btn
                color="secondary"
                :disabled="!hasEditStaffTypesPermission"
                @click="onAddEmploymentType"
            >
                Add Employment Type
            </v-btn>
        </v-col>
        <v-col cols="12">
            <v-data-table
                id="employment-types-table"
                :height="tableHeight"
                :headers="headers"
                :items="employmentTypes"
                :loading="employmentTypesLoading"
                disable-pagination
                fixed-header
                hide-default-footer
                @click:row="onEditEmploymentType"
            >
                <template v-slot:item.payrollPeriod="{ item }">
                    {{ getPayrollPeriod(item.payrollPeriod) }}
                </template>

                <template v-slot:item.maxWeeklyHours="{ item }">
                    {{ item.maxWeeklyHours | timeStringFromMinutes }}
                </template>

                <template v-slot:item.overtimeHours="{ item }">
                    {{ item.overtimeHours | timeStringFromMinutes }}
                </template>

                <template v-slot:item.maxDailyHours="{ item }">
                    {{ item.maxDailyHours | timeStringFromMinutes }}
                </template>

                <template v-slot:item.maxConsecutiveHours="{ item }">
                    {{ item.maxConsecutiveHours | timeStringFromMinutes }}
                </template>

                <template v-slot:item.requiredBreak="{ item }">
                    {{ item.requiredBreak | timeStringFromMinutes }}
                </template>

                <template v-slot:item.workingHours="{ item }">
                    {{ getWorkingHours(item.workingHours) }}
                </template>
            </v-data-table>
        </v-col>
        <save-employment-type-modal
            v-if="employmentTypesModalValue"
            v-model="employmentTypesModalValue"
            :employment-type-id="selectedEmploymentTypeId"
            @onSave="onSaveEmploymentType"
            @onDelete="onDeleteEmploymentType"
        />
    </v-row>
</template>

<script>
import SaveEmploymentTypeModal from "./SaveEmploymentTypeModal";

export default {
    name: "EmploymentTypesTab",
    components: {SaveEmploymentTypeModal},
    props: {
        tab: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            headers: [
                {text: 'Name', value: 'name'},
                {text: 'Payroll Period', value: 'payrollPeriod'},
                {text: 'Max Hours', value: 'maxWeeklyHours'},
                {text: 'Overtime Hours', value: 'overtimeHours'},
                {text: 'Max Hours/Day', value: 'maxDailyHours'},
                {text: 'Max Cons. Hours', value: 'maxConsecutiveHours'},
                {text: 'Required Break', value: 'requiredBreak'},
                {text: 'Working Hours', value: 'workingHours'},
            ],
            employmentTypes: [],
            employmentTypesModalValue: false,
            employmentTypesLoading: false,
            selectedEmploymentTypeId: null,
            api: new formHelper(),
        }
    },
    computed: {
        tableHeight() {
            return window.innerHeight - 320
        },
        hasViewSettingsPermission() {
            return this.$userHasPermission(this.$user, this.$config.permissions.SETTINGS.VIEW)
        },
        hasEditStaffTypesPermission() {
            return this.$userHasPermission(this.$user, this.$config.permissions.STAFF_TYPES.EDIT)
        }
    },
    methods: {
        onAddEmploymentType() {
            if (!this.hasEditStaffTypesPermission) {
                return
            }

            this.employmentTypesModalValue = true
            this.selectedEmploymentTypeId = null
        },
        onEditEmploymentType(employmentType) {
            if (!this.hasEditStaffTypesPermission) {
                return
            }

            this.employmentTypesModalValue = true
            if (employmentType && employmentType._id) {
                this.selectedEmploymentTypeId = employmentType._id
            }
        },
        fetchEmploymentTypes() {
            if (!this.hasViewSettingsPermission) {
                return
            }

            this.employmentTypesLoading = true
            this.employmentTypes = []

            this.api.get('/staff-type/list-for-organization').then(result => {
                if (result && Array.isArray(result.data)) {
                    this.employmentTypes = result.data.map(employmentType => ({
                        _id: employmentType._id,
                        name: employmentType.name,
                        payrollPeriod: employmentType.payroll_period,
                        maxWeeklyHours: employmentType.max_work_hours,
                        overtimeHours: employmentType.overtime_hours,
                        maxDailyHours: employmentType.max_daily_hours,
                        maxConsecutiveHours: employmentType.max_consecutive_hours,
                        requiredBreak: employmentType.required_break,
                        workingHours: employmentType.work_schedule_config,
                    }))
                }
            }).catch(console.log).finally(() => this.employmentTypesLoading = false)
        },
        onSaveEmploymentType() {
            this.fetchEmploymentTypes()
        },
        onDeleteEmploymentType() {
            this.fetchEmploymentTypes()
        },
        getPayrollPeriod(payrollPeriod) {
            if (payrollPeriod) {
                const parts = payrollPeriod.split('_')
                return parts.map(this.$options.filters.capitalize).join('-')
            }
            return 'N/A'
        },
        getWorkingHours(workingHours) {
            if (workingHours) {
                if (workingHours === 'staff_type_defined') {
                    return 'Assigned'
                } else if (workingHours === 'user_defined') {
                    return 'Submitted'
                } else {
                    return 'None'
                }
            }
            return 'N/A'
        }
    },
    watch: {
        tab(tab) {
            if (tab === 0) {
                this.fetchEmploymentTypes()
            }
        }
    },
    mounted() {
        this.fetchEmploymentTypes()
    }
}
</script>

<style scoped>
#employment-types-table :deep(tr:hover) {
    cursor: pointer;
}
</style>
