<template>
    <div>
        <v-app-bar color="white" elevation="3" app clipped-left class="pt-1 pt-md-0" style="z-index: 22; height: 64px !important;">
            <v-toolbar-title class="pl-0">
                <v-img
                    v-if="!$vuetify.breakpoint.smAndDown"
                    alt="Koroid Logo"
                    src="/images/logo.png"
                    contain
                    max-width="230px"
                    @click.stop="showSidebar = !showSidebar"
                />

                <v-img
                    v-if="$vuetify.breakpoint.smAndDown"
                    alt="Koroid Logo"
                    src="/images/logo_no_text.png"
                    contain
                    max-width="42"
                    max-height="42"
                    class="ml-1"
                    @click.stop="showSidebar = !showSidebar"
                />
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-toolbar-items v-if="impersonating">
                <v-row no-gutters align="center" justify="center">
                    <v-col>
                        <v-btn
                            icon
                            elevation="2"
                            style="background-color: #C8E6FF;"
                            href="/impersonate/revert"
                        >
                            <v-icon>mdi-account-settings</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row no-gutters align="center" justify="center" class="ml-3 hidden-sm-and-down text--black">
                    <v-col>
                        <div class="text-center text--label">Stop Impersonating</div>
                    </v-col>
                </v-row>
            </v-toolbar-items>

<!--            <v-toolbar-items class="px-4">-->
<!--                <v-row no-gutters align="center" justify="center">-->
<!--                    <v-col>-->
<!--                        <v-tooltip bottom>-->
<!--                            <template v-slot:activator="{ on, attrs }">-->
<!--                                <v-badge-->
<!--                                    offset-x="24"-->
<!--                                    offset-y="24"-->
<!--                                    color="warning"-->
<!--                                    :value="is_selected_tz_different_from_location_tz"-->
<!--                                >-->
<!--                                    <template v-slot:badge>-->
<!--                                        <v-icon-->
<!--                                            color="primary_text"-->
<!--                                            class="timezone-badge-icon"-->
<!--                                            v-bind="attrs"-->
<!--                                            v-on="on"-->
<!--                                        >-->
<!--                                            mdi-exclamation-thick-->
<!--                                        </v-icon>-->
<!--                                    </template>-->
<!--                                    <v-btn-->
<!--                                        color="primary_text"-->
<!--                                        icon-->
<!--                                        elevation="2"-->
<!--                                        @click="showTimezonePickerModal = true"-->
<!--                                    >-->
<!--                                        <v-icon>mdi-earth</v-icon>-->
<!--                                    </v-btn>-->
<!--                                </v-badge>-->
<!--                            </template>-->
<!--                            <span>The selected timezone does not match the current location</span>-->
<!--                        </v-tooltip>-->

<!--                    </v-col>-->
<!--                </v-row>-->

<!--                <v-row no-gutters align="center" justify="center" class="ml-3 hidden-sm-and-down text&#45;&#45;black">-->
<!--                    <v-col>-->
<!--                        <div class="text-center text&#45;&#45;caption">Time Displayed In:</div>-->
<!--                        <div class="text-center text&#45;&#45;label">{{ formattedTimezone }}</div>-->
<!--                    </v-col>-->
<!--                </v-row>-->
<!--            </v-toolbar-items>-->

            <v-toolbar-items class="px-4">
                <v-row no-gutters align="center" justify="center">
                    <v-col>
                        <v-btn name="timezone" text icon elevation="2" color="primary_text" class="mx-1" @click="showTimezonePickerModal = true">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <div >
                                        <v-badge  :value="is_selected_tz_different_from_location_tz" color="warning" offset-x="12" offset-y="12">
                                            <span v-on="on" v-bind="attrs" slot="badge" style="color: #1B1B1F;">!</span>
                                            <v-icon>mdi-earth</v-icon>
                                        </v-badge>
                                    </div>
                                </template>
                                <span>The selected timezone does not match the current location</span>
                            </v-tooltip>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row no-gutters align="center" justify="center" class="ml-3 hidden-sm-and-down text--black">
                    <v-col>
                        <div class="text-center text--caption">Time Displayed In:</div>
                        <div class="text-center text--label">{{ formattedTimezone }}</div>
                    </v-col>
                </v-row>
            </v-toolbar-items>

            <v-toolbar-items class="px-4">

                <v-layout align-center>
                    <v-btn
                        color="primary_text"
                        icon
                        elevation="2"
                        @click="showLocationPickerModal = true"
                    >
                        <v-icon>mdi-city</v-icon>
                    </v-btn>
                </v-layout>

                <v-layout align-center class="ml-3 hidden-sm-and-down text--black">
                    <v-flex>
                        <div class="text-center text--caption">Current Location:</div>
                        <div data-cy="current-location" class="text-center text--label">{{ selectedLocationName }}</div>
                    </v-flex>
                </v-layout>

            </v-toolbar-items>

            <v-toolbar-items class="notifications-toolbar-container">
                <!-- Note: 2 is added to z-index, don't know where this originates from. Shows 20 in inspector! -->
                <v-menu open-on-click left :nudge-bottom="64" @input="onMenuInput" style="z-index: 18;" attach=".notifications-toolbar-container" content-class="menu-border-radius">
                    <template v-slot:activator="{ on }">
                        <v-btn name="notifications" text icon elevation="2" color="primary_text" v-on="on" class="mx-1" :loading="loadingLatestNotifications">
                            <v-badge :value="$root.$unreadNotificationsCount" color="warning" offset-x="12" offset-y="12">
                                <template v-slot:badge>
                                    <span style="color: #1B1B1F;">{{ $root.unreadNotificationsCount }}</span>
                                </template>
                                <v-icon v-if="notifications_page_active || menu_is_open">
                                    mdi-bell
                                </v-icon>
                                <v-icon v-else>
                                    mdi-bell-outline
                                </v-icon>
                            </v-badge>
                        </v-btn>
                    </template>

                    <v-card min-width="350" max-width="700" class="mx-auto">
                        <v-toolbar height="60" style="background-color: #C8E6FF;">
                            <v-toolbar-title>Notifications</v-toolbar-title>

                            <v-spacer></v-spacer>

                            <v-btn color="primary" outlined small href="/notifications" style="text-transform: none !important;;">View All</v-btn>
                        </v-toolbar>
                       <NotificationsList
                           :notifications="parsedNotifications"
                           :numLines="3"
                           @onNotificationClick="markNotificationAsRead"
                       >
                       </NotificationsList>
                    </v-card>
                </v-menu>
            </v-toolbar-items>

            <v-toolbar-items>
                <v-layout align-center class="ml-3 mr-3">
                    <v-btn data-cy="logout" icon title="Logout" elevation="2" color="primary_text" onclick="event.preventDefault(); document.getElementById('logout-form').submit();">
                        <v-icon>mdi-logout</v-icon>
                    </v-btn>
                </v-layout>
            </v-toolbar-items>
        </v-app-bar>

        <location-picker-modal :open.sync="showLocationPickerModal"></location-picker-modal>
        <timezone-picker-modal v-model="showTimezonePickerModal"/>
    </div>
</template>

<script>
    import LocationPickerModal from '../location/LocationPickerModal'
    import NotificationsList from '../../pages/Notifications/NotificationsList'
    import notificationUrlMixin from '../../lib/mixins/notificationUrl'
    import { NOTIFICATION_TYPES } from '../../lib/helpers/getNotificationDetails'
    import TimezonePickerModal from "../timezone/TimezonePickerModal";

    export default {
        name: "AppBar",
        components: {
            TimezonePickerModal,
            LocationPickerModal,
            NotificationsList
        },
        mixins: [notificationUrlMixin],
        props: {
            impersonating: {
                default: false,
                type: Boolean
            },
            value: {
                type: Boolean,
                default: false
            }
        },
        data() {
            const PATH = window.location.pathname.split('/')[1]

            return {
                showLocationPickerModal: false,
                showTimezonePickerModal: false,
                showSidebar: this.value,
                api: new formHelper(),
                notifications: this.$notifications,
                loadingLatestNotifications: false,
                notifications_page_active: PATH === 'notifications',
                menu_is_open: false,
            }
        },
        computed: {
            globalLocation() {
                return this.$root.globalLocation;
            },

            globalTimezone() {
                return this.$root.globalTimezone
            },

            formattedTimezone() {
                if (!this.$root.globalTimezone) {
                    return 'N/A'
                }

                return moment.tz(this.$root.globalTimezone).format('z (Z)')
            },

            is_selected_tz_different_from_location_tz() {
                if (!this.globalTimezone || !this.globalLocation || typeof this.globalLocation.timezone === 'undefined') {
                    return false
                }

                return this.globalTimezone !== this.globalLocation.timezone
            },

            selectedLocationName() {
                if (this.globalLocation) {
                    return this.globalLocation.name
                }

                if (this.$authIsOwner) {
                    return 'All Locations'
                }

                return 'No Location Selected'
            },

            parsedNotifications() {
                return this.notifications.map(notification => {
                    const disabled = this.getDisabledStatus(notification)
                    return {
                        ...notification,
                        disabled,
                        url: this.buildNotificationUrl(notification, disabled)
                    }
                })
            },

            globalNotifications() {
                return this.$root.notifications
            },

            globalLoadingLatestNotifications() {
                return this.$root.loadingLatestNotifications
            },
        },
        methods: {
            onMenuInput(menu_is_open) {
                this.menu_is_open = menu_is_open
            },
            markNotificationAsRead: function(notificationId, notificationUrl, event) {
                const notification = this.notifications.find(n => n._id === notificationId)
                if(notification) {
                    if(!notification.read_at) {
                        if(event && event.stopPropagation) {
                            event.stopPropagation()
                        }
                        this.notifications = this.notifications.map(n => {
                            if(n._id === notificationId) {
                                return {
                                    ...n,
                                    loading: true,
                                }
                            }
                            return n
                        })
                        this.api.post('/notifications/mark-as-read/' + notificationId)
                            .then(() => {
                                this.notifications = this.notifications.map(n => {
                                    if(n._id === notificationId) {
                                        return {
                                            ...n,
                                            read_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                                            loading: false,
                                        }
                                    }
                                    return n
                                })
                                this.$root.unreadNotificationsCount = this.$root.unreadNotificationsCount - 1
                                if(notificationUrl) {
                                    window.location.href = notificationUrl
                                }
                            })
                            .catch(() => {
                                this.notifications = this.notifications.map(n => {
                                    if(n._id === notificationId) {
                                        return {
                                            ...n,
                                            loading: false,
                                        }
                                    }
                                    return n
                                })
                                if(notificationUrl) {
                                    window.location.href = notificationUrl
                                }
                            })
                    } else {
                        if(notificationUrl) {
                            window.location.href = notificationUrl
                        }
                    }
                } else {
                    if(notificationUrl) {
                        window.location.href = notificationUrl
                    }
                }
            },

            getDisabledStatus(notification) {
                if(
                    notification.data.type === NOTIFICATION_TYPES.DECLINE_SHIFT ||
                    notification.data.type === NOTIFICATION_TYPES.CANCEL_SHIFT_ACCEPTED ||
                    notification.data.type === NOTIFICATION_TYPES.CALL_OUT ||
                    notification.data.type === NOTIFICATION_TYPES.NO_SHOW ||
                    notification.data.type === NOTIFICATION_TYPES.AUTO_SCHEDULE_DRAFT_PUBLISHED
                ) {
                    return true
                } else if (notification.data.type === NOTIFICATION_TYPES.CANCEL_SHIFT_REQUESTED) {
                    if (notification.valid) {
                        return this.getIsShiftCallOut(notification) || this.getIsShiftNoShow(notification)
                    }
                    return true
                } else if (notification.data.type === NOTIFICATION_TYPES.OFFER_SHIFT) {
                    return !notification.valid
                } else if (notification.data.type === NOTIFICATION_TYPES.CANCEL_SHIFT_APPROVED) {
                    return !notification.valid
                } else {
                    if (notification.valid) {
                        if (notification.shift) {
                            return this.getIsShiftCallOut(notification) || this.getIsShiftNoShow(notification)
                        }
                        return false
                    }
                    return true
                }
            },

            getIsShiftCallOut(notification) {
                return this.getIsShiftCallOutOrNoShow(notification, 'call_outs')
            },

            getIsShiftNoShow(notification) {
                return this.getIsShiftCallOutOrNoShow(notification, 'no_shows')
            },

            getIsShiftCallOutOrNoShow(notification, field) {
                if (notification && notification.shift && Array.isArray(notification.shift[field])) {
                    return notification.shift[field].some(co => co.employee_id === this.$user._id)
                }
                return false
            },

            buildNotificationUrl(notification, disabled) {
                if(disabled) {
                    return `/notifications?notification_id=${notification._id}`
                }
                return this.buildUrlForNotification(notification)
            },

            loadLocationDetails(location_id) {
                this.api.get(`/locations/${location_id}`)
                    .then(({data, message}) => {
                        this.$root.globalLocation = data
                    })
            }
        },
        mounted() {
            if (this.globalLocation && typeof this.globalLocation.timezone === 'undefined') {
                this.loadLocationDetails(this.globalLocation._id)
            }
        },
        watch: {
            value() {
                this.showSidebar = this.value
            },
            showSidebar(newVal) {
                this.$emit('input', newVal)
            },
            globalNotifications(notifications) {
                this.notifications = notifications
            },
            globalLoadingLatestNotifications(isLoading) {
                this.loadingLatestNotifications = isLoading
            }
        }
    }
</script>

<style scoped>
    .toolbar-location {
        cursor: pointer;
    }

    .impersonate-button {
        color: #1B1464 !important;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 20px;
        padding: 0 2.25em 0 2.25em !important;
    }

    .impersonate-button:hover {
        color: #D13434 !important;
        border-bottom: 3px solid #D13434 !important;
    }

    .impersonate-button:hover:before {
        opacity: 0 !important;
    }

    .navbar-custom-button:hover {
        color: #485FF8 !important;
        border-bottom: 3px solid #485FF8 !important;
    }

    .timezone-badge-icon {
        padding-left: 1px;
    }

    .notifications-toolbar-container {
        align-items: center;
    }

    .notifications-toolbar-container .v-menu__content {
        top: 70px !important;
    }

    @media only screen and (max-width: 600px) {
        .notifications-toolbar-container .v-menu__content {
            top: 60px !important;
            left: 0 !important;
            max-width: 100% !important;
        }
    }

    .menu-border-radius {
        border-radius: 12px !important;
    }
</style>
