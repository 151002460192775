<template>
    <custom-icon icon="mdi-calendar-question" color="rgb(128, 159, 255)"></custom-icon>
</template>

<script>
import CustomIcon from "./CustomIcon";
export default {
    name: "BlueCalendarQuestionIcon",
    components: { CustomIcon }
}
</script>

<style scoped>

</style>
