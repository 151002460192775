<template>
    <card-display>
        <div class="d-flex flex-column" style="row-gap: 10px;">
            <div class="d-flex flex-row align-center" style="column-gap: 15px;">
                <div class="text--h4">
                    Open Shifts
                </div>
                <div class="d-flex flex-row align-center" style="column-gap: 10px;">
                    <shifts-count :count="shiftsCount"/>
                    <shifts-duration :duration="shiftsDuration"/>
                </div>
            </div>
            <div class="d-flex flex-column" style="row-gap: 10px;">
                <shift-time-counts
                    v-if="shiftTimeCounts.length > 0"
                    :shift-time-counts="shiftTimeCounts"
                />
                <div class="d-flex flex-column" style="row-gap: 8px;">
                    <open-shift-card
                        v-for="(shift, index) in openShifts"
                        :key="index"
                        :start="shift.start"
                        :end="shift.end"
                        :shifts-count="shift.shift_count"
                        :timezone="timezone"/>
                </div>
            </div>
        </div>
    </card-display>
</template>

<script>
import CardDisplay from "../CardDisplay";
import ShiftsCount from "../Stat/ShiftsCount";
import ShiftsDuration from "../Stat/ShiftsDuration";
import ShiftTimeCounts from "../ShiftTimeCounts";
import OpenShiftCard from "./OpenShiftCard";

export default {
    name: "OpenShiftsExpandedCard",
    components: {CardDisplay, ShiftsCount, ShiftsDuration, ShiftTimeCounts, OpenShiftCard},
    props: {
        openShifts: {
            type: Array,
            default: () => ([])
        },
        shiftTimeCounts: {
            type: Array,
            default: () => ([])
        },
        timezone: {
            type: String,
            default: null
        },
        shiftsCount: {
            type: Number,
            default: 0
        },
        shiftsDuration: {
            type: Number,
            default: 0
        }
    }
}
</script>

<style scoped>

</style>
