<template>
    <card-display
        :background-color="backgroundColor"
        clickable
        @onClick="onClick"
    >
        <user-details
            :first-name="firstName"
            :last-name="lastName"
            :avatar="avatar"
            :shifts-count="shiftsCount"
            :shifts-duration="shiftsDuration"
        />
    </card-display>
</template>

<script>
import CardDisplay from "./CardDisplay";
import UserDetails from "./UserDetails";

export default {
    name: "UserCard",
    components: {CardDisplay, UserDetails},
    props: {
        firstName: {
            type: String,
            default: null
        },
        lastName: {
            type: String,
            default: null
        },
        avatar: {
            type: String,
            default: null
        },
        shiftsCount: {
            type: Number,
            default: 0
        },
        shiftsDuration: {
            type: Number,
            default: 0
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        backgroundColor() {
            return this.selected ? 'rgba(200, 230, 255)' : null
        }
    },
    methods: {
        onClick() {
            this.$emit('onClick')
        }
    }
}
</script>

<style scoped>

</style>
