<template>
    <v-card class="elevation-0">
        <v-toolbar class="ds-toolbar" elevation="0">
            <v-icon color="primary" class="mr-3">mdi-city</v-icon>
            <v-toolbar-title>{{ location_name }}</v-toolbar-title>
            <v-btn
                class="ds-toolbar-fab-button"
                fab
                small
                :disabled="!hasEditLocationsPermission || location_details_loading"
                elevation="1"
                @click="onEditLocation"
            >
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                elevation="0"
                outlined
                @click="onBackToOrganization"
            >
                Back to Organization
            </v-btn>
        </v-toolbar>

        <v-container fluid class="px-6">

            <loading-skeleton v-if="location_details_loading"/>

            <v-row v-else class="mt-4">
                <v-col cols="12" md="6">
                    <chips-list-card
                        :items="departments"
                        label="Departments"
                        icon="mdi-domain"
                        :disabled="!hasViewDepartmentsPermission"
                        @item:click="onDepartmentChipClick"
                        :add-button-disabled="!hasEditDepartmentsPermission"
                        @addButton:click="onAddDepartmentClick"
                    />
                </v-col>

                <v-col cols="12" md="6">
                    <chips-list-card
                        :items="staff_groups"
                        label="Staff Groups"
                        icon="mdi-account-group"
                        :disabled="!hasViewStaffGroupPermission"
                        @item:click="onStaffGroupChipClick"
                        :add-button-disabled="!hasEditStaffGroupPermission"
                        @addButton:click="onAddStaffGroupClick"
                    />
                </v-col>
            </v-row>
        </v-container>
        <location-modal ref="editLocationModal"/>
        <staff-group-modal ref="addStaffGroupModal"/>
        <department-modal ref="addDepartmentModal"/>
    </v-card>
</template>

<script>
import LocationModal from "./LocationModal";
import ChipsListCard from "./components/ChipsListCard";
import LoadingSkeleton from "./components/LoadingSkeleton";
import StaffGroupModal from "../StaffGroup/StaffGroupModal";
import DepartmentModal from "../Department/DepartmentModal";

export default {
    name: "LocationWindow",
    components: {DepartmentModal, StaffGroupModal, LoadingSkeleton, ChipsListCard, LocationModal},
    props: {
        selectedLocation: {
            type: Object,
            default: () => {},
        },
        isNewLocation: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            api: new formHelper,
            location_details_loading: false,
            location: {},
            staff_groups: [],
            departments: [],
            location_updated: false,
            PERMISSIONS: this.$config.permissions,
        }
    },
    methods: {
        setLocationUpdated(value) {
            this.location_updated = value
        },

        resetWindowData() {
            this.location = {}
            this.staff_groups = []
            this.departments = []
            this.location_updated = false
        },

        onBackToOrganization() {
            this.$emit('back:organization', {location_updated: this.location_updated})
            this.resetWindowData()
        },

        onEditLocation() {
            if (!this.hasEditLocationsPermission) {
                return
            }

            this.$refs.editLocationModal.open(this.selectedLocation._id)
                .then(({action}) => {
                    if (action === 'updated') {
                        this.location_updated = true
                        this.loadLocationDetails()
                    }
                })
                .catch(() => {})
        },

        loadLocationDetails() {
            this.location_details_loading = true
            this.api.get('/location-details/' + this.selectedLocation._id)
                .then(({data}) => {
                    this.location = data.location
                    this.departments = data.departments
                    this.staff_groups = data.staff_groups

                    if (this.$root.globalLocation && this.location) {
                        if (this.$root.globalLocation._id === this.location._id) {
                            this.$root.globalLocation = this.location
                        }
                    }
                })
                .finally(() => {
                    this.location_details_loading = false
                })
        },

        onDepartmentChipClick(department) {
            if (!this.hasViewDepartmentsPermission) {
                return
            }

            this.emitDepartmentViewEvent(department)
        },

        onStaffGroupChipClick(staff_group) {
            if (!this.hasViewStaffGroupPermission) {
                return
            }

            this.emitStaffGroupViewEvent(staff_group)
        },

        onAddDepartmentClick() {
            if (!this.hasEditDepartmentsPermission) {
                return
            }

            this.$refs.addDepartmentModal.open({location_id: this.location._id})
                .then(({action, department}) => {
                    this.departmentCreated(department)
                })
                .catch(() => {})

        },

        departmentCreated(department) {
            this.loadLocationDetails()
            this.location_updated = true
            this.emitDepartmentViewEvent(department, true)
        },

        onAddStaffGroupClick() {
            if (!this.hasEditStaffGroupPermission) {
                return
            }

            this.$refs.addStaffGroupModal.open({location_id: this.location._id})
                .then(({action, staff_group}) => {
                    this.staffGroupCreated(staff_group)
                })
                .catch(() => {})
        },

        staffGroupCreated(staff_group) {
            this.loadLocationDetails()
            this.location_updated = true
            this.emitStaffGroupViewEvent(staff_group, true)
        },

        emitStaffGroupViewEvent(staff_group, staff_group_created = false) {
            this.$emit('view:staff_group', {staff_group: staff_group, staff_group_created: staff_group_created})
        },

        emitDepartmentViewEvent(department, department_created = false) {
            this.$emit('view:department', {department: department, department_created: department_created})
        }
    },
    computed: {
        location_name() {
            if (!this.selectedLocation) {
                return 'Location'
            }

            if (typeof this.location.name === 'undefined' || !this.location.name) {
                return this.selectedLocation.name
            }

            return this.location.name
        },
        hasEditLocationsPermission () {
            return this.$userHasPermission(this.$user, this.PERMISSIONS.LOCATIONS.EDIT)
        },
        hasViewDepartmentsPermission () {
            return this.$userHasPermission(this.$user, this.PERMISSIONS.DEPARTMENTS.VIEW)
        },
        hasEditDepartmentsPermission () {
            return this.$userHasPermission(this.$user, this.PERMISSIONS.DEPARTMENTS.EDIT)
        },
        hasViewStaffGroupPermission () {
            return this.$userHasPermission(this.$user, this.PERMISSIONS.STAFF_GROUPS.VIEW)
        },
        hasEditStaffGroupPermission () {
            return this.$userHasPermission(this.$user, this.PERMISSIONS.STAFF_GROUPS.EDIT)
        }
    },
    watch: {
        selectedLocation: {
            immediate: true,
            handler(location) {
                if (location && typeof location._id !== 'undefined') {
                    this.loadLocationDetails()
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
