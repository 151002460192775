<template>
    <div class="d-flex flex-row align-center" style="column-gap: 5px;">
        <v-icon style="color: #000000;" dense>
            {{ icon }}
        </v-icon>
        <div class="text--paragraph" style="white-space: nowrap;">
            {{ value }}
        </div>
    </div>
</template>

<script>
export default {
    name: "Stat",
    props: {
        icon: {
            type: String,
            default: null
        },
        value: {
            type: [String, Number],
            default: 0
        }
    }
}
</script>

<style scoped>

</style>
