<template>
    <v-col class="d-flex flex-column pt-0">
        <v-row class="user-lists-header-layout-container" v-if="!isInPast">
            <v-col cols="auto" style="width: 170px;">
                <list-view-select
                    v-model="selectedView"
                    :items="viewItems"
                    :disabled="listsLoading"
                    item-value="id"
                    item-text="name"
                    @onChange="onChangeSelectedView"
                ></list-view-select>
            </v-col>
            <v-col>
                <v-text-field
                    v-model="searchBy"
                    :disabled="listsLoading"
                    prepend-inner-icon="mdi-account-search"
                    clearable
                    outlined
                    placeholder="Search Staff"
                    style="padding-top: 0;"
                ></v-text-field>
            </v-col>
            <v-col cols="auto" class="d-flex flex-row justify-end align-center">
                <v-dialog v-if="shouldShowSchedulingOptionsModal" v-model="showOptions" width="500">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary_text" elevation="2" icon v-bind="attrs" v-on="on" class="mr-2">
                            <v-icon>mdi-auto-fix</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <scheduling-options-modal v-model="showOptions"></scheduling-options-modal>
                    </v-card>
                </v-dialog>
                <v-btn v-if="entity_id" icon @click="copy_entity_id" title="Copy Shift Request ID">
                    <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                <input type="hidden" :value="entity_id" :id="'id-to-copy-' + entity_id">
            </v-col>
        </v-row>
        <user-lists-base :is-in-past="isInPast">
            <template v-slot:left-list-text-left>
                Matching Staff
            </template>
            <template v-slot:left-list-text-right>
                <v-checkbox
                    v-if="canSelectAll"
                    :disabled="!canOffer || listsLoading || selectedEligibleStatus === 'NO_ELIGIBLE' || availableShiftsCount < 1 || fullShift"
                    color="#4253C4"
                    class="ma-0 pa-0"
                    @click="selectAllOffers"
                    :indeterminate="selectedEligibleStatus === 'MIX_OFFERED'"
                    :input-value="selectedEligibleStatus === 'ALL_OFFERED'"
                    hide-details="true"
                ></v-checkbox>
            </template>
            <template v-slot:right-list-text-left>
                Shift Status
            </template>
            <template v-slot:right-list-text-right>
                <v-progress-circular v-if="listsLoading" color="primary" indeterminate :size="20"></v-progress-circular>
                <v-tooltip v-else bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                            Filled: {{ filledShiftsCount }} of {{ totalShiftsCount }}
                        </div>
                    </template>
                    <span>
                        {{ availableShiftsCount < 1 ? `${totalShiftsCount === 1 ? 'Shift' : 'Shifts'} Full` : `${totalShiftsCount - filledShiftsCount} ${totalShiftsCount - filledShiftsCount === 1 ? 'Shift' : 'Shifts'} Remaining` }}
                    </span>
                </v-tooltip>
            </template>
            <template v-slot:left-list>
                <actionable-user-list
                    :users="filteredActionableUsers"
                    :type="selectedView"
                    :loading="listsLoading"
                    :predictedAvailability="predictedAvailability"
                    @click="onActionableUserClick"
                    :in-the-past="isInPast"
                ></actionable-user-list>
            </template>
            <template v-slot:right-list>
                <presentational-user-list
                    :users="filteredPresentationalUsers"
                    :loading="listsLoading"
                    :has-permission="canUnassign"
                    :in-the-past="isInPast"
                    :is-shift-starting-soon="isShiftStartingSoon"
                    :predictedAvailability="predictedAvailability"
                    @onEditUser="onEditUser"
                    @onRemoveUser="onRemoveUser"
                ></presentational-user-list>
            </template>
            <template v-slot:right-list-action-button>
                <!-- Shows the toggle if the user is able to retroactively assign users -->
                <toggle-rejected-offers
                    v-if="!isInPast || hasAssignHistoricalPermission"
                    v-model="showRejectedOffers"
                    style="margin-left: 10px;"
                ></toggle-rejected-offers>
            </template>
        </user-lists-base>
    </v-col>
</template>

<script>
import ListViewSelect from '../../Inputs/ListViewSelect'
import UserListsBase from './UserListsBase'
import ActionableUserList from '../UserList/ActionableUserList'
import PresentationalUserList from "../UserList/PresentationalUserList";
import SchedulingOptionsModal from "../../Modals/SchedulingOptionsModal";
import ToggleRejectedOffers from "../../Inputs/ToggleRejectedOffers";
import { fetchPredictedAvailability } from "../../../../lib/helpers/schedules";

export default {
    name: 'UserLists',
    components: { ListViewSelect, UserListsBase, ActionableUserList, PresentationalUserList, SchedulingOptionsModal, ToggleRejectedOffers },
    props: {
        shiftRequestId: {
            type: String,
            default: null,
        },
        amountRequested: {
            type: Number,
            default: 0,
        },
        authIsAuthorized: {
            type: Boolean,
            default: true,
        },
        isInPast: {
            type: Boolean,
            default: false,
        },
        isShiftStartingSoon: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        viewItems: [
            {
                id: 'all',
                name: 'All'
            },
            {
                id: 'assign',
                name: 'Available'
            },
            {
                id: 'offer',
                name: 'Eligible'
            }
        ],
        presentationalUsers: [],
        rejectedUsers: [],
        showOptions: false,
        api: new formHelper(),
    }),
    computed: {
        selectedView: {
            get () {
                return this.$store.getters.getSelectedView
            },
            set (selectedView) {
                this.dispatchSetSelectedView(selectedView)
            },
        },
        searchBy: {
            get () {
                return this.$store.getters.getSearchBy
            },
            set (searchBy) {
                this.$store.dispatch('setSearchBy', { searchBy })
            },
        },
        prevSelectedIds: {
            get () {
                return this.$store.getters.getPrevSelectedIds
            },
            set (prevSelectedIds) {
                this.$store.dispatch('setPrevSelectedIds', { prevSelectedIds })
            },
        },
        actionableUsers: {
            get () {
                return this.$store.getters.getActionableUsers
            },
            set (actionableUsers) {
                this.$store.dispatch('setActionableUsers', { actionableUsers })
            },
        },
        hasAssignHistoricalPermission() {
            if (this.$authIsOwner) {
                return true;
            }
            if (this.$authIsAdministrator || this.$authIsManager) {
                return this.$userHasPermission(this.$user, this.$config.permissions.SHIFTS.HISTORICAL_ASSIGN);
            }
            return false;
        },
        loading () {
            return this.$store.getters.getUsersLoading
        },
        requestFormDetailsLoading () {
            return this.$store.getters.getIsRequestFormDetailsLoading
        },
        listsLoading () {
            return this.loading || this.requestFormDetailsLoading
        },
        filteredActionableUsers () {
            const { actionableUsers, searchBy } = this
            if (searchBy) {
                return actionableUsers.filter(user => {
                    const name = `${user.first_name} ${user.last_name}`.toLowerCase()
                    return name.includes(searchBy.trim().toLowerCase())
                })
            }

            return this.sortByPredictedAvailability(actionableUsers)
        },
        filteredPresentationalUsers () {
            const { allPresentationalUsers, searchBy } = this
            if (searchBy) {
                return allPresentationalUsers.filter(user => {
                    const name = `${user.first_name} ${user.last_name}`.toLowerCase()
                    return name.includes(searchBy.trim().toLowerCase())
                })
            }
            return allPresentationalUsers
        },
        allPresentationalUsers() {

            // Adds findLastIndex to the prototype of Arrays in environments
            // where it's missing, since this is not standard JavaScript.
            if (!Array.prototype.findLastIndex) {
                Array.prototype.findLastIndex = function(predicate) {
                    for (let i = this.length - 1; i >= 0; i--) {
                        if (predicate(this[i])) {
                            return i;
                        }
                    }
                    return -1;
                };
            }
            const { presentationalUsers, selectedAvailableIds, selectedEligibleIds } = this;
            const newPresentationalUsers = Array.from(presentationalUsers);

            if (selectedAvailableIds.length > 0) {
                const insertAt = presentationalUsers.filter(user => user.assigned).length;
                const selectedAvailableUsers = _.intersectionBy(
                    this.actionableUsers,
                    this.selectedAvailableIds.map(id => ({ '_id': id })),
                    '_id'
                ).map(user => ({ ...user, presentational: true }));
                newPresentationalUsers.splice(insertAt, 0, ...selectedAvailableUsers);
            }

            if (selectedEligibleIds.length > 0) {
                let insertAt = newPresentationalUsers.findLastIndex(user => user.offered);
                if (insertAt < 0) {
                    insertAt = newPresentationalUsers.findLastIndex(user =>
                        selectedAvailableIds.length > 0 ? user.available : user.assigned
                    )}
                const selectedEligibleUsers = _.intersectionBy(
                    this.actionableUsers,
                    this.selectedEligibleIds.map(id => ({ '_id': id })),
                    '_id'
                ).map(user => ({ ...user, presentational: true }));
                newPresentationalUsers.splice(insertAt < 0 ? 0 : insertAt + 1, 0, ...selectedEligibleUsers);
            }

            return newPresentationalUsers;
            },
        selectedIds () {
            return this.actionableUsers.filter(user => user.selected).map(user => user._id)
        },
        selectedAvailableIds () {
            return this.actionableUsers.filter(user => user.available && user.selected).map(user => user._id)
        },
        selectedEligibleIds () {
            return this.actionableUsers.filter(user => user.eligible && user.selected).map(user => user._id)
        },
        selectedEligibleStatus () {
            const isAllOffered = (user) => user.selected;
            const isNoneOffered = (user) => !user.selected;
            if (this.actionableUsers.filter(user => user.eligible && Boolean(user.staff_type?.out_of_work_hours_shift_offer_enabled)).length < 1) {
                return 'NO_ELIGIBLE'
            } else if (this.actionableUsers.filter(user => user.eligible && Boolean(user.staff_type?.out_of_work_hours_shift_offer_enabled)).every(isAllOffered)) {
                return 'ALL_OFFERED'
            } else if (this.actionableUsers.filter(user => user.eligible && Boolean(user.staff_type?.out_of_work_hours_shift_offer_enabled)).every(isNoneOffered)) {
                return 'NONE_OFFERED'
            } else {
                return 'MIX_OFFERED'
            }

        },
        filledShiftsCount () {
            return this.$store.getters.getAssignedCount
        },
        totalShiftsCount () {
            if (this.amountRequested) {
                return this.amountRequested
            }
            return 0
        },
        availableShiftsCount () {
            return this.totalShiftsCount - this.filledShiftsCount
        },
        canAssign () {
            return this.$userHasPermission(this.$user, this.$config.permissions.SHIFTS.ASSIGN) &&
                this.authIsAuthorized && this.availableShiftsCount > 0
        },
        canOffer () {
            return this.$userHasPermission(this.$user, this.$config.permissions.SHIFT_OFFERS.SEND) &&
                this.authIsAuthorized && this.availableShiftsCount > 0
        },
        canUnassign() {
            return this.$userHasPermission(this.$user, this.$config.permissions.SHIFTS.UNASSIGN) && this.authIsAuthorized;
        },
        canDirectAssign () {
            // Not in use
            return this.$userHasPermission(this.$user, this.$config.permissions.SHIFTS.DIRECT_ASSIGN) &&
                this.authIsAuthorized && this.availableShiftsCount > 0
        },
        predictedAvailability() {
            return this.$store.getters.getPredictedAvailability
        },
        predictionsEnabled() {
            return this.$root.instancePredictionsEnabled && this.$root.organizationPredictionsEnabled && this.$store.getters.getPredictionsEnabled
        },
        showRejectedOffers: {
            get () {
                return this.$store.getters.getShowRejectedOffers
            },
            set (showRejectedOffers) {
                this.$store.dispatch('setShowRejectedOffers', { showRejectedOffers })
            }
        },
        canSelectAll() {
            return this.$authIsOwner || this.$authIsAdministrator || this.$authIsManager
        },
        fullShift() {
            return this.selectedAvailableIds.length >= this.availableShiftsCount
        },
        selectedShiftRequestId () {
            return this.$store.getters.getSelectedShiftRequestId
        },
        entity_id () {
            if (this.selectedShiftRequestId) {
                return this.selectedShiftRequestId
            }
            return false
        },
        shouldShowSchedulingOptionsModal() {
            return this.$root.instancePredictionsEnabled && this.$root.organizationPredictionsEnabled;
        },
    },
    methods: {
        fetchUsers (shiftRequestId, type) {
            this.dispatchSetUsersLoading(true)
            this.api.get(`/shift-request/get-users/${shiftRequestId}/${type}`)
                .then(response => {
                    if (response && response.data) {
                        const {
                            available_users,
                            offerable_users,
                            assigned_users,
                            offered_users,
                            rejected_users,
                        } = response.data
                        this.dispatchSetAssignedCount(assigned_users.length)
                        const availableUsers = Array.isArray(available_users) ?
                            available_users.map(user => ({
                                ...user,
                                available: true,
                                selected: false,
                                disabled: !this.canAssign,
                            })) : []
                        const eligibleUsers = Array.isArray(offerable_users) ?
                            offerable_users.map(user => ({
                                ...user,
                                eligible: true,
                                selected: false,
                                disabled: !this.canOffer,
                            })) : []
                        const assignedUsers = Array.isArray(assigned_users) ?
                            assigned_users.map(user => ({
                                ...user,
                                assigned: true,
                            })) : []
                        const offeredUsers = Array.isArray(offered_users) ?
                            offered_users.map(user => ({
                                ...user,
                                offered: true,
                            })) : []
                        const rejectedUsers = Array.isArray(rejected_users) ?
                            rejected_users.map(user => ({
                                ...user,
                                rejected: true,
                            })) : []
                        this.rejectedUsers = Array.from(rejectedUsers)
                        if (type === 'all') {
                            this.actionableUsers = [
                                ...availableUsers,
                                ...eligibleUsers,
                            ]
                        } else if (type === 'assign') {
                            this.actionableUsers = Array.from(availableUsers)
                        } else if (type === 'offer') {
                            this.actionableUsers = Array.from(eligibleUsers)
                        } else {
                            this.actionableUsers = []
                        }
                        if (this.isInPast) {
                            this.presentationalUsers = [
                                ...assignedUsers,
                            ]
                        } else {
                            if (this.showRejectedOffers) {
                                this.presentationalUsers = [
                                    ...assignedUsers,
                                    ...offeredUsers,
                                    ...rejectedUsers,
                                ]
                            } else {
                                this.presentationalUsers = [
                                    ...assignedUsers,
                                    ...offeredUsers,
                                ]
                            }
                        }
                        if (this.prevSelectedIds && Array.isArray(this.prevSelectedIds[type])) {
                            const mapping = this.prevSelectedIds[type].reduce((accum, id) => ({
                                ...accum,
                                [id]: true,
                            }), {})
                            this.actionableUsers = this.actionableUsers.map(user => ({
                                ...user,
                                selected: mapping.hasOwnProperty(user._id)
                            }))
                            if (this.selectedAvailableIds.length >= this.totalShiftsCount) {
                                this.actionableUsers = this.actionableUsers.map(user => {
                                    if (user.available) {
                                        return {
                                            ...user,
                                            disabled: !user.selected,
                                        }
                                    }
                                    return user
                                })
                            }
                        }
                    }
                })
                .catch(console.log)
                .finally(() => {
                    this.dispatchSetUsersLoading(false)
                })
        },
        fetchPredictedAvailability(selectedShiftRequestId) {
            if (!selectedShiftRequestId) return;

            if (this.$root.organizationPredictionsEnabled && this.predictionsEnabled) {
                fetchPredictedAvailability(selectedShiftRequestId)
                    .then(this.dispatchSetPredictedAvailability)
                    .catch(console.error);
            } else {
                this.dispatchSetPredictedAvailability(null);
            }
        },
        sortByPredictedAvailability(users) {
            if (!this.$root.organizationPredictionsEnabled || !this.predictionsEnabled || !this.predictedAvailability) {
                return users
            }

            const availableUsers = users.filter(user => user.available)
            const eligibleUsers = users.filter(user => user.eligible)

            return [
                ...availableUsers,
                ..._.orderBy(eligibleUsers, (user) => this.predictedAvailability[user._id] || -Infinity, 'desc')
            ]
        },
        selectAllOffers () {
            const isAllOffered = (user) => user.selected;
            const isNoneOffered = (user) => !user.selected;
            const eligibleUsersList = this.actionableUsers.filter(user => user.eligible && Boolean(user.staff_type?.out_of_work_hours_shift_offer_enabled))
            if (eligibleUsersList.every(isAllOffered || isNoneOffered)) {
                eligibleUsersList.map(actionableUser => {
                    return this.onActionableUserClick(actionableUser)
                })
            } else {
                this.actionableUsers.filter(user => user.eligible && Boolean(user.staff_type?.out_of_work_hours_shift_offer_enabled) && !user.selected).map(actionableUser => {
                    return this.onActionableUserClick(actionableUser)
                })
            }
        },
        onActionableUserClick(selectedUser) {

            // Calculate the count of selected users who are available or directly assigned.
            const currentSelectedCount = this.actionableUsers.filter(user =>
                user.available && user.selected
            ).length;

            const availableUserSelected = selectedUser.available && !selectedUser.selected;

            // Adjust count based on the action that is about to be performed.
            const exceedsAvailableShifts = currentSelectedCount + 1 >= this.availableShiftsCount;

            // If the selection fills the shift limit, deselect all eligible & selected users first.
            if (availableUserSelected) {
                if (exceedsAvailableShifts) {
                    this.actionableUsers
                        .filter(user => user.eligible && user.selected)
                        .forEach(user => this.onActionableUserClick(user));
                }
            }

            const availableUserDeselected = selectedUser.available && selectedUser.selected
            const eligibleUserDeselected = selectedUser.eligible && selectedUser.selected

            // Apply updates efficiently
            this.actionableUsers = this.actionableUsers.map(user => {
                const isCurrentlySelectedUser = user._id === selectedUser._id;

                if (isCurrentlySelectedUser) {

                    return {
                        ...user,
                        selected: !user.selected,
                        ...eligibleUserDeselected && exceedsAvailableShifts && { disabled: true },
                    };
                }

                // Users should only be disabled if the selection count has hit the limit
                return {
                    ...user,
                    ...availableUserSelected && exceedsAvailableShifts && !user.selected && { disabled: true },
                    ...availableUserDeselected && exceedsAvailableShifts && { disabled: false },
                };
                
            });
            },
        onChangeSelectedView () {
            if (this.shiftRequestId && this.selectedView) {
                this.searchBy = null
                this.fetchUsers(this.shiftRequestId, this.selectedView)
            }
        },
        onEditUser (shiftId) {
            this.$emit('onEditUser', shiftId)
        },
        onRemoveUser (userId) {
            this.$emit('onRemoveUser', userId)
        },
        dispatchSetSelectedView (selectedView) {
            this.$store.dispatch('setSelectedView', { selectedView })
        },
        dispatchSetUsersLoading (usersLoading) {
            this.$store.dispatch('setUsersLoading', { usersLoading })
        },
        dispatchSetAssignedCount (assignedCount) {
            this.$store.dispatch('setAssignedCount', { assignedCount })
        },
        dispatchSetToBeAssignedIds (toBeAssignedIds) {
            this.$store.dispatch('setToBeAssignedIds', { toBeAssignedIds })
        },
        dispatchSetToBeOfferedIds (toBeOfferedIds) {
            this.$store.dispatch('setToBeOfferedIds', { toBeOfferedIds })
        },
        dispatchSetPredictedAvailability(predictedAvailability) {
            this.$store.dispatch('setPredictedAvailability', { predictedAvailability })
        },
        dispatchSetPredictionsEnabled(enabled) {
            this.$store.dispatch('setPredictionsEnabled', { enabled })
        },
        copy_entity_id() {
            if (typeof this.selectedShiftRequestId === 'undefined') {
                return false
            }

            let copy_field = document.querySelector('#id-to-copy-' + this.selectedShiftRequestId)
            copy_field.setAttribute('type', 'text')
            copy_field.select()

            try {
                let success = document.execCommand('copy')
                if (success) {
                    this.$snackNotify('success', `${this.selectedShiftRequestId} copied to your clipboard.`)
                }
            } catch (err) {
                this.$snackNotify('warning', 'Unable to copy shift ID. Please try again.')
            }

            copy_field.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges()
        },
    },
    watch: {
        selectedView: {
            immediate: true,
            handler (newSelectedView, oldSelectedView) {
                if (oldSelectedView === 'all' || oldSelectedView === 'assign' || oldSelectedView === 'offer') {
                    this.prevSelectedIds = {
                        ...this.prevSelectedIds,
                        [oldSelectedView]: Array.from(this.selectedIds),
                    }
                }
            },
        },
        shiftRequestId: {
            immediate: true,
            handler (shiftRequestId) {
                if (shiftRequestId && this.selectedView) {
                    this.searchBy = null
                    this.fetchUsers(shiftRequestId, this.selectedView)
                    this.fetchPredictedAvailability(this.shiftRequestId)
                }
            }
        },
        selectedAvailableIds: {
            immediate: true,
            handler (toBeAssignedIds) {
                this.dispatchSetToBeAssignedIds(toBeAssignedIds)
            },
        },
        selectedEligibleIds: {
            immediate: true,
            handler (toBeOfferedIds) {
                this.dispatchSetToBeOfferedIds(toBeOfferedIds)
            },
        },
        predictionsEnabled: {
            immediate: true,
            handler() {
                this.fetchPredictedAvailability(this.shiftRequestId)
            },
        },
        showRejectedOffers (showRejectedOffers) {
            if (showRejectedOffers) {
                if (Array.isArray(this.presentationalUsers) && Array.isArray(this.rejectedUsers)) {
                    this.presentationalUsers = [
                        ...this.presentationalUsers,
                        ...this.rejectedUsers,
                    ]
                }
            } else {
                if (Array.isArray(this.presentationalUsers)) {
                    this.presentationalUsers = this.presentationalUsers.filter(user => !user.rejected)
                }
            }
        }
    },
    async mounted() {
        if (this.shouldShowSchedulingOptionsModal) {
            const { data } = await this.api.get(`/user/ai-predictions-enabled`);
            this.dispatchSetPredictionsEnabled(data.enabled);
        }
    },
}
</script>

<style scoped>
    .user-lists-header-layout-container {
        flex: 0 1 auto;
    }

    :deep(div.v-input__slot) {
        margin-bottom: 0;
    }

    :deep(div.v-text-field__details) {
        display: none !important;
    }
</style>
