<template>
    <v-container fluid>
        <v-row dusk="userPermissionsTab">
            <v-col cols="12">
                <v-tabs vertical background-color="white">
                    <v-tab v-for="tab in filteredTabs" :name="tab.identifier" :key="tab.name" class="justify-start">
                        <v-icon left>
                            {{ tab.icon }}
                        </v-icon>
                        {{ tab.name }}
                    </v-tab>
                    <v-tab-item v-for="tab in filteredTabs" :key="tab.name">
                        <v-row class="v-tab-item-container">
                            <v-divider vertical></v-divider>
                            <v-col cols="10" class="v-tab-item-content-container">
                                <v-row>
                                    <v-col cols="6">
                                        <div class="text--h3" style="color: #000000;">{{ tab.name }}</div>
                                        <div class="main-divider"></div>
                                    </v-col>
                                </v-row>
                                <v-row v-for="permission in tab.permissions" :key="permission.identifier">
                                    <v-col cols="10" style="margin-bottom: 0; padding-bottom: 0;" v-if="permission.type === 'section'">
                                        <v-col cols="6" style="margin-top: 0; margin-bottom: 0; padding-top: 0; padding-bottom: 0;">
                                            <div class="text--h4" style="color: #000000;">{{ permission.title }}</div>
                                            <div class="main-divider"></div>
                                        </v-col>
                                    </v-col>
                                    <v-col cols="12" style="margin-left: 8px;" v-else>
                                        <v-list-item>
                                            <v-list-item-action start style="margin-right: 10px;">
                                                <v-switch inset :name="permission.identifier" :readonly="cannotEdit" :disabled="cannotEdit" v-model="permissions[permission.name]" @change="permission.onChange"></v-switch>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <span class="text--label">{{ permission.title }}</span>
                                                <v-list-item-subtitle class="text--caption">
                                                    {{ permission.description }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider></v-divider>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs>
            </v-col>
        </v-row>
        <v-row v-if="newUser">
            <v-col cols="6" class="mt-5">
                <v-btn color="primary" text @click="onPreviousTab" :disabled="updatingPermissions">Previous</v-btn>
            </v-col>
            <v-col cols="6" class="text-right mt-5">
                <v-btn dusk="permissionsTabNextButton" color="primary" outlined @click="onSaveProfile" :loading="updatingPermissions" :disabled="updatingPermissions">Next</v-btn>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12" class="text-right mt-5">
                <v-btn
                    color="secondary"
                    dusk="savePermissionsButton"
                    @click="onSaveProfile"
                    :disabled="cannotEdit || updatingPermissions"
                    :loading="updatingPermissions">
                    Save
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "UserPermissions",
        props: {
            user: {
                type: Object,
                default: () => {},
            },
            newUser: {
                type: Boolean,
                default: false,
            },
            roles: {
                type: Array,
                default: () => []
            },
        },
        data() {
            const PERMISSION_CONSTANTS = Object.assign({}, this.$config.permissions)
            const tabs = {
                organization: {
                    name: 'Organization',
                    icon: 'mdi-file-tree',
                    identifier: 'org-permissions',
                    permissions: {
                        viewOrganizationPage: {
                            title: 'Access Organization Page',
                            description: 'View organization page and location details.',
                            identifier: 'view-organization',
                            name: PERMISSION_CONSTANTS.ORGANIZATION.VIEW,
                            type: 'permission',
                            onChange: this.onViewOrganizationChanged
                        },
                        viewLocationsPage: {
                            title: 'Access Locations',
                            description: 'View location page details.',
                            identifier: 'view-location',
                            name: PERMISSION_CONSTANTS.LOCATIONS.VIEW,
                            type: 'permission',
                            onChange: this.onViewLocationChanged
                        },
                        editLocationsPage: {
                            title: 'Manage Locations',
                            description: 'Add new locations and edit assigned location details.',
                            identifier: 'edit-location',
                            name: PERMISSION_CONSTANTS.LOCATIONS.EDIT,
                            type: 'permission',
                            onChange: this.onEditLocationChanged
                        },
                        organizationSection: {
                            title: 'Organization Settings',
                            identifier: 'organization-settings-section',
                            type: 'section'
                        },
                        viewOrganizationSettingsPage: {
                            title: 'Access Configuration Options',
                            description: 'View the settings page.',
                            identifier: 'view-organization-settings',
                            name: PERMISSION_CONSTANTS.SETTINGS.VIEW,
                            type: 'permission',
                            onChange: this.onViewOrganizationSettingsChanged
                        },
                        // editOrganizationSettingsPage: {
                        //     title: 'Manage Organization Settings',
                        //     description: 'Edit the settings page.',
                        //     identifier: 'edit-organization-settings',
                        //     name: PERMISSION_CONSTANTS.SETTINGS.EDIT,
                        //     type: 'permission',
                        //     // onChange: this.onViewOrganizationChanged
                        // },
                        editEmploymentTypes: {
                            title: 'Manage Employment Types',
                            description: 'Add/Edit organization level employment types.',
                            identifier: 'edit-employment-types',
                            name: PERMISSION_CONSTANTS.STAFF_TYPES.EDIT,
                            type: 'permission',
                            onChange: this.onOrganizationChanged
                        },
                        editCredentials: {
                            title: 'Manage Credentials',
                            description: 'Add/Edit organization level credentials.',
                            identifier: 'edit-credentials',
                            name: PERMISSION_CONSTANTS.REQUIREMENTS.EDIT,
                            type: 'permission',
                            onChange: this.onOrganizationChanged
                        },
                        editShiftTimes: {
                            title: 'Manage Shift Times',
                            description: 'Add/Edit organization level shift times.',
                            identifier: 'edit-shift-times',
                            name: PERMISSION_CONSTANTS.SHIFT_TIMES.EDIT,
                            type: 'permission',
                            onChange: this.onOrganizationChanged
                        },
                        editCustomFields: {
                            title: 'Manage Custom Fields',
                            description: 'Add/Edit organization level custom fields.',
                            identifier: 'edit-custom-fields',
                            name: PERMISSION_CONSTANTS.CUSTOM_FIELDS.EDIT,
                            type: 'permission',
                            onChange: this.onOrganizationChanged
                        },
                        departmentSection: {
                            title: 'Departments',
                            identifier: 'org-departments-section',
                            type: 'section'
                        },
                        viewDepartments: {
                            title: 'Access Departments Page',
                            description: 'View the main departments page.',
                            identifier: 'view-departments',
                            name: PERMISSION_CONSTANTS.DEPARTMENTS.VIEW,
                            type: 'permission',
                            onChange: this.onViewDepartmentsChanged
                        },
                        editDepartments: {
                            title: 'Manage Departments',
                            description: 'Add/Edit departments.',
                            identifier: 'edit-departments',
                            name: PERMISSION_CONSTANTS.DEPARTMENTS.EDIT,
                            type: 'permission',
                            onChange: this.onEditDepartmentsChanged
                        },
                        editStaffingDetails: {
                            title: 'Manage Staffing Details',
                            description: 'Add/Edit department staffing details.',
                            identifier: 'edit-staffing-details',
                            name: PERMISSION_CONSTANTS.DEPARTMENTS.AUTHORIZE_STAFF_GROUP,
                            type: 'permission',
                            onChange: this.onEditStaffingDetails
                        },
                        staffGroupSection: {
                            title: 'Staff Groups',
                            identifier: 'org-staff-group-section',
                            type: 'section'
                        },
                        viewStaffGroups: {
                            title: 'Access Staff Groups Page',
                            description: 'View the main Staff groups page.',
                            identifier: 'view-staff-groups',
                            name: PERMISSION_CONSTANTS.STAFF_GROUPS.VIEW,
                            type: 'permission',
                            onChange: this.onViewStaffGroupsChanged
                        },
                        editStaffGroups: {
                            title: 'Manage Staff Groups',
                            description: 'Add/Edit staff groups.',
                            identifier: 'edit-staff-groups',
                            name: PERMISSION_CONSTANTS.STAFF_GROUPS.EDIT,
                            type: 'permission',
                            onChange: this.onEditStaffGroupsChanged
                        },
                        editJobTitles: {
                            title: 'Manage Job Titles',
                            description: 'Add/Edit staff group job titles.',
                            identifier: 'edit-job-titles',
                            name: PERMISSION_CONSTANTS.JOB_TITLES.EDIT,
                            type: 'permission',
                            onChange: this.onEditRequirementsOrJobTitlesOrShiftTimes
                        },
                        // I'm leaving this in place because I think these will need to eventually come back...
                        // editStaffGroupCredentials: {
                        //     title: 'Manage Credentials',
                        //     description: 'Add/Edit available credentials.',
                        //     identifier: 'edit-staff-group-credentials',
                        //     name: PERMISSION_CONSTANTS.STAFF_GROUPS.EDIT_REQUIREMENTS,
                        //     type: 'permission',
                        //     onChange: this.onEditRequirementsOrJobTitlesOrShiftTimes
                        // },
                        // editStaffGroupShiftTimes: {
                        //     title: 'Manage Shift Times',
                        //     description: 'Add/Edit shift times.',
                        //     identifier: 'edit-shift-times',
                        //     name: PERMISSION_CONSTANTS.STAFF_GROUPS.EDIT_SHIFT_TIMES,
                        //     type: 'permission',
                        //     onChange: this.onEditRequirementsOrJobTitlesOrShiftTimes
                        // }
                    }
                },
                users: {
                    name: 'Users',
                    icon: 'mdi-account-multiple',
                    identifier: 'users-permissions',
                    permissions: {
                        viewUsers: {
                            title: 'Access Users Page',
                            description: 'View the main users page.',
                            identifier: 'view-users-page',
                            name: PERMISSION_CONSTANTS.USERS.VIEW_USERS_PAGE,
                            onChange: this.onViewUsersChanged,
                            type: 'permission'
                        },
                        impersonationSection: {
                            title: 'Impersonation',
                            identifier: 'impersonate-users-section',
                            type: 'section'
                        },
                        impersonateUsers: {
                            title: 'Impersonate Users',
                            description: 'Ability to login to each users account and review.',
                            identifier: 'impersonate-users',
                            name: PERMISSION_CONSTANTS.USERS.IMPERSONATE,
                            onChange: this.onImpersonateUsersChanged,
                            type: 'permission'
                        },
                        profileSection: {
                            title: 'Profile',
                            identifier: 'users-profile-section',
                            type: 'section'
                        },
                        viewOthersProfile: {
                            title: 'Access User Profiles',
                            description: 'View other users\' profiles.',
                            identifier: 'view-others-profile',
                            name: PERMISSION_CONSTANTS.USERS.VIEW_OTHERS_PROFILE,
                            onChange: this.onViewOthersProfile,
                            type: 'permission'
                        },
                        editOthersProfile: {
                            title: 'Manage User Profiles',
                            description: 'Add/Edit other users\' profiles.',
                            identifier: 'edit-others-profile',
                            name: PERMISSION_CONSTANTS.USERS.EDIT_OTHERS_PROFILE,
                            onChange: this.onEditOthersProfileChanged,
                            type: 'permission'
                        },
                        jobsSection: {
                            title: 'Jobs',
                            identifier: 'users-jobs-section',
                            type: 'section'
                        },
                        viewOthersJobs: {
                            title: 'Access User Jobs',
                            description: 'View other users\' jobs.',
                            identifier: 'view-others-jobs',
                            name: PERMISSION_CONSTANTS.USERS.VIEW_OTHERS_JOBS,
                            onChange: this.onViewOthersJobs,
                            type: 'permission'
                        },
                        editOthersJobs: {
                            title: 'Manage User Jobs',
                            description: 'Add/Edit other users\' jobs.',
                            identifier: 'edit-others-jobs',
                            name: PERMISSION_CONSTANTS.USERS.EDIT_OTHERS_JOBS,
                            onChange: this.onEditOthersJobsChanged,
                            type: 'permission'
                        },
                        permissionsSection: {
                            title: 'Permissions',
                            identifier: 'users-permissions-section',
                            type: 'section'
                        },
                        viewOthersPermissions: {
                            title: 'Access User Permissions',
                            description: 'View other users\' permissions.',
                            identifier: 'view-others-permissions',
                            name: PERMISSION_CONSTANTS.USERS.VIEW_OTHERS_PERMISSIONS,
                            onChange: this.onViewOthersPermissions,
                            type: 'permission'
                        },
                        editOthersPermissions: {
                            title: 'Manage User Permissions',
                            description: 'Add/Edit other users\' permissions.',
                            identifier: 'edit-others-permissions',
                            name: PERMISSION_CONSTANTS.USERS.EDIT_OTHERS_PERMISSIONS,
                            onChange: this.onEditOthersPermissionsChanged,
                            type: 'permission'
                        },
                        availabilitySection: {
                            title: 'Availability',
                            identifier: 'users-availability-section',
                            type: 'section'
                        },
                        viewOthersAvailability: {
                            title: 'Access User Availability',
                            description: 'View other users\' availability.',
                            identifier: 'view-others-availability',
                            name: PERMISSION_CONSTANTS.AVAILABILITY.VIEW_OTHERS,
                            onChange: this.onViewOthersAvailability,
                            type: 'permission'
                        },
                        editOthersAvailability: {
                            title: 'Manage User Availability',
                            description: 'Add/Edit other users\' availability.',
                            identifier: 'edit-others-availability',
                            name: PERMISSION_CONSTANTS.AVAILABILITY.EDIT_OTHERS,
                            onChange: this.onEditOthersAvailabilityChanged,
                            type: 'permission'
                        },
                        respondToTimeOffRequest: {
                            title: 'Manage Time Off Requests',
                            description: 'Respond to time off requests.',
                            identifier: 'respond-to-time-off-request',
                            name: PERMISSION_CONSTANTS.TIME_OFF_REQUEST.RESPOND,
                            type: 'permission'
                        }
                    }
                },
                scheduling: {
                    name: 'Scheduling',
                    icon: 'mdi-calendar-month',
                    identifier: 'scheduling-permissions',
                    permissions: {
                        viewSchedules: {
                            title: 'Access Schedules Page',
                            description: 'View the schedules page.',
                            //  + 'limited by the departments & staff groups managed.', if manager
                            identifier: 'view-schedules-page',
                            name: PERMISSION_CONSTANTS.USERS.VIEW_SCHEDULES_PAGE,
                            onChange: this.onViewSchedulesPageChanged,
                            type: 'permission'
                        },
                        viewExpandedSchedules: {
                            title: 'Access All Departments',
                            description: 'View the schedules page limited by staff groups.',
                            identifier: 'view-expanded-schedules',
                            name: PERMISSION_CONSTANTS.USERS.VIEW_EXPANDED_SCHEDULES,
                            type: 'permission'
                        },
                        shiftRequestsSection: {
                            title: 'Shifts',
                            identifier: 'scheduling-shift-section',
                            type: 'section'
                        },
                        createShiftRequests: {
                            title: 'Create Shifts',
                            description: 'Add new shift(s), Edit shifts, Mark shifts as Call Out or No Show.',
                            identifier: 'create-shift-requests',
                            name: PERMISSION_CONSTANTS.REQUEST_FORMS.EDIT,
                            onChange: this.onSchedulesInputChange,
                            type: 'permission'
                        },
                        addHistoricalShiftRequests: {
                            title: 'Add Historical Shifts',
                            description: 'Add shifts retroactively.',
                            identifier: 'create-historic-shift-requests',
                            name: PERMISSION_CONSTANTS.REQUEST_FORMS.ADD_HISTORICAL,
                            onChange: this.onSchedulesInputChange,
                            type: 'permission'
                        },
                        sendShiftOffers: {
                            title: 'Offer Shifts',
                            description: 'Send shift offers to Staff users.',
                            identifier: 'send-shift-offer',
                            name: PERMISSION_CONSTANTS.SHIFT_OFFERS.SEND,
                            onChange: this.onSchedulesInputChange,
                            type: 'permission'
                        },
                        assignShifts: {
                            title: 'Assign Shifts',
                            description: 'Assign staff with matching availability.',
                            identifier: 'assign-shifts',
                            name: PERMISSION_CONSTANTS.SHIFTS.ASSIGN,
                            onChange: this.onSchedulesInputChange,
                            type: 'permission'
                        },
                        assignHistoricalShifts: {
                            title: 'Assign Historical Shifts',
                            description: 'Directly assign users to past open shifts for historical purposes.',
                            identifier: 'assign-historical-shifts',
                            name: PERMISSION_CONSTANTS.SHIFTS.HISTORICAL_ASSIGN,
                            onChange: this.onSchedulesInputChange,
                            type: 'permission'
                        },
                        unassignShifts: {
                            title: 'Un-assign Shifts',
                            description: 'Remove scheduled users from a shift.',
                            identifier: 'unassign-shifts',
                            name: PERMISSION_CONSTANTS.SHIFTS.UNASSIGN,
                            onChange: this.onSchedulesInputChange,
                            type: 'permission'
                        },
                        directAssignShifts: {
                            title: 'Direct Assign Shifts',
                            description: 'Directly assign users to open shifts without availability.',
                            identifier: 'direct-assign-shifts',
                            name: PERMISSION_CONSTANTS.SHIFTS.DIRECT_ASSIGN,
                            onChange: this.onSchedulesInputChange,
                            type: 'permission'
                        },
                        handleShiftCancellations: {
                            title: 'Handle Cancellation Requests',
                            description: 'Respond to cancellation requests.',
                            identifier: 'handle-cancellation-request',
                            name: PERMISSION_CONSTANTS.SHIFTS.HANDLE_CANCELLATION,
                            onChange: this.onSchedulesInputChange,
                            type: 'permission'
                        },
                        viewStaffingAutomations: {
                            title: 'Access Staffing Automations Page',
                            description: 'View the staffing automations page.',
                            //  limited by the departments & staff groups they manage.', if manager
                            identifier: 'view-staffing-automations-page',
                            name: PERMISSION_CONSTANTS.STAFFING_AUTOMATION.VIEW,
                            type: 'permission'
                        },
                        editStaffingAutomations: {
                            title: 'Manage Staffing Automations',
                            description: 'Add/Edit staffing automations.',
                            identifier: 'edit-staffing-automations-page',
                            name: PERMISSION_CONSTANTS.STAFFING_AUTOMATION.EDIT,
                            onChange: this.onEditStaffingAutomationsChanged,
                            type: 'permission'
                        }
                    }
                },
                reports: {
                    name: 'Reports',
                    icon: 'mdi-chart-bar',
                    identifier: 'reports-permissions',
                    permissions: {
                        viewReports: {
                            title: 'Access Reports Page',
                            description: 'View the main reports page.',
                            identifier: 'view-reports',
                            name: PERMISSION_CONSTANTS.REPORTS.VIEW,
                            type: 'permission',
                            onChange: this.onViewReportsChanged
                        },
                        generateReports: {
                            title: 'Generate Reports',
                            description: 'Create and access reports.',
                            identifier: 'generate-reports',
                            name: PERMISSION_CONSTANTS.REPORTS.GENERATE,
                            type: 'permission',
                            onChange: this.onGenerateReportsOrEditPresetsChanged
                        },
                        editReportPresets: {
                            title: 'Manage Report Presets',
                            description: 'Add/Edit report presets.',
                            identifier: 'edit-report-presets',
                            name: PERMISSION_CONSTANTS.REPORT_PRESETS.EDIT,
                            type: 'permission',
                            onChange: this.onGenerateReportsOrEditPresetsChanged
                        }
                    }
                },
                shifts: {
                    name: 'Shifts',
                    icon: 'mdi-calendar-account',
                    identifier: 'shifts-permissions',
                    permissions: {
                        viewEmployeeCalendar: {
                            title: 'Access Shifts Calendar',
                            description: 'View and interact with personal shifts.',
                            identifier: 'view-employee-calendar',
                            name: PERMISSION_CONSTANTS.USERS.VIEW_SHIFTS_PAGE,
                            type: 'permission',
                            onChange: this.onViewShiftsCalendarChanged
                        },
                        requestShiftCancellations: {
                            title: 'Request Shift Cancellation',
                            description: 'Request cancellation for scheduled shifts.',
                            identifier: 'request-shift-cancellation',
                            name: PERMISSION_CONSTANTS.SHIFTS.REQUEST_CANCELLATION,
                            type: 'permission',
                            onChange: this.onRequestShiftsCancellationChanged
                        }
                    }
                },
                profile: {
                    name: 'Profile',
                    icon: 'mdi-account',
                    identifier: 'profile-permissions',
                    permissions: {
                        editOwnProfile: {
                            title: 'Manage Own Profile',
                            description: 'Update personal profile contents.',
                            identifier: 'edit-own-profile',
                            name: PERMISSION_CONSTANTS.USERS.EDIT_OWN_PROFILE,
                            type: 'permission'
                        },
                        jobsSection: {
                            title: 'Jobs',
                            identifier: 'profile-jobs-section',
                            type: 'section'
                        },
                        viewOwnJobs: {
                            title: 'Access Own Jobs',
                            description: 'View personal job title assignments.',
                            identifier: 'view-own-jobs',
                            name: PERMISSION_CONSTANTS.USERS.VIEW_OWN_JOBS,
                            type: 'permission'
                        },
                        availabilitySection: {
                            title: 'Availability',
                            identifier: 'profile-availability-section',
                            type: 'section'
                        },
                        viewOwnAvailability: {
                            title: 'Access Own Availability',
                            description: 'View personal availability.',
                            identifier: 'view-own-profile',
                            name: PERMISSION_CONSTANTS.AVAILABILITY.VIEW_OWN,
                            type: 'permission',
                            onChange: this.onViewOwnAvailabilityChanged,
                        },
                        editOwnAvailability: {
                            title: 'Manage Own Availability',
                            description: 'Add/Edit personal availability.',
                            identifier: 'edit-own-availability',
                            name: PERMISSION_CONSTANTS.AVAILABILITY.EDIT_OWN,
                            onChange: this.onEditOwnAvailabilityChanged,
                            type: 'permission'
                        },
                        requestTimeOffRequest: {
                            title: 'Request Time Off',
                            description: 'Submit time off requests.',
                            identifier: 'request-time-off-request',
                            name: PERMISSION_CONSTANTS.TIME_OFF_REQUEST.REQUEST,
                            type: 'permission'
                        }
                    }
                }
            }
            const permissions = this.initPermissions()
            const prevPermissions = _.cloneDeep(permissions)
            return {
                api: new formHelper(),
                creatingUser: false,
                updatingPermissions: false,
                permissions,
                prevPermissions,
                PERMISSION_CONSTANTS,
                tabs,
                allowedSections: {
                    administrator: {
                        'organization-settings-section': true,
                        'org-departments-section': true,
                        'org-staff-group-section': true,
                        'users-profile-section': true,
                        'impersonate-users-section': true,
                        'users-jobs-section': true,
                        'users-permissions-section': true,
                        'users-availability-section': true,
                        'scheduling-shift-section': true,
                    },
                    manager: {
                        'users-profile-section': true,
                        'users-jobs-section': true,
                        'users-permissions-section': true,
                        'users-availability-section': true,
                        'scheduling-shift-section': true,
                    },
                    staff: {
                        'profile-jobs-section': true,
                        'profile-availability-section': true,
                    }
                }
            }
        },
        methods: {
            onPreviousTab() {
                this.$emit('onPreviousTab')
            },
            onNextTab() {
                this.creatingUser = true
                this.api.post('/user/create', {
                    ...this.user,
                    permissions: this.buildPermissionsArray()
                })
                    .then(({ data, message }) => {
                        this.$snackNotify('success', `User ${this.user.first_name} ${this.user.last_name} added.`)
                        this.creatingUser = false
                        this.$emit('user:saved', data, () => this.$emit('onNextTab'))
                    })
                    .catch(({ message, data }) => {
                        this.creatingUser = false
                        if (data.message) {
                            message(data.message)
                        } else {
                            this.$snackNotify('warning', 'Unable to add user. Please try again.')
                        }
                    })
            },
            onSaveProfile(callback) {
                this.updatingPermissions = true
                this.api.post(`/user/update-permissions/${this.user._id}`, {
                    permissions: this.buildPermissionsArray()
                })
                    .then(({ data }) => {
                        this.updatingPermissions = false
                        this.$snackNotify('success', 'Permissions updated.')
                        let fn = false
                        if(this.newUser) {
                            if(this.$userIsStaff(this.user)) {
                                fn = () => this.$emit('onNextTab')
                            }
                        } else {
                            if(callback && typeof callback === 'function') {
                                fn = callback
                            }
                        }
                        this.$emit('user:saved', {
                            ...this.user,
                            permissions: data
                        }, fn)
                    })
                    .catch(() => {
                        this.updatingPermissions = false
                        this.$snackNotify('warning', 'Unable to save changes to permissions. Please try again.')
                        if(!this.newUser && callback && typeof callback === 'function') {
                            callback()
                        }
                    })
            },
            initPermissions() {
                const { user, roles, newUser, $userHasPermission } = this
                const { role_id, permissions } = user
                const role = roles.find(r => r._id === role_id)
                if(role) {
                    const { available_permissions, enabled_permissions } = role
                    const enabledPermissionsDict = enabled_permissions.reduce((accumulator, permission) => ({
                        ...accumulator,
                        [permission]: true
                    }), {})
                    return available_permissions.reduce((accumulator, permission) => {
                        return {
                            ...accumulator,
                            [permission]: newUser ? enabledPermissionsDict.hasOwnProperty(permission) : $userHasPermission(user, permission)
                        }
                    }, {})
                } else {
                    if(this.newUser) {
                        return {}
                    } else {
                        return permissions.reduce((accumulator, permission) => ({...accumulator, [permission]: true}), {})
                    }
                }
            },
            buildPermissionsArray() {
                const { permissions } = this
                return Object.keys(permissions).filter(permission => permissions[permission])
            },
            onViewShiftsCalendarChanged(value) {
                const { PERMISSION_CONSTANTS } = this
                if(!value) {
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.SHIFTS.REQUEST_CANCELLATION]: false
                    }
                }
            },
            onRequestShiftsCancellationChanged(value) {
                const { PERMISSION_CONSTANTS } = this
                if(value) {
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.USERS.VIEW_SHIFTS_PAGE]: true,
                    }
                }
            },
            onOrganizationChanged(value) {
                if(value) {
                    const { PERMISSION_CONSTANTS } = this
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.ORGANIZATION.VIEW]: true,
                        [PERMISSION_CONSTANTS.SETTINGS.VIEW]: true
                    }
                }
            },
            onEditOrganizationChanged(value) {
                if(!value) {
                    const { PERMISSION_CONSTANTS } = this
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.ORGANIZATION.EDIT]: false,
                        [PERMISSION_CONSTANTS.STAFF_TYPES.EDIT]: false,
                        [PERMISSION_CONSTANTS.REQUIREMENTS.EDIT]: false,
                        [PERMISSION_CONSTANTS.SHIFT_TIMES.EDIT]: false,
                        [PERMISSION_CONSTANTS.CUSTOM_FIELDS.EDIT]: false,
                    }
                }
                if(value) {
                    const { PERMISSION_CONSTANTS } = this
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.ORGANIZATION.VIEW]: true,
                    }
                }
            },
            onViewOrganizationChanged(value) {
                if(!value) {
                    const { PERMISSION_CONSTANTS } = this
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.LOCATIONS.VIEW]: false,
                        [PERMISSION_CONSTANTS.LOCATIONS.EDIT]: false,
                        [PERMISSION_CONSTANTS.SETTINGS.VIEW]: false,
                        [PERMISSION_CONSTANTS.STAFF_TYPES.EDIT]: false,
                        [PERMISSION_CONSTANTS.REQUIREMENTS.EDIT]: false,
                        [PERMISSION_CONSTANTS.SHIFT_TIMES.EDIT]: false,
                        [PERMISSION_CONSTANTS.CUSTOM_FIELDS.EDIT]: false,
                        [PERMISSION_CONSTANTS.DEPARTMENTS.VIEW]: false,
                        [PERMISSION_CONSTANTS.STAFF_GROUPS.VIEW]: false,
                    }
                    this.onViewDepartmentsChanged(false)
                    this.onViewStaffGroupsChanged(false)
                }
            },
            onViewOrganizationSettingsChanged(value) {
                if(!value) {
                    const { PERMISSION_CONSTANTS } = this
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.STAFF_TYPES.EDIT]: false,
                        [PERMISSION_CONSTANTS.REQUIREMENTS.EDIT]: false,
                        [PERMISSION_CONSTANTS.SHIFT_TIMES.EDIT]: false,
                        [PERMISSION_CONSTANTS.CUSTOM_FIELDS.EDIT]: false,
                    }
                }
                if(value) {
                    const { PERMISSION_CONSTANTS } = this
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.ORGANIZATION.VIEW]: true
                    }
                }
            },
            onViewLocationChanged(value) {
                if(value) {
                    const { PERMISSION_CONSTANTS } = this
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.ORGANIZATION.VIEW]: true
                    }
                }
                if(!value) {
                    const { PERMISSION_CONSTANTS } = this
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.LOCATIONS.EDIT]: false,
                        [PERMISSION_CONSTANTS.DEPARTMENTS.VIEW]: false,
                        [PERMISSION_CONSTANTS.STAFF_GROUPS.VIEW]: false,
                    }
                    this.onViewDepartmentsChanged(false)
                    this.onViewStaffGroupsChanged(false)
                }
            },
            onEditLocationChanged(value) {
                if(value) {
                    const { PERMISSION_CONSTANTS } = this
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.ORGANIZATION.VIEW]: true,
                        [PERMISSION_CONSTANTS.LOCATIONS.VIEW]: true
                    }
                }
            },
            onViewDepartmentsChanged(value) {
                if(!value) {
                    const { PERMISSION_CONSTANTS } = this
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.DEPARTMENTS.EDIT]: false,
                        [PERMISSION_CONSTANTS.DEPARTMENTS.AUTHORIZE_STAFF_GROUP]: false
                    }
                }
                if(value) {
                    const { PERMISSION_CONSTANTS } = this
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.ORGANIZATION.VIEW]: true,
                        [PERMISSION_CONSTANTS.LOCATIONS.VIEW]: true
                    }
                }
            },
            onEditDepartmentsChanged(value) {
                const { PERMISSION_CONSTANTS } = this
                if(value) {
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.ORGANIZATION.VIEW]: true,
                        [PERMISSION_CONSTANTS.LOCATIONS.VIEW]: true,
                        [PERMISSION_CONSTANTS.DEPARTMENTS.VIEW]: true
                    }
                } else {
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.DEPARTMENTS.AUTHORIZE_STAFF_GROUP]: false
                    }
                }
            },
            onEditStaffingDetails(value) {
                if(value) {
                    const { PERMISSION_CONSTANTS } = this
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.ORGANIZATION.VIEW]: true,
                        [PERMISSION_CONSTANTS.LOCATIONS.VIEW]: true,
                        [PERMISSION_CONSTANTS.DEPARTMENTS.VIEW]: true,
                        [PERMISSION_CONSTANTS.DEPARTMENTS.EDIT]: true
                    }
                }
            },
            onViewStaffGroupsChanged(value) {
                if(!value) {
                    const { PERMISSION_CONSTANTS } = this
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.STAFF_GROUPS.EDIT]: false,
                        [PERMISSION_CONSTANTS.JOB_TITLES.EDIT]: false,
                        [PERMISSION_CONSTANTS.STAFF_GROUPS.EDIT_REQUIREMENTS]: false,
                        // [PERMISSION_CONSTANTS.STAFF_GROUPS.EDIT_SHIFT_TIMES]: false,
                    }
                }
                if(value) {
                    const { PERMISSION_CONSTANTS } = this
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.ORGANIZATION.VIEW]: true,
                        [PERMISSION_CONSTANTS.LOCATIONS.VIEW]: true,
                        [PERMISSION_CONSTANTS.ORGANIZATION.VIEW]: true,
                        [PERMISSION_CONSTANTS.LOCATIONS.VIEW]: true
                    }
                }
            },
            onEditStaffGroupsChanged(value) {
                const { PERMISSION_CONSTANTS } = this
                if(value) {
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.ORGANIZATION.VIEW]: true,
                        [PERMISSION_CONSTANTS.LOCATIONS.VIEW]: true,
                        [PERMISSION_CONSTANTS.STAFF_GROUPS.VIEW]: true
                    }
                } else {
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.JOB_TITLES.EDIT]: false,
                        [PERMISSION_CONSTANTS.STAFF_GROUPS.EDIT_REQUIREMENTS]: false,
                        // [PERMISSION_CONSTANTS.STAFF_GROUPS.EDIT_SHIFT_TIMES]: false,
                    }
                }
            },
            onEditRequirementsOrJobTitlesOrShiftTimes(value) {
                if(value) {
                    const { PERMISSION_CONSTANTS } = this
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.ORGANIZATION.VIEW]: true,
                        [PERMISSION_CONSTANTS.LOCATIONS.VIEW]: true,
                        [PERMISSION_CONSTANTS.STAFF_GROUPS.VIEW]: true,
                        [PERMISSION_CONSTANTS.STAFF_GROUPS.EDIT]: true
                    }
                }
            },
            onViewOwnAvailabilityChanged(value) {
                const { PERMISSION_CONSTANTS } = this
                if(!value && this.permissions[PERMISSION_CONSTANTS.AVAILABILITY.EDIT_OWN]) {
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.AVAILABILITY.EDIT_OWN]: false
                    }
                }
            },
            onEditOwnAvailabilityChanged(value) {
                const { PERMISSION_CONSTANTS } = this
                if(value && !this.permissions[PERMISSION_CONSTANTS.AVAILABILITY.VIEW_OWN]) {
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.AVAILABILITY.VIEW_OWN]: true
                    }
                }
            },
            onImpersonateUsersChanged(value) {
                const { PERMISSION_CONSTANTS } = this
                if(value && !this.permissions[PERMISSION_CONSTANTS.USERS.VIEW_USERS_PAGE]) {
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.USERS.VIEW_USERS_PAGE]: true
                    }
                }
            },
            onViewOthersProfile(value) {
                const { PERMISSION_CONSTANTS } = this
                if(value && !this.permissions[PERMISSION_CONSTANTS.USERS.VIEW_USERS_PAGE]) {
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.USERS.VIEW_USERS_PAGE]: true
                    }
                }

                if (!value && this.permissions[PERMISSION_CONSTANTS.USERS.EDIT_OTHERS_PROFILE]) {
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.USERS.EDIT_OTHERS_PROFILE]: false,
                    }
                }
            },
            onViewOthersJobs(value) {
                const { PERMISSION_CONSTANTS } = this
                if(value && !this.permissions[PERMISSION_CONSTANTS.USERS.VIEW_USERS_PAGE]) {
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.USERS.VIEW_USERS_PAGE]: true
                    }
                }

                if (this.permissions[PERMISSION_CONSTANTS.USERS.EDIT_OTHERS_JOBS]) {
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.USERS.EDIT_OTHERS_JOBS]: false,
                    }
                }
            },
            onViewOthersPermissions(value) {
                const { PERMISSION_CONSTANTS } = this
                if(value && !this.permissions[PERMISSION_CONSTANTS.USERS.VIEW_USERS_PAGE]) {
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.USERS.VIEW_USERS_PAGE]: true
                    }
                }

                if (this.permissions[PERMISSION_CONSTANTS.USERS.EDIT_OTHERS_PERMISSIONS]) {
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.USERS.EDIT_OTHERS_PERMISSIONS]: false,
                    }
                }
            },
            onViewOthersAvailability(value) {
                const { PERMISSION_CONSTANTS } = this
                if(value && !this.permissions[PERMISSION_CONSTANTS.USERS.VIEW_USERS_PAGE]) {
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.USERS.VIEW_USERS_PAGE]: true
                    }
                }

                if (this.permissions[PERMISSION_CONSTANTS.AVAILABILITY.EDIT_OTHERS]) {
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.AVAILABILITY.EDIT_OTHERS]: false,
                    }
                }
            },
            onViewUsersChanged(value) {
                const { PERMISSION_CONSTANTS } = this
                if(value) {
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.USERS.VIEW_USERS_PAGE]: true
                    }
                } else {
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.USERS.IMPERSONATE]: false,
                        [PERMISSION_CONSTANTS.USERS.VIEW_OTHERS_PROFILE]: false,
                        [PERMISSION_CONSTANTS.USERS.EDIT_OTHERS_PROFILE]: false,
                        [PERMISSION_CONSTANTS.USERS.VIEW_OTHERS_JOBS]: false,
                        [PERMISSION_CONSTANTS.USERS.EDIT_OTHERS_JOBS]: false,
                        [PERMISSION_CONSTANTS.USERS.VIEW_OTHERS_PERMISSIONS]: false,
                        [PERMISSION_CONSTANTS.USERS.EDIT_OTHERS_PERMISSIONS]: false,
                        [PERMISSION_CONSTANTS.AVAILABILITY.VIEW_OTHERS]: false,
                        [PERMISSION_CONSTANTS.AVAILABILITY.EDIT_OTHERS]: false,
                    }
                }
            },
            onViewSchedulesPageChanged(value) {
                const { PERMISSION_CONSTANTS } = this
                if(value) {
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.USERS.VIEW_SCHEDULES_PAGE]: true
                    }
                } else {
                    this.permissions = {
                        ...this.permissions,
                        ...!this.permissions[PERMISSION_CONSTANTS.USERS.VIEW_EXPANDED_SCHEDULES] && { [PERMISSION_CONSTANTS.USERS.VIEW_EXPANDED_SCHEDULES]: false },
                        [PERMISSION_CONSTANTS.REQUEST_FORMS.EDIT]: false,
                        [PERMISSION_CONSTANTS.REQUEST_FORMS.ADD_HISTORICAL]: false,
                        [PERMISSION_CONSTANTS.SHIFT_OFFERS.SEND]: false,
                        [PERMISSION_CONSTANTS.SHIFTS.ASSIGN]: false,
                        [PERMISSION_CONSTANTS.SHIFTS.HISTORICAL_ASSIGN]: false,
                        [PERMISSION_CONSTANTS.SHIFTS.UNASSIGN]: false,
                        [PERMISSION_CONSTANTS.SHIFTS.DIRECT_ASSIGN]: false,
                        [PERMISSION_CONSTANTS.SHIFTS.HANDLE_CANCELLATION]: false,
                    }
                }
            },
            onEditStaffingAutomationsChanged(value) {
                const { PERMISSION_CONSTANTS } = this
                if (value) {
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.STAFFING_AUTOMATION.VIEW]: true
                    }
                }
            },
            onViewReportsChanged(value) {
                if(!value) {
                    const { PERMISSION_CONSTANTS } = this
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.REPORTS.GENERATE]: false,
                        [PERMISSION_CONSTANTS.REPORT_PRESETS.EDIT]: false
                    }
                }
            },
            onGenerateReportsOrEditPresetsChanged(value) {
                const { PERMISSION_CONSTANTS } = this
                if(value && !this.permissions[PERMISSION_CONSTANTS.REPORTS.VIEW]) {
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.REPORTS.VIEW]: true
                    }
                }
            },
            onEditOthersProfileChanged(value) {
                const { PERMISSION_CONSTANTS } = this
                if(value && (!this.permissions[PERMISSION_CONSTANTS.USERS.VIEW_USERS_PAGE] || !this.permissions[PERMISSION_CONSTANTS.USERS.VIEW_OTHERS_PROFILE])) {
                    this.permissions = {
                        ...this.permissions,
                        ...!this.permissions[PERMISSION_CONSTANTS.USERS.VIEW_USERS_PAGE] && { [PERMISSION_CONSTANTS.USERS.VIEW_USERS_PAGE]: true },
                        ...!this.permissions[PERMISSION_CONSTANTS.USERS.VIEW_OTHERS_PROFILE] && { [PERMISSION_CONSTANTS.USERS.VIEW_OTHERS_PROFILE]: true }
                    }
                }
            },
            onEditOthersJobsChanged(value) {
                const { PERMISSION_CONSTANTS } = this
                if(value && (!this.permissions[PERMISSION_CONSTANTS.USERS.VIEW_USERS_PAGE] || !this.permissions[PERMISSION_CONSTANTS.USERS.VIEW_OTHERS_JOBS])) {
                    this.permissions = {
                        ...this.permissions,
                        ...!this.permissions[PERMISSION_CONSTANTS.USERS.VIEW_USERS_PAGE] && { [PERMISSION_CONSTANTS.USERS.VIEW_USERS_PAGE]: true },
                        ...!this.permissions[PERMISSION_CONSTANTS.USERS.VIEW_OTHERS_JOBS] && { [PERMISSION_CONSTANTS.USERS.VIEW_OTHERS_JOBS]: true }
                    }
                }
            },
            onEditOthersPermissionsChanged(value) {
                const { PERMISSION_CONSTANTS } = this
                if(value && (!this.permissions[PERMISSION_CONSTANTS.USERS.VIEW_USERS_PAGE] || !this.permissions[PERMISSION_CONSTANTS.USERS.VIEW_OTHERS_PERMISSIONS])) {
                    this.permissions = {
                        ...this.permissions,
                        ...!this.permissions[PERMISSION_CONSTANTS.USERS.VIEW_USERS_PAGE] && { [PERMISSION_CONSTANTS.USERS.VIEW_USERS_PAGE]: true },
                        ...!this.permissions[PERMISSION_CONSTANTS.USERS.VIEW_OTHERS_PERMISSIONS] && { [PERMISSION_CONSTANTS.USERS.VIEW_OTHERS_PERMISSIONS]: true }
                    }
                }
            },
            onEditOthersAvailabilityChanged(value) {
                const { PERMISSION_CONSTANTS } = this
                if(value && (!this.permissions[PERMISSION_CONSTANTS.USERS.VIEW_USERS_PAGE] || !this.permissions[PERMISSION_CONSTANTS.AVAILABILITY.VIEW_OTHERS])) {
                    this.permissions = {
                        ...this.permissions,
                        ...!this.permissions[PERMISSION_CONSTANTS.USERS.VIEW_USERS_PAGE] && { [PERMISSION_CONSTANTS.USERS.VIEW_USERS_PAGE]: true },
                        ...!this.permissions[PERMISSION_CONSTANTS.AVAILABILITY.VIEW_OTHERS] && { [PERMISSION_CONSTANTS.AVAILABILITY.VIEW_OTHERS]: true }
                    }
                }
            },
            onSchedulesInputChange(value) {
                const { PERMISSION_CONSTANTS } = this
                if(value && !this.permissions[PERMISSION_CONSTANTS.USERS.VIEW_SCHEDULES_PAGE]) {
                    this.permissions = {
                        ...this.permissions,
                        [PERMISSION_CONSTANTS.USERS.VIEW_SCHEDULES_PAGE]: true
                    }
                }
            },
            isDirty() {
                return !_.isEqual(this.permissions, this.prevPermissions)
            }
        },
        computed: {
            cannotEdit() {
                const PERMISSIONS = this.$config.permissions
                return !this.$userHasPermission(this.$user, PERMISSIONS.USERS.EDIT_OTHERS_PERMISSIONS)
            },
            filteredTabs() {
                const { user, roles } = this
                const { role_id } = user
                const role = roles.find(r => r._id === role_id)
                const { available_permissions } = role
                const available_permissions_lookup = available_permissions.reduce((permissions, permission) => ({
                    ...permissions,
                    [permission]: true
                }), {})
                const {
                    $userIsAdministrator,
                    $userIsManager,
                    $userIsStaff,
                    allowedSections
                } = this
                const filteredByTab = Object.keys(this.tabs).reduce((accumulator, tab) => {
                    const { name } = this.tabs[tab]

                    if (name === 'Organization') {
                        if($userIsAdministrator(user)) {
                            return {
                                ...accumulator,
                                [tab]: this.tabs[tab]
                            }
                        }
                    } else if(name === 'Users') {
                        if($userIsAdministrator(user) || $userIsManager(user)) {
                            return {
                                ...accumulator,
                                [tab]: this.tabs[tab]
                            }
                        }
                        return accumulator
                    } else if(name === 'Shifts') {
                        if($userIsStaff(user)) {
                            return {
                                ...accumulator,
                                [tab]: this.tabs[tab]
                            }
                        }
                        return accumulator
                    } else if(name === 'Reports') {
                        if($userIsAdministrator(user) || $userIsManager(user)) {
                            return {
                                ...accumulator,
                                [tab]: this.tabs[tab]
                            }
                        }
                        return accumulator
                    }  else if(name === 'Scheduling') {
                        if(!$userIsStaff(user)) {
                            return {
                                ...accumulator,
                                [tab]: this.tabs[tab]
                            }
                        }
                        return accumulator
                    } else {
                        return {
                            ...accumulator,
                            [tab]: this.tabs[tab]
                        }
                    }
                }, {})
                return Object.keys(filteredByTab).reduce((accumulator, tab) => {
                   return {
                       ...accumulator,
                       [tab]: {
                           ...this.tabs[tab],
                           permissions: Object.keys(this.tabs[tab].permissions).reduce((accumulator, key) => {
                               const permission = this.tabs[tab].permissions[key]
                               if(permission.type === 'permission') {
                                   if(available_permissions_lookup.hasOwnProperty(permission.name)) {
                                       return {
                                           ...accumulator,
                                           [key]: permission
                                       }
                                   }
                                   return accumulator
                               } else if(permission.type === 'section') {
                                   const { identifier } = permission
                                   if($userIsAdministrator(user)) {
                                       if(allowedSections.administrator.hasOwnProperty(identifier)) {
                                           return {
                                               ...accumulator,
                                               [key]: permission
                                           }
                                       }
                                       return accumulator
                                   } else if($userIsManager(user)) {
                                       if(allowedSections.manager.hasOwnProperty(identifier)) {
                                           return {
                                               ...accumulator,
                                               [key]: permission
                                           }
                                       }
                                       return accumulator
                                   } else if($userIsStaff(user)) {
                                       if(allowedSections.staff.hasOwnProperty(identifier)) {
                                           return {
                                               ...accumulator,
                                               [key]: permission
                                           }
                                       }
                                       return accumulator
                                   } else {
                                       return accumulator
                                   }
                               } else {
                                   return accumulator
                               }
                           }, {})
                       },
                   }
                }, {})
            }
        },
        watch: {
            user() {
                this.prevPermissions = _.cloneDeep(this.permissions)
            }
        }
    }
</script>

<style scoped>
    .v-tab-item-container {
        padding-left: 15px;
    }
    .v-tab-item-content-container {
        margin: 0;
    }
    .main-divider {
        border: 2px solid rgb(27, 20, 100);
        background-color: rgb(27, 20, 100);
    }
</style>
