<template>
    <stat icon="mdi-calendar" :value="count"/>
</template>

<script>
import Stat from "./Stat";

export default {
    name: "ShiftsCount",
    components: {Stat},
    props: {
        count: {
            type: Number,
            default: 0
        }
    }
}
</script>

<style scoped>

</style>
