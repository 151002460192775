<template>
    <v-dialog
        v-model="modalValue"
        scrollable
        persistent
        :max-width="maxWidth"
    >
        <v-card :elevation="isInPast ? 2 : 0">
            <v-toolbar class="informational-modal" elevation="0">
                <v-toolbar-title class="text--h2">
                    Add Staff
                </v-toolbar-title>
                <template v-if="isInPast">
                    <v-spacer></v-spacer>
                    <v-btn v-if="entity_id" icon @click="copy_entity_id" title="Copy Shift Request ID">
                        <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                    <input type="hidden" :value="entity_id" :id="'id-to-copy-' + entity_id">
                </template>
            </v-toolbar>
            <v-card-text :style="contentStyle">
                <staff-scheduler
                    :request-details="requestDetails"
                    :auth-staffing-details="authStaffingDetails"
                    :preselected-values="preselectedValues"
                    :is-in-past="isInPast"
                    :is-shift-starting-soon="isShiftStartInXHours"
                    :amount-requested="amountRequested"
                    @onEditUser="onEditUser"
                    @onRemoveUser="onRemoveUser"
                ></staff-scheduler>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="error"
                    text
                    @click="onDelete"
                    :disabled="isDeleteDisabled || isInPast"
                    :loading="isDeleting"
                >Delete Shift</v-btn>
                <div class="ml-4"></div>
                <v-btn
                    v-if="isSingleShift"
                    color="primary"
                    outlined
                    @click="onEditShift({ value: 'single' })"
                    :disabled="isEditDisabled || isInPast"
                    :loading="isEditing"
                >
                    Edit Shift
                </v-btn>
                <options-select
                    v-else-if="!isSingleShift"
                    title="Edit Shift"
                    color="primary"
                    outlined
                    :show-icon="false"
                    :items="shiftTypes"
                    :disabled="isEditDisabled || isInPast"
                    :loading="isEditing"
                    @onOptionChanged="onEditShift"
                ></options-select>
                <div class="ml-4"></div>
                <!-- TODO: This does not follow the rules, hidden when not available... -->
                <v-btn
                    v-if="hasAssignHistoricalPermission && isInPast"
                    color="primary"
                    outlined
                    @click="historicUserAssignModal"
                    :disabled="isEditDisabled || fullShift || isShiftOlderThan14Days"
                    :loading="isEditing"
                >
                    Add Staff
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    :outlined="isInPast"
                    :text="!isInPast"
                    @click="onCloseModal"
                    :disabled="isSaving || isDeleting"
                >
                    Close
                </v-btn>
                <v-btn
                    color="secondary"
                    @click="onSave"
                    :loading="isSaving"
                    :disabled="isSaveDisabled || isInPast"
                >Save</v-btn>
            </v-card-actions>
        </v-card>
        <delete-shift-modal
            v-model="deleteShiftModalValue"
            :recurrent="recurrent"
            :is-from-staffing-automation="isFromStaffingAutomation"
            :date="currentDate"
            @onDelete="deleteShift"
        ></delete-shift-modal>
        <unsaved-scheduling-changes-modal v-model="unsavedChangesModalValue" @onConfirm="onDiscardChanges"></unsaved-scheduling-changes-modal>
        <remove-user-modal
            v-model="removeUserModalValue"
            :user-id="removeUserModalUserId"
            :shift-request-id="selectedShiftRequestId"
            @onCancel="onRemoveUserCancel"
            @onConfirm="onRemoveUserConfirm"
        ></remove-user-modal>
        <historical-assign-modal
            v-model="showHistoricalAssignModal"
            :amount-requested="amountRequested"
            :shift-request-id="selectedShiftRequestId"
            :is-in-past="isInPast"
            :auth-is-authorized="authIsAuthorized"
            @onConfirm="onHistoricAssignUserConfirm"
            @onCancel="closeHistoricAssignModal"
        ></historical-assign-modal>
    </v-dialog>
</template>

<script>
import StaffScheduler from "../Misc/StaffScheduler";
import DeleteShiftModal from "./DeleteShiftModal";
import UnsavedSchedulingChangesModal from "./UnsavedSchedulingChangesModal";
import SchedulingOptionsModal from "./SchedulingOptionsModal";
import RemoveUserModal from "./RemoveUserModal";
import OptionsSelect from "../../reporting/FormInputs/OptionsSelect";
import message from '../../../lib/toast';
import HistoricalAssignModal from './HistoricalAssignModal';

export default {
    name: "SchedulingModal",
    components: { StaffScheduler, DeleteShiftModal, OptionsSelect, UnsavedSchedulingChangesModal, SchedulingOptionsModal, RemoveUserModal, HistoricalAssignModal },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        requestFormIdsByDay: {
            type: Array,
            default: () => [],
        },
        requestDetails: {
            type: Object,
            default: () => ({})
        },
        authStaffingDetails: {
            type: Array,
            default: function() {
                return []
            }
        },
        preselectedValues: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        isSaving: false,
        isEditing: false,
        isDeleting: false,
        deleteShiftModalValue: false,
        unsavedChangesModalValue: false,
        removeUserModalValue: false,
        removeUserModalUserId: null,
        showHistoricalAssignModal: false,
        shiftTypes: [
            { text: 'This and Following Shifts', value: 'repeating' },
            { text: 'This Shift', value: 'single' },
        ],
        api: new formHelper()
    }),
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        selectedView () {
            return this.$store.getters.getSelectedView
        },
        selectedDate () {
            return this.$store.getters.getSelectedDate
        },
        datesById () {
            return this.$store.getters.getDatesById
        },
        assignableView () {
            return this.$store.getters.getIsAssignableView
        },
        changesMade: {
            get () {
                return this.$store.getters.getChangesMade
            },
            set (changesMade) {
                this.$store.dispatch('setChangesMade', { changesMade })
            }
        },
        selectedRequestFormId () {
            return this.$store.getters.getSelectedRequestFormId
        },
        selectedShiftRequestId () {
            return this.$store.getters.getSelectedShiftRequestId
        },
        entity_id () {
            if (this.selectedShiftRequestId) {
                return this.selectedShiftRequestId
            }
            return false
        },
        toBeAssignedIds () {
            return this.$store.getters.getToBeAssignedIds
        },
        toBeUnassignedIds () {
            return this.$store.getters.getToBeUnassignedIds
        },
        toBeOfferedIds () {
            return this.$store.getters.getToBeOfferedIds
        },
        requestFormDetailsLoading () {
            return this.$store.getters.getIsRequestFormDetailsLoading
        },
        listsLoading () {
            return this.$store.getters.getUsersLoading
        },
        actionableUsers () {
            return this.$store.getters.getActionableUsers
        },
        shiftRequest () {
            const { requestForm, selectedShiftRequestId } = this
            if (requestForm && selectedShiftRequestId) {
                return requestForm.shift_requests.find(req => req._id === this.selectedShiftRequestId)
            }
            return null
        },
        amountRequested () {
            const { shiftRequest } = this
            if (shiftRequest) {
                return shiftRequest.amount_requested
            }
            return null
        },
        filledShiftsCount () {
            return this.$store.getters.getAssignedCount
        },
        totalShiftsCount () {
            if (this.amountRequested) {
                return this.amountRequested
            }
            return 0
        },
        fullShift () {
            return this.amountRequested <= this.filledShiftsCount;
        },
        availableShiftCount () {
            return this.amountRequested - this.filledShiftsCount;
        },
        innerHeight() {
            return window.innerHeight
        },
        contentStyle() {
            return {
                height: `${this.innerHeight}px`
            }
        },
        requestForm () {
            return this.$store.getters.getRequestForm
        },
        currentDate () {
            if (this.datesById && this.datesById.hasOwnProperty('byMoment') && this.datesById['byMoment'].hasOwnProperty(this.selectedRequestFormId)) {
                return this.datesById['byMoment'][this.selectedRequestFormId].format('LL')
            }
            return null
        },
        currentMoment () {
            if (this.datesById && this.datesById.hasOwnProperty('byMoment') && this.datesById['byMoment'].hasOwnProperty(this.selectedRequestFormId)) {
                return this.datesById['byMoment'][this.selectedRequestFormId]
            }
            return null
        },
        recurrent () {
            const details = this.requestDetails || {}
            return !!details.request_form_group
        },
        isFromStaffingAutomation() {
            return Boolean(this.requestForm?.staffing_automation_id)
        },
        isDeleteDisabled() {
            const apiBusy = this.requestFormDetailsLoading || this.listsLoading || this.isEditing || this.isSaving;
            if (apiBusy) {
                return true;
            }
            const { requestForm } = this;
            if (!requestForm) {
                return true;
            }
            const { authIsAuthorized } = this;
            if (!authIsAuthorized) {
                return true;
            }

            if (requestForm) {
                if (this.$authIsManager || this.$authIsAdministrator) {
                    return !this.$userHasPermission(this.$user, this.$config.permissions.SHIFTS.UNASSIGN) ||
                    !this.$userHasPermission(this.$user, this.$config.permissions.REQUEST_FORMS.EDIT)
                }
            }

        },
        isEditDisabled () {
            const apiBusy = this.requestFormDetailsLoading || this.listsLoading || this.isDeleting || this.isSaving
            if (apiBusy) {
                return true
            }
            const { requestForm } = this;
            if (!requestForm) {
                return true;
            }

            const { authIsAuthorized } = this;
            if (!authIsAuthorized) {
                return true;
            }

            if (requestForm) {
                if (this.$authIsManager || this.$authIsAdministrator) {
                    return !this.$userHasPermission(this.$user, this.$config.permissions.REQUEST_FORMS.EDIT)
                }
            }
        },
        isSaveDisabled () {
            const apiBusy = this.requestFormDetailsLoading || this.listsLoading || this.isDeleting || this.isEditing
            if (apiBusy) {
                return true
            }
            const { requestForm } = this;
            if (!requestForm) {
                return true;
            }

            const { authIsAuthorized } = this;
            if (!authIsAuthorized) {
                return true;
            }

            const { isDirty } = this;
            if (!isDirty) {
                return true;
            }

            if (requestForm && (this.$authIsManager || this.$authIsAdministrator)) {
                return !this.$userHasPermission(this.$user, this.$config.permissions.SHIFTS.ASSIGN) &&
                       !this.$userHasPermission(this.$user, this.$config.permissions.SHIFT_OFFERS.SEND) 
                    //    && !this.$userHasPermission(this.$user, this.$config.permissions.SHIFTS.DIRECT_ASSIGN);
            }
        },
        // TODO: Control Admin via permissions but stil loading all for admins?
        authIsAuthorized () {
            if (this.$authIsOwner || this.$authIsAdministrator) {
                return true
            }

            if (!this.authStaffingDetails || !this.requestForm) {
                return false
            }


            const { staff_group_id, department_id } = this.requestForm

            return this.authStaffingDetails.some(staffing_detail => {
                return staffing_detail.staff_group_id === staff_group_id &&
                    staffing_detail.department_id === department_id
            })
        },
        globalTimezone() {
            return this.$root.globalTimezone
        },
        hasAssignHistoricalPermission() {
            if (this.$authIsOwner) {
                return true;
            }
            if (this.$authIsAdministrator || this.$authIsManager) {
                return this.$userHasPermission(this.$user, this.$config.permissions.SHIFTS.HISTORICAL_ASSIGN);
            }
            return false;
        },
        isInPast () {
            const { currentMoment } = this
            if (currentMoment) {
                return currentMoment.isSameOrBefore(moment())
            }
            return false
        },
        isShiftStartInXHours() {
            const { currentMoment } = this;
            if (currentMoment) {
                const twentyFourHoursFromNow = moment().add(24, 'hours');
                const result = currentMoment.isBefore(twentyFourHoursFromNow);
                return result
            }
            return false;
        },
        isShiftOlderThan14Days () {
            const { currentMoment } = this
            if (currentMoment) {
                const fourteenDaysAgo = moment().subtract(14, 'days');
                return currentMoment.isSameOrBefore(fourteenDaysAgo);
            }
            return false;
        },
        maxWidth () {
            if (this.isInPast) {
                return '900px'
            }
            return '1300px'
        },
        isSingleShift () {
            if (this.requestDetails) {
                return !this.requestDetails.request_form_group
            }
            return false
        },
        isDirty () {
            const { selectedView, toBeAssignedIds, toBeOfferedIds } = this
            let isDirty = false
            if (selectedView === 'all') {
                isDirty = (Array.isArray(toBeAssignedIds) && toBeAssignedIds.length > 0) || (Array.isArray(toBeOfferedIds) && toBeOfferedIds.length > 0)
            } else if (selectedView === 'assign') {
                isDirty = Array.isArray(toBeAssignedIds) && toBeAssignedIds.length > 0
            } else if (selectedView === 'offer') {
                isDirty = (Array.isArray(toBeOfferedIds) && toBeOfferedIds.length > 0)
            }
            return isDirty
        }
    },
    methods: {
        onSave() {
            const { selectedView, toBeAssignedIds, toBeOfferedIds, selectedShiftRequestId } = this
            if (!Array.isArray(toBeAssignedIds) || !Array.isArray(toBeOfferedIds) || !selectedShiftRequestId) {
                this.$snackNotify('warning', 'No changes have been made.')
                return
            }
            if (selectedView === 'all') {
                if (toBeAssignedIds.length <= 0 && toBeOfferedIds.length <= 0) {
                    this.$snackNotify('warning', 'No changes have been made.')
                    return
                }
            } else if (selectedView === 'assign') {
                if (toBeAssignedIds.length <= 0) {
                    this.$snackNotify('warning', 'No changes have been made.')
                    return
                }
            } else if (selectedView === 'offer') {
                if (toBeOfferedIds.length <= 0) {
                    this.$snackNotify('warning', 'No changes have been made.')
                    return
                }
            }
            if (this.shiftRequest && toBeAssignedIds.length > 0 && toBeOfferedIds.length > 0) {
                const { filledShiftsCount, totalShiftsCount } = this
                if (filledShiftsCount + toBeAssignedIds.length >= totalShiftsCount) {
                    message('warning', 'Unable to offer shift. You have selected more Staff Members than open shifts.')
                    this.dispatchSetActionableUsers(
                        this.actionableUsers.map(user => {
                            if (user.eligible) {
                                return {
                                    ...user,
                                    selected: false,
                                    disabled: true,
                                }
                            }
                            return user
                        })
                    )
                    return
                }
            }
            this.bulkAssignOffer(this.selectedShiftRequestId, this.toBeAssignedIds, this.toBeOfferedIds)
        },
        onEditShift (shiftType) {
            if (this.requestForm && this.requestForm._id) {
                this.isEditing = true
                this.api.get(`/request-forms/details/${this.requestForm._id}`)
                    .then(data => {
                        if (data && data.data) {
                            const requestForm = data.data
                            const type = shiftType.value === 'single' || shiftType.value === 'group' ? shiftType.value : this.requestDetails?.request_form_group?.recurrence_settings ? 'repeating' : 'multiple'
                            this.$emit('onEditShift', {
                                ...requestForm,
                                ...requestForm.request_form_group && {
                                    requestFormIndex: Array.isArray(this.datesById.values) ?
                                        this.datesById.values.findIndex(({ request_form_id }) => request_form_id === requestForm._id) : -1
                                },
                                selected_start_date: this.selectedDate,
                            }, type, this.isFromStaffingAutomation)
                            this.closeModal()
                        }
                    })
                    .catch(console.log)
                    .finally(() => {
                        this.isEditing = false
                    })
            }
        },
        onDelete () {
            this.deleteShiftModalValue = true
        },
        deleteShift (recurrent, group) {
            this.isDeleting = true
            this.api.post('/request-forms/delete-many', {
                request_form_id: this.selectedRequestFormId,
                delete_recurring: this.recurrent ? recurrent : false,
                ...(group && { delete_all_automation_shifts: true })
            }).then(({ data }) => {
                if (data && data.success === true) {
                    this.$snackNotify('success', 'Successfully removed Shift(s).')
                    this.changesMade = true
                } else {
                    this.$snackNotify('success', 'Error removing Shift(s).')
                }
                this.closeModal()
                this.$emit('onDelete')
            }).catch(console.log).finally(() => {
                this.isDeleting = false
            })
        },
        onDiscardChanges () {
            this.closeModal()
        },
        bulkAssignOffer (requestId, toBeAssignedIds, toBeOfferedIds) {
            this.isSaving = true
            this.api.post(`/shift-request/bulk-assign-offer/${requestId}`, {
                user_ids_to_assign: toBeAssignedIds,
                user_ids_to_offer_to: toBeOfferedIds,
            }).then(({ data, message }) => new Promise((resolve, reject) => {
                if (data) {
                    const { assign_statuses, offer_statuses } = data
                    const assignError = this.getError(
                        assign_statuses,
                        'Unable to assign shift. Please try again.')
                    const offerError = this.getError(
                        offer_statuses,
                        'Unable to send shift offers. Please try again.')
                    if (assignError) {
                        reject({
                            customMessage: assignError
                        })
                    } else if (offerError) {
                        reject({
                            customMessage: offerError
                        })
                    } else {
                        this.$snackNotify('success', 'Staff Members assigned and shift offers sent.')
                        this.reloadLists()
                        resolve()
                    }
                } else {
                    this.$snackNotify('success', 'Staff Members assigned and shift offers sent.')
                    this.reloadLists()
                    resolve()
                }
            })).catch(error => {
                if (error && error.customMessage) {
                    this.$snackNotify('success', error.customMessage)
                }
            }).finally(() => { this.isSaving = false })
        },
        getError (status, defaultError) {
            if (status) {
                if (typeof status === 'object' && status.hasOwnProperty('success')) {
                    if (!status.success) {
                        if (status.error) {
                            return status.error
                        } else {
                            return defaultError
                        }
                    }
                    return null
                }
                return null
            }
            return null
        },
        onCloseModal () {
            if (this.isDirty) {
                this.unsavedChangesModalValue = true
            } else {
                this.closeModal()
            }
        },
        onEditUser (shiftId) {
            this.$emit('onEditUser', shiftId, this.selectedShiftRequestId)
            this.closeModal()
        },
        onRemoveUser (userId) {
            this.removeUserModalValue = true
            this.removeUserModalUserId = userId
        },
        onRemoveUserCancel () {
            this.removeUserModalValue = false
            this.removeUserModalUserId = null
        },
        onRemoveUserConfirm () {
            this.removeUserModalValue = false
            this.removeUserModalUserId = null
            this.reloadLists()
            this.$emit('onUnassignShift')
        },
        historicUserAssignModal () {
            this.showHistoricalAssignModal = true
        },
        onHistoricAssignUserConfirm () {
            this.reloadLists()
            this.showHistoricalAssignModal = false
            this.$snackNotify('success', 'Staff Members assigned to historic shift.')
            this.$emit('fillHistoricalShift')
        },
        closeHistoricAssignModal() {
            this.showHistoricalAssignModal = false
        },
        closeModal () {
            this.modalValue = false
            this.dispatchClearAll()
            this.$emit('onCloseModal')
        },
        reloadLists () {
            this.$emit('onSave')
            this.changesMade = true
            const id = this.selectedShiftRequestId
            this.dispatchSetSelectedShiftRequestId(null)
            this.$nextTick(() => {
                this.dispatchSetSelectedShiftRequestId(id)
            })
        },
        genMoment (date) {
            if (this.globalTimezone) {
                return moment(date).tz(this.globalTimezone)
            }
            return moment(date)
        },
        dispatchClearAll () {
            this.$store.dispatch('clearAll')
        },
        dispatchSetSelectedShiftRequestId (selectedShiftRequestId) {
            this.$store.dispatch('setSelectedShiftRequestId', { selectedShiftRequestId })
        },
        dispatchSetDatesById (datesById, preselectedValues) {
            this.$store.dispatch('setDatesById', { datesById, preselectedValues })
        },
        dispatchSetActionableUsers (actionableUsers) {
            this.$store.dispatch('setActionableUsers', { actionableUsers })
        },
        copy_entity_id() {
            if (typeof this.selectedShiftRequestId === 'undefined') {
                return false
            }

            let copy_field = document.querySelector('#id-to-copy-' + this.selectedShiftRequestId)
            copy_field.setAttribute('type', 'text')
            copy_field.select()

            try {
                let success = document.execCommand('copy')
                if (success) {
                    this.$snackNotify('success', `${this.selectedShiftRequestId} copied to your clipboard.`)
                }
            } catch (err) {
                this.$snackNotify('warning', 'Unable to copy shift ID. Please try again.')
            }

            copy_field.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges()
        },
    },
    watch: {
        requestFormIdsByDay: {
            immediate: true,
            handler (requestFormIdsByDay) {
                if (Array.isArray(requestFormIdsByDay)) {
                    this.dispatchSetDatesById(requestFormIdsByDay.map(({ request_form_id, date}) => ({
                        request_form_id,
                        date: this.genMoment(date),
                    })), this.preselectedValues)
                }
            }
        },
        requestFormDetailsLoading(requestFormDetailsLoading) {
            if (!requestFormDetailsLoading && this.isFromStaffingAutomation) {
                if (!this.shiftTypes.some(shiftType => shiftType.value === 'group')) {
                    const shiftTypes = Array.from(this.shiftTypes)
                    shiftTypes.splice(1, 0, { text: 'This Shift Group', value: 'group' })
                    this.shiftTypes = Array.from(shiftTypes)
                }
            }
        }
    }
}
</script>

<style scoped>
</style>
