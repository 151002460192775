<template>
    <v-dialog
        v-model="modalValue"
        scrollable
        persistent
        max-width="600px"
    >
        <v-card>
            <v-toolbar class="informational-modal" elevation="0">
                <v-toolbar-title class="text--h2">Auto-Assign Published</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <div class="text--paragraph mt-6 mb-5">
                    Shift assignments have been submitted and notifications sent to Staff Members and associated
                    Managers.
                </div>
                <div class="text--paragraph mb-2">
                    Auto-Assign Summary:
                </div>
                <div class="ml-5 mb-2">
                    <div class="text--paragraph mb-2">
                        Date Range: <b>{{ start }} - {{ end }}</b>
                    </div>
                    <div class="text--paragraph mb-2">
                        Staff Group: <b>{{ staffGroup }}</b>
                    </div>
                    <div class="text--paragraph mb-2">
                        Job Title: <b>{{ jobTitle }}</b>
                    </div>
                    <div class="text--paragraph mb-2">
                        Shifts Assigned: <b>{{ assignedCount }}</b>
                    </div>
                    <div :class="openShiftsClass">
                        Open Shifts: <b>{{ openCount }}</b>
                    </div>
                    <div class="text--paragraph mb-2 d-flex flex-row justify-space-between align-center">
                        <div>
                            Failed to Assign: <b>{{ failedCount }}</b>
                        </div>
                        <v-btn
                            v-if="failedAssignments.length > 0"
                            icon
                            color="black"
                            style="width: 24px; height: 24px;"
                            @click="viewFailedAssignments = !viewFailedAssignments"
                        >
                            <v-icon dark>{{ viewFailedAssignments ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                    </div>
                    <div v-if="viewFailedAssignments">
                        <shift-card
                            v-for="(shift, index) in failedAssignments"
                            :key="index"
                            :shift="shift"/>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="modalValue = false"
                >
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ShiftCard from "./ShiftCard";

export default {
    name: "AutoAssignPublishedModal",
    components: {ShiftCard},
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        autoScheduleDraft: {
            type: Object,
            default: null
        }
    },
    data: () => ({
        viewFailedAssignments: false,
    }),
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        start() {
            if (this.autoScheduleDraft) {
                return moment(this.autoScheduleDraft.schedule_start).tz(this.autoScheduleDraft.timezone).format('MM/DD/YYYY')
            }
            return null
        },
        end() {
            if (this.autoScheduleDraft) {
                return moment(this.autoScheduleDraft.schedule_end).tz(this.autoScheduleDraft.timezone).format('MM/DD/YYYY')
            }
            return null
        },
        staffGroup() {
            return this.autoScheduleDraft?.staff_group?.name || ''
        },
        jobTitle() {
            return this.autoScheduleDraft?.job_title?.title || ''
        },
        assignedCount() {
            return this.autoScheduleDraft?.publish_results?.assigned_count || 0
        },
        openCount() {
            return this.autoScheduleDraft?.publish_results?.open_shifts_count || 0
        },
        failedCount() {
            return this.autoScheduleDraft?.publish_results?.failed_count || 0
        },
        failedAssignments() {
            return Array.isArray(this.autoScheduleDraft?.publish_results?.failed_assignments) ? this.autoScheduleDraft?.publish_results?.failed_assignments : []
        },
        openShiftsClass() {
            return {
                'text--paragraph': true,
                ...this.failedAssignments.length < 1 && {
                    'mb-2': true
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
