<template>
    <div class="d-flex flex-column" style="row-gap: 25px;">
        <div
            v-for="(grouping, groupingIndex) in groupedShifts"
            v-if="grouping.shifts.length > 0"
            :key="groupingIndex"
            class="d-flex flex-column"
            style="row-gap: 10px;"
        >
            <div class="text--h4">
                {{ getHeader(grouping) }}
            </div>
            <div class="d-flex flex-column" style="row-gap: 8px;">
                <shift-card
                    v-for="(shift, index) in grouping.shifts"
                    :key="index"
                    :start="shift.start"
                    :end="shift.end"
                    :shift-time="shift.shift_time"
                    :duration="shift.duration"
                    :timezone="timezone"
                    :deletable="shift.is_draft"
                    @onDelete="onDelete(shift.shift_request_id, groupingIndex)"/>
            </div>
        </div>
    </div>
</template>

<script>
import ShiftsCount from "../Stat/ShiftsCount";
import ShiftCard from "../ShiftCard";

export default {
    name: "GroupedShifts",
    components: {ShiftsCount, ShiftCard},
    props: {
        groupedShifts: {
            type: Array,
            default: () => ([])
        },
        timezone: {
            type: String,
            default: null
        }
    },
    methods: {
        getHeader(grouping) {
            const {start, end} = grouping
            const startDate = moment(start).tz(this.timezone).format('MMM Do')
            const endDate = moment(end).tz(this.timezone).format('Do')
            return `${startDate} - ${endDate}`
        },
        onDelete(shiftRequestId, groupingIndex) {
            this.$emit('onDelete', shiftRequestId, groupingIndex)
        }
    }
}
</script>

<style scoped>

</style>
