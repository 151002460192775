<template>
    <v-dialog
        ref="timePickerDialog"
        v-model="modalValue"
        :return-value.sync="selectedDateRange"
        persistent
        width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-on="on"
                v-bind="attrs"
                label="Date Range"
                :value="dateTextFieldValue"
                outlined
                readonly
                :rules="rulesProp"
                :error-messages="errorMessages"
                :disabled="disabled"
                :clearable="!disabled"
                @click:clear="onClearTime"
                @click="onClick"
            >
                <template v-slot:prepend-inner>
                    <v-icon color="primary">mdi-calendar</v-icon>
                </template>
            </v-text-field>
        </template>
        <v-date-picker
            v-model="selectedDateRange"
            elevation="15"
            locale="en-US"
            color="secondary"
            range
            type="date"
            :title-date-format="getTitleFormat"
            :allowed-dates="allowedDates"
            full-width
        >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="onClosePicker"
            >
                Cancel
            </v-btn>
            <v-btn
                outlined
                color="primary"
                @click="onConfirmTime"
            >
                OK
            </v-btn>
        </v-date-picker>
    </v-dialog>
</template>

<script>
import validationRules from '../../../../lib/mixins/validationRules'

export default {
    name: "DateRangePicker",
    mixins: [validationRules],
    props: {
        value: {
            type: Array,
            default: () => ([])
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            modalValue: false,
            selectedDateRange: Array.isArray(this.value) && this.value.length === 2 ? this.value : [],
            errorMessages: [],
            shouldValidate: true,
            datePickerProps: {
                range: true,
                type: 'date',
            },
        }
    },
    computed: {
        dateRange() {
            const {selectedDateRange} = this
            if (Array.isArray(selectedDateRange) && selectedDateRange.length > 1) {
                const selectedDateCopy = Array.from(selectedDateRange)
                return this.sortDates(selectedDateCopy, 'YYYY-MM-DD')
            } else {
                return []
            }
        },
        dateTextFieldValue() {
            const {selectedDateRange} = this
            const date = Array.isArray(selectedDateRange) ? selectedDateRange.map(this.transformDate) : null
            return Array.isArray(date) && date.length > 0 ? this.sortDates(date, 'MM/DD/YYYY').join(' - ') : ''
        },
        rulesProp() {
            if (this.shouldValidate) {
                return [
                    () => {
                        if (Array.isArray(this.selectedDateRange) && this.selectedDateRange.length === 2) {
                            return true
                        }
                        return 'Required'
                    }
                ]
            }
            return []
        },
        allowedDates() {
            return date => {
                const now = this.genMoment(moment().format('YYYY-MM-DD'))
                const oneYearOut = now.clone().add(1, 'years')
                const calendarDate = this.genMoment(date)
                const min = calendarDate.isSameOrAfter(now.clone().add(1, 'days'), 'days')
                let max = calendarDate.isSameOrBefore(oneYearOut, 'days')
                if (this.selectedDateRange.length === 1) {
                    const selectedDate = this.genMoment(this.selectedDateRange[0])
                    const threeMonthsOut = selectedDate.clone().add(3, 'months')
                    if (threeMonthsOut.isSameOrBefore(oneYearOut, 'days')) {
                        max = calendarDate.isSameOrBefore(threeMonthsOut, 'days')
                    }
                }
                return min && max
            }
        },
    },
    methods: {
        onClick() {
            this.shouldValidate = false
        },
        transformDate(date) {
            const parts = date.split('-')
            if (parts.length > 1) {
                if (parts.length > 2) {
                    const [year, month, day] = date.split('-')
                    return `${month}/${day}/${year}`
                } else {
                    const [year, month] = date.split('-')
                    return `${month}/${year}`
                }
            }
            return date
        },
        sortDates(dates, format) {
            if (Array.isArray(dates) && dates.length > 1) {
                const first = moment(dates[0], format)
                const second = moment(dates[1], format)
                if (first.isAfter(second)) {
                    return [dates[1], dates[0]]
                }
                return dates
            }
            return dates
        },
        getTitleFormat() {
            const {
                selectedDateRange,
                dateRange
            } = this
            if (Array.isArray(selectedDateRange)) {
                if (selectedDateRange.length > 1) {
                    const first = moment(dateRange[0])
                    const second = moment(dateRange[1])
                    return `${first.format('MMM D')} - ${second.format('MMM D')}`
                } else if (selectedDateRange.length > 0) {
                    const date = moment(dateRange[0])
                    return date.format('ddd, MMM D')
                } else {
                    return null
                }
            } else {
                return null
            }
        },
        genMoment(date) {
            return moment(date, 'YYYY-MM-DD')
        },
        onClearTime() {
            this.selectedDateRange = []
            this.$emit('onClearTime')
        },
        onClosePicker() {
            this.shouldValidate = true
            this.modalValue = false
            this.$emit('onClose')
        },
        onConfirmTime() {
            this.shouldValidate = true
            this.$refs.timePickerDialog.save(this.selectedDateRange)
            this.$emit('onConfirmTime')
        },
    },
    watch: {
        selectedDateRange() {
            this.errorMessages = []
            this.$emit('input', this.dateRange)
        }
    }
}
</script>

<style scoped>

</style>
