<template>
    <card-display>
        <div class="d-flex flex-row justify-space-between align-center" style="column-gap:  10px;">
            <div class="text--h5" style="white-space: nowrap;">
                {{ startDate }}
            </div>
            <div class="d-flex flex-row align-center" style="column-gap: 10px; min-width: 0;">
                <div class="d-flex flex-row align-center" style="column-gap: 10px; min-width: 0;">
                    <div class="text--paragraph shift-time-container">
                        {{ shiftTime }}
                    </div>
                    <div class="d-flex flex-row align-center text--h5" style="column-gap: 3px; white-space: nowrap;">
                        <div>
                            {{ startTime }}
                        </div>
                        <div>
                            -
                        </div>
                        <div>
                            {{ endTime }}
                        </div>
                    </div>
                </div>
                <shifts-duration :duration="duration"/>
                <v-btn
                    v-if="deletable"
                    small
                    icon
                    color="primary"
                    @click="onDelete"
                    style="width: 24px !important; height: 24px !important;"
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-tooltip v-else top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            small
                            icon
                            color="success"
                            style="width: 24px !important; height: 24px !important;"
                        >
                            <v-icon>mdi-check-circle</v-icon>
                        </v-btn>
                    </template>
                    <span>Shift Currently Assigned</span>
                </v-tooltip>
            </div>
        </div>
    </card-display>
</template>

<script>
import CardDisplay from "./CardDisplay";
import ShiftsDuration from "./Stat/ShiftsDuration";

export default {
    name: "ShiftCard",
    components: {CardDisplay, ShiftsDuration},
    props: {
        start: {
            type: String,
            default: null
        },
        end: {
            type: String,
            default: null
        },
        shiftTime: {
            type: String,
            default: null
        },
        duration: {
            type: Number,
            default: null
        },
        timezone: {
            type: String,
            default: null
        },
        deletable: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        startDate() {
            return moment(this.start).tz(this.timezone).format('MMM D, YYYY')
        },
        startTime() {
            return moment(this.start).tz(this.timezone).format('LT')
        },
        endTime() {
            return moment(this.end).tz(this.timezone).format('LT')
        }
    },
    methods: {
        onDelete() {
            this.$emit('onDelete')
        }
    }
}
</script>

<style scoped>
.shift-time-container {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
