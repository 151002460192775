<template>
    <scrollable-container>
        <open-shifts-expanded-card
            :open-shifts="openShifts.shifts_by_month"
            :shifts-count="openShifts.shift_count"
            :shifts-duration="openShifts.total_shift_duration"
            :shift-time-counts="openShifts.shift_time_counts"
            :timezone="timezone"/>
        <v-divider/>
        <grouped-shifts
            :grouped-shifts="openShifts.shifts_by_month"
            :timezone="timezone"
            @onDelete="onDelete"/>
    </scrollable-container>
</template>

<script>
import ScrollableContainer from "../ScrollableContainer";
import OpenShiftsExpandedCard from "../OpenShiftsCard/OpenShiftsExpandedCard";
import GroupedShifts from "./GroupedShifts";

export default {
    name: "OpenShiftsDetails",
    components: {ScrollableContainer, OpenShiftsExpandedCard, GroupedShifts},
    props: {
        openShifts: {
            type: Object,
            default: () => ({})
        },
        timezone: {
            type: String,
            default: null
        }
    },
    methods: {
        onDelete(shiftRequestId) {
            this.$emit('onDelete', shiftRequestId)
        }
    }
}
</script>

<style scoped>

</style>
