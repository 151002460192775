<template>
    <div class="scheduler-header-wrapper px-6 hide-during-print-view">
        <div class="scheduler-header-container">
            <v-btn-toggle
                v-model="scheduleViewType"
                color="secondary"
                mandatory
            >
                <v-btn value="department" :disabled="loading || isSchedulerDisabled">
                    <v-icon>
                        {{ scheduleViewType === 'department' ? 'mdi-check' : 'mdi-domain' }}
                    </v-icon>
                    <div class="responsive-hidden">
                        Department
                    </div>
                </v-btn>
                <v-btn v-if="canViewStaffGroups" value="staff_group" :disabled="loading || isSchedulerDisabled">
                    <v-icon>
                        {{ scheduleViewType === 'staff_group' ? 'mdi-check' : 'mdi-account-group' }}
                    </v-icon>
                    <div class="responsive-hidden">
                        Staff Group
                    </div>
                </v-btn>
                <v-btn value="staff" :disabled="loading || isSchedulerDisabled">
                    <v-icon>
                        {{ scheduleViewType === 'staff' ? 'mdi-check' : 'mdi-account-multiple' }}
                    </v-icon>
                    <div class="responsive-hidden">
                        Staff
                    </div>
                </v-btn>
            </v-btn-toggle>
            <date-picker
                :preselected-options="preselectedOptions"
                :disabled="loading || isSchedulerDisabled"
            ></date-picker>
            <div class="d-flex flex-row align-center" style="column-gap: 20px;">
                <v-btn
                    :disabled="loading || isSchedulerDisabled"
                    color="primary_text"
                    icon
                    elevation="2"
                    @click="exportToPDF"
                >
                    <v-icon>mdi-printer</v-icon>
                </v-btn>
                <v-btn
                    v-if="organizationCanUseAutoSchedule"
                    :disabled="isAddShiftButtonDisabled"
                    color="primary_text"
                    icon
                    elevation="2"
                    @click="onAddAutoAssignShifts"
                >
                    <v-icon>mdi-calendar-plus</v-icon>
                </v-btn>
                <options-select
                    title="Create"
                    color="primary"
                    :items="shiftTypes"
                    :disabled="isAddShiftButtonDisabled"
                    @onOptionChanged="onNewRequestFormClick"
                ></options-select>
            </div>

        </div>

        <div class="scheduler-header-container">
            <v-row class="px-3 d-flex flex-row align-center justify-space-between">
                <div>
                    <department-filters-view
                        v-show="scheduleViewType === 'department'"
                    ></department-filters-view>
                    <staff-group-filters-view
                        v-show="scheduleViewType === 'staff_group'"
                    ></staff-group-filters-view>
                    <staff-filters-view
                        v-show="scheduleViewType === 'staff'"
                    ></staff-filters-view>
                </div>
            </v-row>
        </div>
    </div>
</template>

<script>
import DatePicker from '../Inputs/DatePicker'
import OptionsSelect from '../../reporting/FormInputs/OptionsSelect'
import DepartmentFiltersView from './Department/Filters/DepartmentFiltersView'
import StaffGroupFiltersView from './StaffGroup/Filters/StaffGroupFiltersView'
import StaffFiltersView from './Staff/StaffFiltersView'

export default {
    name: 'SchedulerHeader',
    components: {DatePicker, OptionsSelect, DepartmentFiltersView, StaffGroupFiltersView, StaffFiltersView},
    props: {
        preselectedOptions: {
            type: Object,
            default: () => ({}),
        }
    },
    data() {
        const org_can_use_auto_schedule = !!this.$user.hospital_group?.enable_auto_scheduling

        return {
            organizationCanUseAutoSchedule: org_can_use_auto_schedule,
            initialDepartmentsRender: true,
            initialStaffGroupsRender: true,
            initialStaffRender: true,
            api: new formHelper()
        }
    },
    computed: {
        shiftTypes() {
            const allShifts = [
                {text: 'Shifts', value: 'single'},
                {text: 'Repeating Shifts', value: 'repeating'},
                {text: 'Automated Shifts', value: 'automated'},
                {text: 'Historical Shift', value: 'historical'},
            ];

            if (this.$authIsOwner) {
                return allShifts
            }

            if (this.$authIsAdministrator || this.$authIsManager) {
                let newShifts = Array.from(allShifts)

                if (!this.$userHasPermission(this.$user, this.$config.permissions.REQUEST_FORMS.ADD_HISTORICAL)) {
                    newShifts = newShifts.filter(shift => shift.value !== 'historical')
                }

                if (!this.$userHasPermission(this.$user, this.$config.permissions.STAFFING_AUTOMATION.EDIT)) {
                    newShifts = newShifts.filter(shift => shift.value !== 'automated')
                }

                return newShifts
            }

            return allShifts; // default is to show all shifts
        },
        scheduleViewType: {
            get() {
                return this.$store.getters.schedulerGetScheduleViewType
            },
            set(scheduleViewType) {
                this.$store.dispatch('schedulerSetScheduleViewType', {scheduleViewType})
            }
        },
        selectedDepartmentId: {
            get() {
                return this.$store.getters.schedulerGetDepartmentId
            },
            set(departmentId) {
                this.$store.dispatch('schedulerSetDepartmentId', {departmentId})
            }
        },
        departments: {
            get() {
                return this.$store.getters.schedulerGetDepartments
            },
            set(departments) {
                this.$store.dispatch('schedulerSetDepartments', {departments})
            }
        },
        departmentsLoading: {
            get() {
                return this.$store.getters.schedulerGetDepartmentsLoading
            },
            set(departmentsLoading) {
                this.$store.dispatch('schedulerSetDepartmentsLoading', {departmentsLoading})
            }
        },
        selectedStaffGroupId: {
            get() {
                return this.$store.getters.schedulerGetStaffGroupId
            },
            set(staffGroupId) {
                this.$store.dispatch('schedulerSetStaffGroupId', {staffGroupId})
            }
        },
        staffGroups: {
            get() {
                return this.$store.getters.schedulerGetStaffGroups
            },
            set(staffGroups) {
                this.$store.dispatch('schedulerSetStaffGroups', {staffGroups})
            }
        },
        staffGroupsLoading: {
            get() {
                return this.$store.getters.schedulerGetStaffGroupsLoading
            },
            set(staffGroupsLoading) {
                this.$store.dispatch('schedulerSetStaffGroupsLoading', {staffGroupsLoading})
            }
        },
        selectedStaff: {
            get() {
                return this.$store.getters.schedulerGetStaff
            },
            set(staff) {
                this.$store.dispatch('schedulerSetStaff', {staff})
            }
        },
        canViewStaffGroups() {
            if (this.$authIsOwner || this.$authIsAdministrator) {
                return true
            }
        },
        isSchedulerDisabled() {
            return this.$store.getters.schedulerGetIsDisabled
        },
        eventsLoading() {
            if (this.scheduleViewType === 'department') {
                return this.$store.getters.schedulerGetDepartmentEventsLoading
            } else if (this.scheduleViewType === 'staff_group') {
                return this.$store.getters.schedulerGetStaffGroupEventsLoading
            } else if (this.scheduleViewType === 'staff') {
                return this.$store.getters.schedulerGetStaffEventsLoading

            }
        },
        loading() {
            if (this.scheduleViewType === 'department') {
                return this.departmentsLoading || this.eventsLoading
            } else if (this.scheduleViewType === 'staff_group') {
                return this.staffGroupsLoading || this.eventsLoading
            } else if (this.scheduleViewType === 'staff') {
                return this.staffGroupsLoading || this.eventsLoading
            }
        },
        locationId() {
            return this.$root.globalLocation?._id
        },
        isAddShiftButtonDisabled() {
            return this.loading || this.isSchedulerDisabled || !this.$userHasPermission(this.$user, this.$config.permissions.REQUEST_FORMS.EDIT)
        },
    },
    methods: {
        onNewRequestFormClick(shiftType) {
            this.$emit('onNewRequestFormClick', shiftType.value)
        },
        fetchDepartments(locationId) {
            this.departmentsLoading = true
            this.api.get(`/schedules/departments-filter/${locationId}`).then(({data}) => {
                if (Array.isArray(data)) {
                    this.departments = Array.from(data).map(department => ({
                        _id: department._id,
                        text: department.name,
                    }))
                    if (this.initialDepartmentsRender) {
                        if (
                            !this.preselectedOptions.preselected_event_not_found &&
                            this.preselectedOptions.scheduler_view === 'department' &&
                            this.preselectedOptions.department_id) {
                            this.selectedDepartmentId = this.preselectedOptions.department_id
                        } else {
                            if (this.departments.length > 0) {
                                this.selectedDepartmentId = this.departments[0]._id
                            } else {
                                this.$store.dispatch('schedulerSetDepartmentEventsLoading', {eventsLoading: false})
                                this.$store.dispatch('schedulerSetDepartmentEvents', {events: []})
                                this.$store.dispatch('schedulerSetStaffGroupEventsLoading', {eventsLoading: false})
                                this.$store.dispatch('schedulerSetStaffGroupEvents', {events: []})
                                this.$store.dispatch('schedulerSetStaffEventsLoading', {eventsLoading: false})
                                this.$store.dispatch('schedulerSetStaffEvents', {events: []})
                            }
                        }
                        this.initialDepartmentsRender = false
                    } else {
                        if (this.departments.length > 0) {
                            this.selectedDepartmentId = this.departments[0]._id
                        } else {
                            this.$store.dispatch('schedulerSetDepartmentEventsLoading', {eventsLoading: false})
                            this.$store.dispatch('schedulerSetDepartmentEvents', {events: []})
                            this.$store.dispatch('schedulerSetStaffGroupEventsLoading', {eventsLoading: false})
                            this.$store.dispatch('schedulerSetStaffGroupEvents', {events: []})
                            this.$store.dispatch('schedulerSetStaffEventsLoading', {eventsLoading: false})
                            this.$store.dispatch('schedulerSetStaffEvents', {events: []})
                        }
                    }
                }
            }).catch(console.log).finally(() => {
                this.departmentsLoading = false
            })
        },
        fetchStaffGroups(locationId) {
            this.staffGroupsLoading = true
            this.api.get(`/schedules/staff-groups-filter/${locationId}`).then(({data}) => {
                if (Array.isArray(data)) {
                    this.staffGroups = Array.from(data).map(staffGroup => ({
                        _id: staffGroup._id,
                        text: staffGroup.name,
                    }))
                    if (this.initialStaffGroupsRender) {
                        if (
                            !this.preselectedOptions.preselected_event_not_found &&
                            this.preselectedOptions.scheduler_view === 'staff_group' &&
                            this.preselectedOptions.staff_group_id) {
                            this.selectedStaffGroupId = this.preselectedOptions.staff_group_id
                            this.selectedStaff = this.preselectedOptions.staff_group_id
                        } else {
                            if (this.staffGroups.length > 0) {
                                this.selectedStaffGroupId = this.staffGroups[0]._id
                                this.selectedStaff = this.staffGroups[0]._id
                            }
                        }
                        this.initialStaffGroupsRender = false
                    } else {
                        if (this.staffGroups.length > 0) {
                            this.selectedStaffGroupId = this.staffGroups[0]._id
                            this.selectedStaff = this.staffGroups[0]._id
                        }
                    }
                }
            }).catch(console.log).finally(() => {
                this.staffGroupsLoading = false
            })
        },
        exportToPDF() {
            const {scheduleViewType, selectedDepartmentId, selectedStaffGroupId} = this;

            let data = {
                scheduleViewType,
                selectedDepartmentId: this.scheduleViewType === 'department' ? selectedDepartmentId : null,
                selectedStaffGroupId: this.scheduleViewType !== 'department' ? selectedStaffGroupId : null,
            };

            this.$emit('exportToPDF', data);

        },
        onAddAutoAssignShifts() {
            this.$emit('onAddAutoAssignShifts')
        }
    },
    watch: {
        locationId: {
            immediate: true,
            handler(locationId) {
                if (locationId) {
                    this.fetchDepartments(locationId)
                    this.fetchStaffGroups(locationId)
                }
            }
        }
    },
}
</script>

<style scoped>

.scheduler-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.scheduler-header-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    z-index: 15;
}

@media only screen and (max-width: 1080px) {
    .scheduler-header-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
    }
}

@media only screen and (max-width: 480px) {
    .responsive-hidden {
        display: none;
    }
}

</style>
