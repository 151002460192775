<template>
    <div class="d-flex flex-column scrollable-container" :style="scrollableContainerStyle">
        <slot/>
    </div>
</template>

<script>
export default {
    name: "ScrollableContainer",
    props: {
        height: {
            type: Number,
            default: 0
        }
    },
    data: () => ({
        innerWidth: window.innerWidth,
    }),
    computed: {
        scrollableContainerStyle() {
            return {
                height: this.matchDownMd ? '500px' : this.height > 0 ? 'calc(100% - 72px)' : '100%',
            }
        },
        matchDownMd() {
            return this.innerWidth < 960
        },
    },
    methods: {
        onResize() {
            this.innerWidth = window.innerWidth
        },
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize)
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    },
}
</script>

<style scoped>
.scrollable-container {
    overflow-y: auto;
    row-gap: 15px;
}
</style>
