<template>
    <custom-icon icon="mdi-calendar-check" color="rgb(206, 206, 206)"></custom-icon>
</template>

<script>
import CustomIcon from "./CustomIcon";

export default {
    name: "GreyCalendarCheckIcon",
    components: {CustomIcon}
}
</script>

<style scoped>

</style>
