<template>
    <scrollable-container>
        <user-details-card
            :user="user"
            :timezone="timezone"/>
        <v-divider/>
        <grouped-shifts
            :grouped-shifts="user.shifts_by_month"
            :timezone="timezone"
            @onDelete="onDelete"/>
    </scrollable-container>
</template>

<script>
import ScrollableContainer from "../ScrollableContainer";
import UserDetailsCard from "./UserDetailsCard";
import GroupedShifts from "./GroupedShifts";

export default {
    name: "UserDetails",
    components: {ScrollableContainer, UserDetailsCard, GroupedShifts},
    props: {
        user: {
            type: Object,
            default: () => ({})
        },
        timezone: {
            type: String,
            default: null
        }
    },
    methods: {
        onDelete(shiftRequestId, groupingIndex) {
            this.$emit('onDelete', shiftRequestId, groupingIndex)
        }
    }
}
</script>

<style scoped>

</style>
