<template>
    <div class="d-flex flex-row justify-center align-center" :style="containerStyle">
        <v-icon class="mr-3" color="black">{{ statusInfo.icon }}</v-icon>
        <span class="text-center text--h3">{{ statusInfo.name }}</span>
    </div>
</template>

<script>
export default {
    name: "TimeOffRequestStatus",
    props: {
        status: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            statuses: {
                [this.$config.TIME_OFF_REQUEST_STATUS.APPROVED]: {
                    name: 'Request Approved',
                    icon: 'mdi-check-circle',
                    backgroundColor: 'rgba(107, 201, 110, 0.4)',
                    borderColor: 'rgba(107, 201, 110, 1)'
                },
                [this.$config.TIME_OFF_REQUEST_STATUS.CANCELLED]: {
                    name: 'Request Cancelled',
                    icon: 'mdi-close-circle',
                    backgroundColor: 'rgba(255, 218, 214, 1)',
                    borderColor: 'rgba(186, 26, 26, 1)'
                },
                [this.$config.TIME_OFF_REQUEST_STATUS.DENIED]: {
                    name: 'Request Denied',
                    icon: 'mdi-close-circle',
                    backgroundColor: 'rgba(255, 218, 214, 1)',
                    borderColor: 'rgba(186, 26, 26, 1)'
                },
                [this.$config.TIME_OFF_REQUEST_STATUS.REQUESTED]: {
                    name: 'Request Pending',
                    icon: 'mdi-timer-sand',
                    backgroundColor: 'rgba(247, 219, 163, 1)',
                    borderColor: 'rgba(213, 191, 64, 1)'
                },
            }
        }
    },
    computed: {
        statusInfo() {
            if (this.statuses.hasOwnProperty(this.status)) {
                return this.statuses[this.status]
            }
            return this.statuses[this.$config.TIME_OFF_REQUEST_STATUS.APPROVED]
        },
        containerStyle() {
            return {
                backgroundColor: this.statusInfo.backgroundColor,
                border: `2px solid ${this.statusInfo.borderColor}`,
                padding: '8px 24px',
                borderRadius: '8px'
            }
        }
    }

}
</script>

<style scoped>

</style>
