<template>
    <v-menu
        ref="datePicker"
        open-on-click
        bottom
        offset-y
        z-index="22"
        :close-on-content-click="false"
        @input="onInput"
    >
        <template v-slot:activator="{ on, value }">
            <div class="d-flex flex-row align-center justify-space-between date-picker-container" style="flex:0 0 auto;">
                <v-btn
                    name="previousWeek"
                    icon
                    color="black"
                    :disabled="disabled"
                    @click="onClickPrevious"
                >
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <div
                    v-on="on"
                    class="date-picker"
                    style="cursor: pointer;"
                >
                    {{ getStartOfWeek(currentDates)?.format('MMM D') }} - {{ getEndOfWeek(currentDates)?.format('MMM D, YYYY') }}
                </div>
                <v-btn
                    name="nextWeek"
                    icon
                    color="black"
                    :disabled="disabled"
                    @click="onClickNext"
                >
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </div>
        </template>
        <v-card :width="450">
            <v-container fluid class="pa-0 ma-0">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-date-picker
                            v-model="calendarDates"
                            color="secondary"
                            locale="en-US"
                            elevation="0"
                            full-width
                            multiple
                            range
                            show-adjacent-months
                            :title-date-format="getCalendarTitle"
                            @click:date="onDateClick"
                        ></v-date-picker>
                    </v-col>
                </v-row>
                <v-row no-gutters class="pb-3 px-2">
                    <v-col cols="12" class="d-flex justify-end">
                        <v-btn
                            color="primary"
                            :disabled="disabled"
                            @click="onApplyDates"
                        >
                            Apply Dates
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-menu>
</template>

<script>
export default {
    name: "DatePicker",
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        preselectedOptions: {
            type: Object,
            default: () => ({}),
        }
    },
    data: () => ({
        calendarDates: [],
    }),
    computed: {
        currentDates: {
            get () {
                return this.$store.getters.schedulerGetDates
            },
            set (dates) {
                this.$store.dispatch('schedulerSetDates', { dates })
            }
        },
        globalTimezone () {
            return this.$root.globalTimezone
        }
    },
    methods: {
        onDateClick () {
            const { calendarDates } = this
            if (Array.isArray(calendarDates) && calendarDates.length > 0) {
                const currentDate = moment(calendarDates[0])
                this.calendarDates = [
                    currentDate.clone().startOf('week').format('YYYY-MM-DD'),
                    currentDate.clone().endOf('week').format('YYYY-MM-DD'),
                ]
            }
        },
        onApplyDates () {
            this.currentDates = Array.from(this.calendarDates)
            this.emitChangeWeek()
            this.$refs.datePicker?.save()
        },
        onInput (value) {
            if (value) {
                this.calendarDates = Array.from(this.currentDates)
            }
        },
        onClickPrevious () {
            const startOfWeek = this.getStartOfWeek(this.currentDates)
            const endOfWeek = this.getEndOfWeek(this.currentDates)
            if (startOfWeek && endOfWeek) {
                this.currentDates = [
                    startOfWeek.subtract(1, 'week').format('YYYY-MM-DD'),
                    endOfWeek.subtract(1, 'week').format('YYYY-MM-DD'),
                ]
                this.emitChangeWeek()
            }
        },
        onClickNext () {
            const startOfWeek = this.getStartOfWeek(this.currentDates)
            const endOfWeek = this.getEndOfWeek(this.currentDates)
            if (startOfWeek && endOfWeek) {
                this.currentDates = [
                    startOfWeek.add(1, 'week').format('YYYY-MM-DD'),
                    endOfWeek.add(1, 'week').format('YYYY-MM-DD'),
                ]
                this.emitChangeWeek()
            }
        },
        emitChangeWeek () {
            window.EventBus.$emit('scheduler/onChangeWeek')
        },
        getCalendarTitle () {
            const startOfWeek = this.getStartOfWeek(this.calendarDates)
            const endOfWeek = this.getEndOfWeek(this.calendarDates)
            if (startOfWeek && endOfWeek) {
                return `${startOfWeek.format('MMM D')} - ${endOfWeek.format('MMM D')}`
            }
            return moment().format('ddd, MMM D')
        },
        setInitialDates () {
            let date = null
            if (!this.preselectedOptions.preselected_event_not_found && this.preselectedOptions.date) {
                date = moment(this.preselectedOptions.date).tz(this.globalTimezone)
            } else {
                date = moment().tz(this.globalTimezone)
            }
            const startOfWeek = date.startOf('week').format('YYYY-MM-DD')
            const endOfWeek = date.endOf('week').format('YYYY-MM-DD')
            this.currentDates = [startOfWeek, endOfWeek]
            this.calendarDates = [startOfWeek, endOfWeek]
        },
        getStartOfWeek (dates) {
            if (Array.isArray(dates) && dates.length > 1) {
                return moment(dates[0])
            }
            return null
        },
        getEndOfWeek (dates) {
            if (Array.isArray(dates) && dates.length > 1) {
                return moment(dates[1])
            }
            return null
        },
    },
    created () {
        this.setInitialDates()
    }
}
</script>

<style scoped>

    .date-picker-container {
        width: 450px;
    }

    .date-picker {
        font-size: 32px;
        padding-right: 12px !important;
        padding-left: 12px !important;
    }

    @media only screen and (max-width: 860px) {

        .date-picker-container {
            width: 360px;
        }
        .date-picker {
            font-size: 18px;
            padding-right: 2px !important;
            padding-left: 2px !important;
        }

    }

    @media only screen and (max-width: 380px) {

        .date-picker-container {
            width: 300px;
        }
        .date-picker {
            font-size: 14px;
            padding-right: 0px !important;
            padding-left: 0px !important;
        }

    }

</style>
