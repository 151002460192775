<template>
    <card-display>
        <div class="parent-grid">
            <div class="text--paragraph">
                {{ startDate }} - {{ endDate }}
            </div>
            <shifts-count
                :count="payrollPeriod.shift_count"
            />
            <shifts-duration
                :duration="payrollPeriod.total_shift_duration"
                :max-duration="payrollPeriod.max_work_hours"
            />
            <div class="text--paragraph d-flex justify-end">
                {{ payrollPeriod.utilization_rate }}%
            </div>
        </div>
    </card-display>
</template>

<script>
import CardDisplay from "./CardDisplay";
import ShiftsCount from "./Stat/ShiftsCount";
import ShiftsDuration from "./Stat/ShiftsDuration";

export default {
    name: "PayrollPeriod",
    components: {CardDisplay, ShiftsCount, ShiftsDuration},
    props: {
        payrollPeriod: {
            type: Object,
            default: () => ({})
        },
        timezone: {
            type: String,
            default: null
        }
    },
    computed: {
        startDate() {
            return moment(this.payrollPeriod.start).tz(this.timezone).format('MMM Do')
        },
        endDate() {
            return moment(this.payrollPeriod.end).tz(this.timezone).format('Do')
        }
    }
}
</script>

<style scoped>
.parent-grid {
    display: grid;
    grid-template-columns: 12fr 8fr 9fr 3fr;
}
</style>
