<template>
    <card-display
        background-color="rgb(255, 245, 214)"
        border-color="rgb(250, 213, 0)"
        :height="50"
    >
        <div class="d-flex flex-row justify-space-between align-center">
            <div class="text--paragraph">
                {{ startDate }} - {{ endDate }}
            </div>
            <shifts-count :count="shiftsCount"/>
        </div>
    </card-display>
</template>

<script>
import CardDisplay from "../CardDisplay";
import ShiftsCount from "../Stat/ShiftsCount";

export default {
    name: "OpenShiftCard",
    components: {CardDisplay, ShiftsCount},
    props: {
        start: {
            type: String,
            default: null
        },
        end: {
            type: String,
            default: null
        },
        timezone: {
            type: String,
            default: null
        },
        shiftsCount: {
            type: Number,
            default: 0
        }
    },
    computed: {
        startDate() {
            return moment(this.start).tz(this.timezone).format('MMM Do')
        },
        endDate() {
            return moment(this.end).tz(this.timezone).format('Do')
        }
    }
}
</script>

<style scoped>

</style>
