<template>
    <v-row class="mt-4">
        <v-col cols="12" class="d-flex labels-row">
            <div>
                <v-autocomplete
                    v-model="selectedShiftRequestId"
                    :items="jobTitles"
                    item-value="shift_request_id"
                    item-text="job_title"
                    :disabled="jobTitles.length <= 1 || listsLoading"
                    :loading="requestFormDetailsLoading"
                    style="margin-bottom: 10px;"
                    :menu-props="{ offsetY: true }"
                    prepend-inner-icon="mdi-clipboard-account"
                    append-icon="mdi-chevron-down"
                    :height="48"
                    solo
                    flat
                    outlined
                >

                </v-autocomplete>
            </div>
            
            <div class="d-flex flex-row align-center mb-2 label-margin">
                <div class="tooltip-container">
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" class="d-flex flex-row align-center">
                                <v-icon color="primary" class="mr-2">mdi-clock-outline</v-icon>
                                <span class="text--paragraph black--text">{{ time }}</span>
                            </div>
                        </template>
                        <span>Shift Hours</span>
                    </v-tooltip>
                </div>
            </div>

            <div class="tooltip-container">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="tooltip-wrapper">
                            <staff-scheduler-label
                                icon="mdi-domain"
                                :label="department"
                                class="mb-2 label-margin"
                            ></staff-scheduler-label>
                        </div>
                    </template>
                    <span>Department</span>
                </v-tooltip>
            </div>

            <div class="tooltip-container">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="tooltip-wrapper">
                            <staff-scheduler-label
                                icon="mdi-account-group"
                                :label="staffGroup"
                                class="mb-2 label-margin"
                            ></staff-scheduler-label>
                        </div>
                    </template>
                    <span>Staff Group</span>
                </v-tooltip>
            </div>

            <div class="d-flex flex-row justify-end">
                <v-btn
                    color="primary"
                    text
                    :disabled="listsLoading"
                    @click="openDetailsPanel"
                >
                    View Details
                </v-btn>
                <request-details-panel ref="requestDetailsPanel"/>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import StaffSchedulerLabel from "./StaffSchedulerLabel";
import RequestDetailsPanel from "./RequestDetailsPanel";
export default {
    name: "StaffSchedulerDetails",
    components: {RequestDetailsPanel, StaffSchedulerLabel, },
    props: {
        requestDetails: {
            type: Object,
            default: () => ({})
        },
    },
    computed: {
        selectedTimezone () {
            return this.$root.globalTimezone
        },
        selectedShiftRequestId: {
            get () {
                return this.$store.getters.getSelectedShiftRequestId
            },
            set (selectedShiftRequestId) {
                this.dispatchSetSelectedShiftRequestId(selectedShiftRequestId)
            }
        },
        loading () {
            return this.$store.getters.getUsersLoading
        },
        requestFormDetailsLoading () {
            return this.$store.getters.getIsRequestFormDetailsLoading
        },
        listsLoading () {
            return this.loading || this.requestFormDetailsLoading
        },
        requestForm () {
            return this.$store.getters.getRequestForm
        },
        jobTitles () {
            const { requestForm } = this
            if (requestForm && Array.isArray(requestForm.shift_requests)) {
                return requestForm.shift_requests.map(({ _id, job_title_name, filled_shifts_count, amount_requested, requirements }) => ({
                    shift_request_id: _id,
                    job_title: job_title_name,
                    filled_shifts: filled_shifts_count,
                    amount: amount_requested,
                    credentials: Array.isArray(requirements) ? Array.from(requirements) : []
                }))
            }
            return []
        },
        details () {
            return this.requestDetails || {}
        },
        shiftTime() {
            if (this.shiftTimeLabel) {
                return `${this.shiftTimeLabel} (${this.time})`
            }
            return this.time
        },
        shiftTimeLabel () {
            if (this.details.shift_time && this.details.shift_time.label) {
                return this.details.shift_time.label
            }
            return null
        },
        startTime () {
            if (!this.details.start_time) {
                return null
            }

            return this.formatTime(this.details.start_time)
        },
        endTime () {
            if (!this.details.end_time) {
                return null
            }

            return this.formatTime(this.details.end_time)
        },
        time () {
            if (this.startTime && this.endTime) {
                return `${this.startTime} - ${this.endTime}`
            }
            return 'Unknown'
        },
        duration () {
            if (this.details.duration) {
                return this.$options.filters.timeStringFromMinutes(this.details.duration)
            }
            return 'Unknown'
        },
        now () {
            return moment().format('YYYY-MM-DD')
        },
        location () {
            if (this.details.location) {
                return this.details.location
            }
            return 'Unknown'
        },
        department () {
            if (this.details.department) {
                return this.details.department
            }
            return 'Unknown'
        },
        staffGroup () {
            if (this.details.staff_group) {
                return this.details.staff_group
            }
            return 'Unknown'
        },
        recurrent () {
            if (this.details.request_form_group) {
                return 'Repeating'
            }
            return 'No Repeat'
        },
        createdBy () {
            if (this.details.created_by) {
                const { first_name, last_name } = this.details.created_by
                return `${first_name} ${last_name}`
            }
            return null
        }
    },
    methods: {
        openDetailsPanel() {
            this.$refs.requestDetailsPanel.open(this.createdBy, this.location, this.duration, this.jobTitles, this.shiftTimeLabel)
        },
        dispatchSetSelectedShiftRequestId (selectedShiftRequestId) {
            this.$store.dispatch('setSelectedShiftRequestId', { selectedShiftRequestId })
        },
        formatTime(time_string) {
            return moment(moment().format('YYYY-MM-DD') + ' ' + time_string).format('LT')
        }
    }
}
</script>

<style scoped>
    @media only screen and (min-width: 960px) {
        .label-margin {
            margin-left: 0;
        }

        .labels-row {
            flex-direction: column;
        }
    }
    @media only screen and (max-width: 959px) {
        .label-margin {
            margin-left: 10px;
        }

        .labels-row {
            flex-direction: row;
        }
    }

   /* .list-view-select-item {
        font-size: 24px;
        color: #485FF8;
    }*/

    :deep(i.v-icon.mdi-chevron-down) {
        color: #485FF8;
    }

    :deep(i.v-icon.mdi-clipboard-account) {
        color: rgb(13, 10, 50);
    }

    :deep(div.v-input__slot) {
        padding: 0 12px;
        margin-bottom: 0;
    }

    :deep(div.v-text-field__details) {
        display: none !important;
    }

    .view-details-button {
        text-transform: none !important;
    }

    .shift-time-label {
        font-size: 20px;
        line-height: 26px;
    }

    .tooltip-container {
        display: flex;
        align-items: center;
        gap: 6px; /* Adjust spacing between item and tooltip */
    }

    .tooltip-wrapper {
        display: inline-flex;
        align-items: center;
    }
</style>
