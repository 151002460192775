<template>
    <scheduling-content-layout :is-in-past="isInPast">
        <template v-slot:calendar>
            <v-date-picker
                :value="selectedDate"
                locale="en-US"
                elevation="5"
                color="secondary"
                full-width
                :allowed-dates="allowedDates"
                :disabled="loading || listsLoading"
                @click:date="onClickDate"
            ></v-date-picker>
            <staff-scheduler-details :request-details="requestDetails"></staff-scheduler-details>
            <unsaved-scheduling-changes-modal
                v-model="unsavedChangesModalValue"
                @onConfirm="onDiscardChanges"
            >
            </unsaved-scheduling-changes-modal>
        </template>
        <template v-slot:user-lists>
            <user-lists
                :shift-request-id="selectedShiftRequestId"
                :amount-requested="amountRequested"
                :auth-is-authorized="authIsAuthorized"
                :is-in-past="isInPast"
                :is-shift-starting-soon="isShiftStartingSoon"
                @onEditUser="onEditUser"
                @onRemoveUser="onRemoveUser"
            ></user-lists>
        </template>
    </scheduling-content-layout>
</template>

<script>
import SchedulingContentLayout from "../Layouts/SchedulingLayout/SchedulingContentLayout";
import StaffSchedulerDetails from "./StaffSchedulerDetails"
import UserLists from '../Lists/UserLists/UserLists'
import UnsavedSchedulingChangesModal from '../Modals/UnsavedSchedulingChangesModal'

export default {
    name: "StaffScheduler",
    components: { SchedulingContentLayout, UserLists, StaffSchedulerDetails, UnsavedSchedulingChangesModal },
    props: {
        requestDetails: {
            type: Object,
            default: () => ({})
        },
        authStaffingDetails: {
            type: Array,
            default: function() {
                return []
            }
        },
        preselectedValues: {
            type: Object,
            default: () => ({}),
        },
        isInPast: {
            type: Boolean,
            default: false,
        },
        isShiftStartingSoon: {
            type: Boolean,
            default: false,
        },
        amountRequested: {
            type: Number,
            default: 0,
        },
    },
    data: () => ({
        date: null,
        unsavedChangesModalValue: false,
        api: new formHelper(),
    }),
    computed: {
        datesById () {
            return this.$store.getters.getDatesById
        },
        selectedDate: {
            get () {
                return this.$store.getters.getSelectedDate
            },
            set (date) {
                this.dispatchSetSelectedDate(date)
            }
        },
        selectedView () {
            return this.$store.getters.getSelectedView
        },
        toBeAssignedIds () {
            return this.$store.getters.getToBeAssignedIds
        },
        toBeOfferedIds () {
            return this.$store.getters.getToBeOfferedIds
        },
        allowedDates() {
            if (this.datesById && this.datesById.hasOwnProperty('byDate')) {
                const dates = Object.keys(this.datesById.byDate)
                return val => dates.indexOf(val) !== -1
            }
            return null
        },
        selectedRequestFormId () {
            return this.$store.getters.getSelectedRequestFormId
        },
        requestForm () {
            return this.$store.getters.getRequestForm
        },
        selectedShiftRequestId () {
            return this.$store.getters.getSelectedShiftRequestId
        },
        loading () {
            return this.$store.getters.getIsRequestFormDetailsLoading
        },
        listsLoading() {
            return this.$store.getters.getUsersLoading
        },
        currentDate() {
            if (this.datesById && this.datesById.hasOwnProperty('byMoment') && this.datesById['byMoment'].hasOwnProperty(this.selectedRequestFormId)) {
                return this.datesById['byMoment'][this.selectedRequestFormId].format('dddd, MMMM Do')
            }
            return 'N/A'
        },
        jobTitles () {
            const { requestForm } = this
            if (requestForm && Array.isArray(requestForm.shift_requests)) {
                return requestForm.shift_requests.map(({ _id, job_title_name }) => ({
                    _id,
                    title: job_title_name
                }))
            }
            return []
        },
        shiftRequest () {
            const { requestForm, selectedShiftRequestId } = this
            if (requestForm && selectedShiftRequestId) {
                return requestForm.shift_requests.find(req => req._id === this.selectedShiftRequestId)
            }
            return null
        },
        authIsAuthorized () {
            if (this.$authIsOwner || this.$authIsAdministrator) {
                return true
            }

            if (!this.authStaffingDetails) {
                return false
            }

            if (!this.selectedShiftRequestId || !this.requestForm || !Array.isArray(this.requestForm.shift_requests)) {
                return false
            }

            const { shiftRequest } = this

            if (!shiftRequest) {
                return false
            }

            const { staff_group_id, department_id } = this.requestForm
            const { job_title_id } = shiftRequest

            return this.authStaffingDetails.some(staffing_detail => {
                return (staffing_detail.staff_group_id === staff_group_id) &&
                    (staffing_detail.department_id === department_id) &&
                    (staffing_detail.job_title_id === job_title_id)
            })

        },
        preselectedShiftRequestId () {
            if (this.preselectedValues && this.preselectedValues.shiftRequestId) {
                return this.preselectedValues.shiftRequestId
            }
            return null
        },
        preselectedJobTitleId () {
            if (this.preselectedValues && this.preselectedValues.jobTitleId) {
                return this.preselectedValues.jobTitleId
            }
            return null
        },
    },
    methods: {
        fetchDetails(requestFormId) {
            if(requestFormId) {
                this.dispatchSetIsRequestFormDetailsLoading(true)
                this.api.get(`/request-forms/details/${requestFormId}`)
                    .then(({ data }) => {
                        if(data) {
                            const { shift_requests } = data
                            const shiftRequest = this.shiftRequest
                            this.dispatchSetRequestForm(data)
                            this.dispatchSetSelectedShiftRequestId(
                                this.getInitialShiftRequestId(
                                    shift_requests,
                                    shiftRequest && shiftRequest.job_title_id ?
                                        shiftRequest.job_title_id : this.preselectedJobTitleId,
                                )
                            )
                        }
                    })
                    .catch(console.log)
                    .finally(() => {
                        this.dispatchSetIsRequestFormDetailsLoading(false)
                    })
            }
        },
        onClickDate (date) {
            if (this.selectedDate !== date) {
                const { selectedView, toBeAssignedIds, toBeOfferedIds } = this
                let isDirty = false
                if (selectedView === 'all') {
                    isDirty = (Array.isArray(toBeAssignedIds) && toBeAssignedIds.length > 0) || (Array.isArray(toBeOfferedIds) && toBeOfferedIds.length > 0)
                } else if (selectedView === 'assign') {
                    isDirty = Array.isArray(toBeAssignedIds) && toBeAssignedIds.length > 0
                } else if (selectedView === 'offer') {
                    isDirty = (Array.isArray(toBeOfferedIds) && toBeOfferedIds.length > 0)
                }
                if (isDirty) {
                    this.date = date
                    this.unsavedChangesModalValue = true
                } else {
                    this.selectedDate = date
                }
            }
        },
        onDiscardChanges () {
            this.selectedDate = this.date
        },
        onChangeJobTitle(id) {
            this.dispatchSetSelectedShiftRequestId(id)
        },
        onEditUser (shiftId) {
            this.$emit('onEditUser', shiftId)
        },
        onRemoveUser (userId) {
            this.$emit('onRemoveUser', userId)
        },
        clearListsState() {
            this.dispatchClearScheduleState()
        },
        getInitialShiftRequestId (shiftRequests, preselectedJobTitleId) {
            if (Array.isArray(shiftRequests) && shiftRequests.length > 0) {
                if (preselectedJobTitleId) {
                    const shiftRequest = shiftRequests.find(req => req.job_title_id === preselectedJobTitleId)
                    if (shiftRequest) {
                        return shiftRequest._id
                    }
                }
                return shiftRequests[0]._id
            }
            return null
        },
        dispatchClearScheduleState () {
            this.$store.dispatch('clearScheduleState')
        },
        dispatchSetIsRequestFormDetailsLoading (isRequestFormDetailsLoading) {
            this.$store.dispatch('setIsRequestFormDetailsLoading', { isRequestFormDetailsLoading })
        },
        dispatchSetSelectedShiftRequestId (selectedShiftRequestId) {
            this.$store.dispatch('setSelectedShiftRequestId', { selectedShiftRequestId })
        },
        dispatchSetIsAssignableView (isAssignableView) {
            this.$store.dispatch('setIsAssignableView', { isAssignableView })
        },
        dispatchSetRequestForm (requestForm) {
            this.$store.dispatch('setRequestForm', { requestForm })
        },
        dispatchSetAssignedCount (assignedCount) {
            this.$store.dispatch('setAssignedCount', { assignedCount })
        },
        dispatchSetSelectedDate (selectedDate) {
            this.$store.dispatch('setSelectedDate', { selectedDate })
        },
    },
    watch: {
        selectedRequestFormId: {
            immediate: true,
            handler(id) {
                this.fetchDetails(id)
                this.dispatchSetIsAssignableView(true)
                this.clearListsState()
            }
        },
        selectedShiftRequestId: {
            immediate: true,
            handler() {
                if (this.shiftRequest && this.shiftRequest.hasOwnProperty('filled_shifts_count')) {
                  this.dispatchSetAssignedCount(this.shiftRequest.filled_shifts_count)
                }
                this.clearListsState()
            }
        }
    }
}
</script>

<style scoped>

</style>
