<template>
    <div class="d-flex flex-row align-center mb-2" style="column-gap: 10px;">
        <card-display full-width>
            <div class="d-flex flex-column" style="row-gap: 5px;">
                <div class="d-flex flex-row" style="column-gap: 10px;">
                    <div class="text--paragraph font-weight-bold">
                        {{ date }}
                    </div>
                    <div>
                        |
                    </div>
                    <div class="text--paragraph font-weight-bold">
                        {{ start }} - {{ end }}
                    </div>
                    <div>
                        |
                    </div>
                    <div class="text--paragraph">
                        {{ shiftTime }}
                    </div>
                </div>
                <div class="d-flex flex-row" style="column-gap: 5px;">
                    <div class="text--paragraph">
                        {{ fullName }}
                    </div>
                    <div class="text--paragraph">
                        -
                    </div>
                    <div class="text--paragraph">
                        {{ reason }}
                    </div>
                </div>
            </div>
        </card-display>
        <v-btn
            icon
            color="black"
            style="margin-top: 4px;"
            :href="url"
        >
            <v-icon dark>mdi-calendar</v-icon>
        </v-btn>
    </div>
</template>

<script>
import CardDisplay from "../AutoAssignResultsModal/CardDisplay";

export default {
    name: "ShiftCard",
    components: {CardDisplay},
    props: {
        shift: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        date() {
            return moment(this.shift.start).tz(this.shift.timezone).format('MMMM D, YYYY')
        },
        start() {
            return moment(this.shift.start).tz(this.shift.timezone).format('h:mm A')
        },
        end() {
            return moment(this.shift.end).tz(this.shift.timezone).format('h:mm A')
        },
        shiftTime() {
            return this.shift.shift_time
        },
        fullName() {
            return `${this.shift.first_name} ${this.shift.last_name}`
        },
        reason() {
            return this.shift.reason
        },
        url() {
            return `/schedules?shift_id=${this.shift.shift_request_id}&type=shift_request`
        }
    }
}
</script>

<style scoped>

</style>
