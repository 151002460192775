<template>
    <v-dialog v-model="model" max-width="500px" persistent>
        <v-form>
            <v-card>
                <v-toolbar class="informational-modal" elevation="0">
                    <v-toolbar-title class="text--h2">
                        Share Report
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text
                    style="padding-left: 12px !important; padding-right: 12px !important; padding-top: 12px !important;">
                    <v-container fluid>
                        <v-row>
                            <div v-show="isFetchingUsers" style="height:100%">
                                <loading-spinner :value="true"></loading-spinner>
                            </div>
                            <v-col cols="12" class="ds-modal-input-container">
                                <div class="text--paragraph black--text"
                                     style="padding-bottom: 14px; padding-top: 4px;">
                                    Select team members to receive a copy of this report via email.
                                </div>
                            </v-col>
                            <v-col cols="12" class="ds-modal-input-container" style="margin-bottom: 6px;">
                                <search-shareable-users
                                    :key="shareableUsersKey"
                                    v-model="shareableUsers"
                                    hide-details
                                    :initial-entries="initialShareableUsers"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        :disabled="isSharingReport"
                        @click="onClose"
                    >
                        Close
                    </v-btn>
                    <v-btn
                        color="primary"
                        outlined
                        class="mr-3"
                        :disabled="shareableUsers.length < 1"
                        :loading="isSharingReport"
                        @click="onShare"
                    >
                        Share
                    </v-btn>
                </v-card-actions>
            </v-card>
            <unsaved-changes-dialog ref="unsavedChangesDialog"/>
        </v-form>
    </v-dialog>
</template>

<script>
import SearchShareableUsers from "./SearchShareableUsers";
import UnsavedChangesDialog from "../../modals/UnsavedChangesDialog";
import LoadingSpinner from "../../loaders/LoadingSpinner";

export default {
    name: "ShareReportModal",
    components: {SearchShareableUsers, UnsavedChangesDialog, LoadingSpinner},
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        reportId: {
            type: String,
            default: null,
        }
    },
    data: () => ({
        shareableUsers: [],
        prevShareableUsers: [],
        initialShareableUsers: [],
        isSharingReport: false,
        isFetchingUsers: false,
        shareableUsersKey: 1,
        api: new formHelper()
    }),
    computed: {
        model: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
    },
    methods: {
        shareReport() {
            this.isSharingReport = true
            this.api.post(`/reports/share/${this.reportId}`, {user_ids: this.shareableUsers})
                .then(({message}) => {
                    message('Report shared')
                    this.model = false
                })
                .catch(console.log)
                .finally(() => {
                    this.isSharingReport = false
                })
        },
        fetchPreviouslySharedUsers() {
            this.isFetchingUsers = true
            this.api.get(`/reports/${this.reportId}/shared_with`)
                .then(({data}) => {
                    if (Array.isArray(data)) {
                        this.shareableUsers = data.map(u => u._id)
                        this.prevShareableUsers = data.map(u => u._id)
                        this.initialShareableUsers = Array.from(data)
                        this.shareableUsersKey = this.shareableUsersKey + 1
                    }
                })
                .catch(console.log)
                .finally(() => {
                    this.isFetchingUsers = false
                })
        },
        showDiscardChangesModal() {
            this.$refs.unsavedChangesDialog.open()
                .then(() => {
                    this.model = false
                })
                .catch(() => {
                })
        },
        onClose() {
            if (!_.isEqual(this.shareableUsers, this.prevShareableUsers)) {
                this.showDiscardChangesModal()
                return
            }
            this.model = false
        },
        onShare() {
            this.shareReport()
        }
    },
    created() {
        this.fetchPreviouslySharedUsers()
    }
}
</script>

<style scoped>

</style>
