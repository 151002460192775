<template>
    <v-dialog
        v-model="modalValue"
        scrollable
        persistent
        max-width="1200px"
    >
        <v-card>
            <v-toolbar class="informational-modal" elevation="0">
                <v-toolbar-title class="text--h2">Create Automated Shifts</v-toolbar-title>
                <v-tooltip right v-if="showTimezoneTooltip">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            class="ml-1"
                        >
                            <v-icon color="primary">mdi-information-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>The selected timezone does not match the current location</span>
                </v-tooltip>
            </v-toolbar>
            <v-card-text>
                <v-form
                    ref="addAutomatedShiftForm"
                    :lazy-validation="false"
                >
                    <v-row class="mt-5 row-min-width">
                        <v-col cols="12" md="6">
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12" class="ds-modal-input-container">
                                        <p class="text--h4">
                                            Shift Dates
                                        </p>
                                    </v-col>
                                    <v-col cols="12" class="ds-modal-input-container">
                                        <time-picker-dialog
                                            v-model="startDate"
                                            :text-field-props="startDateTextFieldProps"
                                            required
                                            min-rule
                                            max-rule
                                            is-date-picker
                                            :allowed-dates="allowedStartDates"
                                        ></time-picker-dialog>
                                    </v-col>
                                    <v-col cols="12" class="ds-modal-input-container">
                                        <v-row style="margin-top: 0 !important;" class="row-min-width">
                                            <v-col cols="auto" class="ds-modal-input-container">
                                                <p class="text--h5" style="margin-top: 18px;">
                                                    Shifts Span
                                                </p>
                                            </v-col>
                                            <v-col cols="12" :sm="true" class="ds-modal-input-container d-flex"
                                                   style="column-gap: 10px;">
                                                <number-input
                                                    v-model.number="coverageWeeks"
                                                    label="Number"
                                                    :min="1"
                                                    :max="4"
                                                    :rules="intervalRules"
                                                    :readonly="editMode"
                                                    controls
                                                ></number-input>
                                                <p class="text--paragraph" style="margin-top: 18px;">
                                                    Weeks
                                                </p>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" class="ds-modal-input-container">
                                        <v-divider/>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-8">
                                    <v-col cols="12" class="ds-modal-input-container">
                                        <p class="text--h4">
                                            First Run Date
                                        </p>
                                    </v-col>
                                    <v-col cols="12" class="ds-modal-input-container">
                                        <time-picker-dialog
                                            v-model="runDate"
                                            :text-field-props="runDateTextFieldProps"
                                            required
                                            min-rule
                                            max-rule
                                            is-date-picker
                                            :allowed-dates="allowedRunDates"
                                        ></time-picker-dialog>
                                    </v-col>
                                    <v-col cols="12" class="ds-modal-input-container">
                                        <v-divider/>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-8">
                                    <v-col cols="12" class="ds-modal-input-container">
                                        <p class="text--h4">
                                            Shift Time
                                        </p>
                                    </v-col>
                                    <v-col cols="12" class="ds-modal-input-container">
                                        <v-autocomplete
                                            v-model="selectedShiftTimeId"
                                            label="Shift Time Preset"
                                            :items="shiftTimes"
                                            :loading="shiftTimesLoading"
                                            :disabled="shiftTimesLoading"
                                            item-value="_id"
                                            item-text="label"
                                            clearable
                                            outlined
                                            @change="onChangeShiftTime"
                                        >
                                            <template v-slot:prepend-inner>
                                                <v-icon color="primary">mdi-clock-outline</v-icon>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" :lg="true" class="ds-modal-input-container">
                                        <time-picker
                                            v-model="shiftTime.startTime"
                                            label="Start Time"
                                            :error-messages="api.hasError('start_time')"
                                            :key="startTimeKey"
                                            @onClearTime="onClearStartTime"
                                            @onUpdateTime="onConfirmStartTime"/>
                                    </v-col>
                                    <v-col cols="12" lg="5" class="ds-modal-input-container">
                                        <duration-input
                                            v-model="shiftTime.duration"
                                            :disabled="!start_time"
                                            :error-messages="api.hasError('duration')"
                                            :key="durationKey"
                                            @onChangeDuration="onChangeDuration"
                                        />
                                    </v-col>
                                    <v-col cols="12" :lg="true" class="ds-modal-input-container">
                                        <time-picker
                                            v-model="shiftTime.endTime"
                                            label="End Time"
                                            :disabled="!start_time"
                                            :error-messages="api.hasError('end_time')"
                                            :hint="end_time_hint"
                                            :key="endTimeKey"
                                            @onClearTime="onClearEndTime"
                                            @onUpdateTime="onConfirmEndTime"/>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                        <v-col cols="12" md="6" :style="rhsContainerStyle">
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12" class="ds-modal-input-container">
                                        <p class="text--h4">
                                            Shift Details
                                        </p>
                                    </v-col>
                                    <v-col cols="12" class="ds-modal-input-container">
                                        <v-autocomplete
                                            v-model="selectedDepartmentId"
                                            label="Department"
                                            :items="departments"
                                            :loading="departmentsLoading"
                                            :disabled="departmentsLoading"
                                            :rules="rules.required"
                                            :readonly="editMode"
                                            item-value="_id"
                                            item-text="name"
                                            :clearable="!editMode"
                                            outlined
                                            @change="onChangeDepartment"
                                        >
                                            <template v-slot:prepend-inner>
                                                <v-icon color="primary">mdi-domain</v-icon>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" class="ds-modal-input-container">
                                        <v-autocomplete
                                            v-model="selectedStaffGroupId"
                                            :key="staffGroupKey"
                                            label="Staff Group"
                                            :items="staffGroups"
                                            :loading="staffGroupsLoading"
                                            :disabled="staffGroupsDisabled"
                                            :rules="rules.required"
                                            :readonly="editMode"
                                            :hint="staffGroupsHint"
                                            persistent-hint
                                            item-value="_id"
                                            item-text="name"
                                            outlined
                                            :clearable="!editMode"
                                        >
                                            <template v-slot:prepend-inner>
                                                <v-icon color="primary">mdi-account-group</v-icon>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="onClose"
                    :disabled="isCreating"
                >
                    Close
                </v-btn>
                <v-btn
                    color="primary"
                    outlined
                    @click="onNext"
                    :loading="isCreating"
                >
                    {{ editMode ? 'Save' : 'Next' }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <unsaved-changes-dialog ref="unsavedChangesDialogRef"/>
    </v-dialog>
</template>

<script>
import TimePickerDialog from "../../../components/modals/TimePickerDialog";
import NumberInput from "../../../components/common/NumberInput/NumberInput";
import TimePicker from "../../../components/common/TimeRangePicker/TimePicker";
import DurationInput from "../../../components/common/TimeRangePicker/DurationInput";
import UnsavedChangesDialog from "../../../components/modals/UnsavedChangesDialog";
import validationRules from "../../../lib/mixins/validationRules"
import timeRangePickerMethods from "../../../lib/mixins/timeRangePickerMethods"

export default {
    name: "AddAutomatedShiftModal",
    components: {TimePickerDialog, NumberInput, TimePicker, DurationInput, UnsavedChangesDialog},
    mixins: [validationRules, timeRangePickerMethods],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        staffingAutomation: {
            type: Object,
            default: () => ({})
        },
        editMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            startDate: null,
            coverageWeeks: null,
            runDate: null,
            selectedShiftTimeId: null,
            shiftTimes: [],
            shiftTimesLoading: false,
            timePickerModal: false,
            shiftTime: {},
            startTimeKey: 1111,
            durationKey: 2222,
            endTimeKey: 3333,
            customTokens: {
                'h': {pattern: /[0-3]/},
                'H': {pattern: /[0-9]/},
                'm': {pattern: /[0-5]/},
                'M': {pattern: /[0-9]/}
            },
            selectedDepartmentId: null,
            departments: [],
            departmentsLoading: false,
            selectedStaffGroupId: null,
            staffGroups: [],
            staffGroupsLoading: false,
            staffGroupKey: 3333,
            isCreating: false,
            matchAgainst: {
                startDate: null,
                coverageWeeks: null,
                runDate: null,
                selectedShiftTimeId: null,
                shiftTime: {},
                selectedDepartmentId: null,
                selectedStaffGroupId: null,
            },
            innerWidth: window.innerWidth,
            api: new formHelper(),
        }
    },
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        startDateTextFieldProps() {
            let value = null
            if (this.startDate) {
                const endMoment = this.genMoment(this.startDate)
                value = endMoment.format('MM/DD/YY')
            }
            return {
                label: 'Start Date',
                value,
                outlined: true,
                readonly: this.editMode
            }
        },
        runDateTextFieldProps() {
            let value = null
            if (this.runDate) {
                const endMoment = this.genMoment(this.runDate)
                value = endMoment.format('MM/DD/YY')
            }
            return {
                label: 'Run Date',
                value,
                outlined: true,
                readonly: this.editMode
            }
        },
        intervalRules() {
            return [
                v => !!v || 'Required',
                v => v > 0 || 'Must be greater than 0',
                v => v <= 4 || 'Can not be greater than 4'
            ]
        },
        formattedStartTime() {
            if (typeof this.shiftTime.startTime === 'undefined' || !this.shiftTime.startTime) {
                return ''
            }

            let time = moment(moment().format('YYYY-MM-DD') + ' ' + this.shiftTime.startTime)
            return time.format('h:mm A')
        },
        start_time() {
            return typeof this.shiftTime.startTime !== 'undefined' ? this.shiftTime.startTime : null
        },
        duration() {
            return typeof this.shiftTime.duration !== 'undefined' ? this.shiftTime.duration : null
        },
        start_date_time() {
            if (!this.start_time) {
                return null
            }

            return moment(moment().format('YYYY-MM-DD') + ' ' + this.start_time)
        },
        end_date_time() {
            if (!this.start_date_time || !this.duration) {
                return null
            }

            return this.start_date_time.clone().add(this.duration, 'm')
        },
        end_time() {
            if (!this.end_date_time) {
                return null
            }

            return this.end_date_time.format('h:mm A')
        },
        end_time_hint() {
            if (!this.start_time) {
                return 'Set a start time'
            }

            if (!this.duration) {
                return ''
            }

            if (!this.end_date_time.isSame(this.start_date_time, 'd')) {
                let diff = this.end_date_time.clone().startOf('day').diff(this.start_date_time.clone().startOf('day'), 'd')
                return diff > 0 ? ('+ ' + diff + ' ' + this.$options.filters.pluralize('Day', diff)) : ''
            }

            return ''
        },
        allowedStartDates() {
            return date => {
                const now = this.genMoment(moment().format('YYYY-MM-DD'))
                const startDate = this.genMoment(date)
                const minThreeDaysOut = startDate.isSameOrAfter(now.clone().add(3, 'days'), 'days')
                const maxThreeYearsOut = startDate.isBefore(now.clone().add(3, 'years'), 'days')
                if (this.runDate) {
                    const runDate = this.genMoment(this.runDate)
                    return minThreeDaysOut && maxThreeYearsOut && runDate.isSameOrBefore(startDate.subtract(2, 'days'), 'days')
                }
                return minThreeDaysOut && maxThreeYearsOut
            }
        },
        allowedRunDates() {
            return date => {
                const now = this.genMoment(moment().format('YYYY-MM-DD'))
                const runDate = this.genMoment(date)
                const minOneDayOut = runDate.isSameOrAfter(now.clone().add(1, 'days'), 'days')
                const maxThreeYearsOut = runDate.isBefore(now.clone().add(3, 'years'), 'days')
                if (this.startDate) {
                    const startDate = this.genMoment(this.startDate)
                    return minOneDayOut && maxThreeYearsOut && runDate.isSameOrBefore(startDate.subtract(2, 'days'), 'days')
                }
                return minOneDayOut && maxThreeYearsOut
            }
        },
        staffGroupsHint() {
            if (this.selectedDepartmentId) {
                return ''
            }
            return '(Select a Department to View)'
        },
        staffGroupsDisabled() {
            return this.staffGroupsLoading || !this.selectedDepartmentId
        },
        globalLocation() {
            return this.$root.globalLocation
        },
        globalTimezone() {
            return this.$root.globalTimezone
        },
        addAutomatedShiftFormRef() {
            return this.$refs?.addAutomatedShiftForm
        },
        isDirty() {
            return !_.isEqual({
                startDate: this.startDate,
                coverageWeeks: this.coverageWeeks,
                runDate: this.runDate,
                selectedShiftTimeId: this.selectedShiftTimeId,
                shiftTime: this.shiftTime,
                selectedDepartmentId: this.selectedDepartmentId,
                selectedStaffGroupId: this.selectedStaffGroupId,
            }, this.matchAgainst)
        },
        unsavedChangesDialogRef() {
            return this.$refs.unsavedChangesDialogRef
        },
        showTimezoneTooltip() {
            if (this.globalLocation && this.globalTimezone) {
                return this.globalLocation.timezone !== this.globalTimezone
            }
            return false
        },
        rhsContainerStyle() {
            return {
                ...this.innerWidth >= 960 && {
                    'border-left': '1px solid #000000'
                }
            }
        }
    },
    methods: {
        initStaffingAutomation() {
            if (this.editMode) {
                const startDate = moment(this.staffingAutomation.shift_start).tz(this.staffingAutomation.timezone).format('YYYY-MM-DD')
                const coverageWeeks = this.staffingAutomation.coverage_weeks
                const runDate = moment(this.staffingAutomation.start_date).tz(this.staffingAutomation.timezone).format('YYYY-MM-DD')
                const selectedShiftTimeId = this.staffingAutomation.shift_time_id ?? null
                const shiftTime = {
                    startTime: this.staffingAutomation.start_time,
                    duration: this.staffingAutomation.duration,
                    endTime: this.staffingAutomation.end_time,
                }
                const selectedDepartmentId = this.staffingAutomation.department_id
                const selectedStaffGroupId = this.staffingAutomation.staff_group_id
                this.startDate = startDate
                this.coverageWeeks = coverageWeeks
                this.runDate = runDate
                this.selectedShiftTimeId = selectedShiftTimeId
                this.shiftTime = {...shiftTime}
                this.durationKey = this.durationKey + 1
                this.selectedDepartmentId = selectedDepartmentId
                this.fetchStaffGroups(selectedDepartmentId)
                this.selectedStaffGroupId = selectedStaffGroupId
                this.matchAgainst = {
                    startDate,
                    coverageWeeks,
                    runDate,
                    selectedShiftTimeId,
                    shiftTime: {...shiftTime},
                    selectedDepartmentId,
                    selectedStaffGroupId,
                }
            }
        },
        fetchShiftTimes() {
            this.shiftTimesLoading = true
            this.api.get('/staffing-automation/modal-options-data/shift_time')
                .then(data => {
                    const shiftTimes = data?.data
                    if (Array.isArray(shiftTimes)) {
                        this.shiftTimes = Array.from(shiftTimes)
                    }
                })
                .catch(console.log)
                .finally(() => {
                    this.shiftTimesLoading = false
                })
        },
        onChangeShiftTime(shiftTimeId) {
            if (shiftTimeId) {
                const shiftTime = this.shiftTimes.find(shiftTime => shiftTime._id === shiftTimeId)
                if (shiftTime) {
                    this.setTimes(shiftTime.start_time, shiftTime.duration, shiftTime.end_time)
                }
            } else {
                this.setTimes(null, null, null)
            }
        },
        onClearStartTime() {
            this.shiftTime = {
                startTime: null,
                duration: null,
                endTime: null,
            }
            this.selectedShiftTimeId = null
            this.durationKey = this.durationKey + 1
        },
        onConfirmStartTime(data) {
            if (this.shiftTime?.endTime) {
                this.setDuration(data, this.shiftTime.endTime)
            } else if (this.duration) {
                this.setEndTime(data, this.duration)
            }
            this.selectedShiftTimeId = null
        },
        onChangeDuration(duration) {
            if (!duration) {
                this.shiftTime = {
                    ...this.shiftTime,
                    endTime: null,
                }
            } else {
                this.setEndTime(this.start_time, duration)
            }
            this.selectedShiftTimeId = null
        },
        onClearEndTime() {
            this.shiftTime = {
                ...this.shiftTime,
                endTime: null,
            }
            this.selectedShiftTimeId = null
        },
        onConfirmEndTime(data) {
            this.setDuration(this.start_time, data)
            this.selectedShiftTimeId = null
        },
        setTimes(startTime, duration, endTime) {
            this.shiftTime = {
                startTime,
                duration,
                endTime,
            }
            this.startTimeKey = this.startTimeKey + 1
            this.durationKey = this.durationKey + 1
            this.endTimeKey = this.endTimeKey + 1
        },
        setDuration(start_time, end_time) {
            this.shiftTime = {
                ...this.shiftTime,
                duration: this.getUpdatedDuration(start_time, end_time)
            }
            this.durationKey = this.durationKey + 1
        },
        setEndTime(start_time, duration) {
            const end = this.getUpdatedEndTime(start_time, duration)
            if (end) {
                this.shiftTime = {
                    ...this.shiftTime,
                    endTime: end
                }
            }
        },
        fetchDepartments() {
            this.departmentsLoading = true
            const params = {
                'location_id': this.editMode && this.staffingAutomation ? this.staffingAutomation.location._id : this.globalLocation._id,
                ...(this.editMode && this.staffingAutomation && {
                    'staffing_automation_id': this.staffingAutomation._id
                })
            }

            this.api.get(`/staffing-automation/modal-options-data/department`, {params})
                .then(data => {
                    const departments = data?.data
                    if (Array.isArray(departments)) {
                        this.departments = Array.from(departments)
                    }
                })
                .catch(console.log)
                .finally(() => {
                    this.departmentsLoading = false
                })
        },
        onChangeDepartment(departmentId) {
            if (departmentId) {
                this.fetchStaffGroups(departmentId)
            }
            this.selectedStaffGroupId = null
            this.staffGroupKey = this.staffGroupKey + 1
        },
        fetchStaffGroups(departmentId) {
            this.staffGroupsLoading = true
            const params = {
                'location_id': this.editMode && this.staffingAutomation ? this.staffingAutomation.location._id : this.globalLocation._id,
                'department_id': departmentId,
                ...(this.editMode && this.staffingAutomation && {
                    'staffing_automation_id': this.staffingAutomation._id
                })
            }
            this.api.get(`/staffing-automation/modal-options-data/staff_group`, {params})
                .then(data => {
                    const staffGroups = data?.data
                    if (Array.isArray(staffGroups)) {
                        this.staffGroups = Array.from(staffGroups)
                    }
                })
                .catch(console.log)
                .finally(() => {
                    this.staffGroupsLoading = false
                })
        },
        close() {
            this.modalValue = false
        },
        onClose() {
            if (this.isDirty) {
                return this.unsavedChangesDialogRef.open()
                    .then(() => {
                        this.close()
                    })
                    .catch(() => {
                    })

            } else {
                this.close()
            }
        },
        onNext() {
            if (!this.validateForm()) {
                return
            }
            if (this.editMode) {
                this.isCreating = true
                this.api.post(`/staffing-automation/update/${this.staffingAutomation._id}`, {
                    start_time: this.start_time,
                    end_time: this.end_date_time.format('HH:mm'),
                    duration: this.duration,
                    shift_time_id: this.selectedShiftTimeId,
                })
                    .then(({data, message}) => {
                        message('Staffing Automation Updated')
                        this.$emit('onUpdate', data)
                        this.close()
                    })
                    .catch(console.log)
                    .finally(() => {
                        this.isCreating = false
                    })
            } else {
                this.isCreating = true
                this.api.post('/staffing-automation/create', {
                    run_date: this.runDate,
                    start_date: this.startDate,
                    start_time: this.start_time,
                    end_time: this.end_date_time.format('HH:mm'),
                    timezone: this.globalLocation?.timezone,
                    duration: this.duration,
                    shift_time_id: this.selectedShiftTimeId,
                    coverage_weeks: this.coverageWeeks,
                    location_id: this.globalLocation?._id,
                    department_id: this.selectedDepartmentId,
                    staff_group_id: this.selectedStaffGroupId,
                })
                    .then(({data, message}) => {
                        message('Staffing Automation Created')
                        this.$emit('onNext', data._id, this.startDate, this.runDate)
                        this.close()
                    })
                    .catch(console.log)
                    .finally(() => {
                        this.isCreating = false
                    })
            }
        },
        validateForm() {
            if (this.addAutomatedShiftFormRef) {
                return this.addAutomatedShiftFormRef.validate()
            }
            return false
        },
        genMoment(date) {
            return moment(date, 'YYYY-MM-DD')
        },
        onResize() {
            this.innerWidth = window.innerWidth
        },
    },
    created() {
        this.initStaffingAutomation()
        this.fetchShiftTimes()
        this.fetchDepartments()
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize)
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    },
}
</script>

<style scoped>
.row-min-width {
    min-width: 250px;
}
</style>
