<template>
    <v-tooltip
        content-class="time-off-tooltip"
        right>
        <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
                <v-btn
                    icon
                >
                    <v-icon color="primary_text">mdi-information</v-icon>
                </v-btn>
            </div>
        </template>
        <div>
            <p class="text--paragraph">
                Time off requests are subject to approval by the Manager(s) responsible for your scheduling.
            </p>
        </div>
    </v-tooltip>
</template>

<script>
export default {
    name: "TimeOffTooltip"
}
</script>

<style scoped>
.time-off-tooltip {
    opacity: 1;
    font-size: 12px;
    width: 269px;
    padding: 10px 15px;
    background: #DFE0FF;
    border-radius: 6px;
    border: 1px solid #000000;
    color: #000;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .2), 0 2px 2px rgba(0, 0, 0, .14), 0 1px 5px rgba(0, 0, 0, .12);
}
</style>
