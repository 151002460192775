<template>
    <v-select
        v-model="selectedId"
        :items="items"
        item-value="_id"
        item-text="text"
        outlined
        :menu-props="{ 'offset-y': true }"
        hide-details
        style="padding-right: 5px; padding-bottom: 1px;"
    >
        <template v-slot:selection>
            <div class="sort-by-menu-text-container">
                <span class="sort-by-menu-text-ellipsis">
                    {{ itemText }}
                </span>
                <span class="ml-1">({{ sortText }}</span>
                <v-icon small>
                    {{ sortAsc ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                </v-icon>
                <span>
                    )
                </span>
            </div>
        </template>
        <template v-slot:item="{ item, on, attrs }">
            <v-list-item
                v-bind="attrs"
                v-on="on"
            >
                <v-list-item-content>
                    <v-list-item-title>
                        <div class="sort-by-menu-text-container">
                            <span>
                                {{ item.text }} ({{ item.sortText }}
                            </span>
                            <v-icon small>
                                {{ item.asc ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                            </v-icon>
                            <span>
                                )
                            </span>
                        </div>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-select>
</template>

<script>
export default {
    name: "SortByMenu",
    props: {
        value: {
            type: String,
            default: null,
        },
        items: {
            type: Array,
            default: () => ([])
        }
    },
    computed: {
        selectedId: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            },
        },
        selectedItem () {
            return this.items.find(item => item._id === this.selectedId)
        },
        itemText () {
            return this.selectedItem?.text ?? 'Staff Group'
        },
        sortText () {
            return this.selectedItem?.sortText ?? 'A'
        },
        sortAsc () {
            return this.selectedItem?.asc
        }
    }
}
</script>

<style scoped>
    .sort-by-menu-text-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: 0;
    }

    .sort-by-menu-text-ellipsis {
        min-width: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
</style>
