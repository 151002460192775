<template>
    <v-card class="elevation-0">
        <v-toolbar class="ds-toolbar" elevation="0">
            <v-icon color="primary" class="mr-3">mdi-domain</v-icon>
            <v-toolbar-title>{{ department_name }}</v-toolbar-title>
            <v-btn
                class="ds-toolbar-fab-button"
                fab
                small
                elevation="1"
                :disabled="department_details_loading || !hasEditDepartmentPermission"
                @click="onEditDepartment"
            >
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-spacer/>
            <v-btn
                color="primary"
                elevation="0"
                outlined
                @click="onBackToLocation"
            >
                Back to Location
            </v-btn>
        </v-toolbar>

        <v-container fluid class="pt-4 px-6">
            <v-row>
                <v-col cols="12">
                    <p>{{ department_description }}</p>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="8">
                    <h4 class="ds-h4">Staffing Details</h4>
                    <p class="ds-caption mt-2">Authorize specific Job Titles from Staff Groups to work in this Department.</p>
                </v-col>
                <v-col cols="12" md="4" class="text-right">
                    <v-btn
                        color="secondary"
                        :disabled="!hasAuthStaffGroupPermission"
                        @click="onAddStaffGroup"
                    >
                        Add Staff Group
                    </v-btn>
                </v-col>
            </v-row>

            <v-row v-if="department_details_loading">
                <v-col cols="12">
                    <staffing-details-loading-skeleton/>
                </v-col>
            </v-row>

            <v-row v-else>
                <v-col cols="12">
                    <staffing-details-panels ref="staffingDetailsPanels"/>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-divider class="mt-2 mb-4"/>
                    <h4 class="ds-h4">Custom Fields</h4>
                    <p class="ds-caption mt-2">These fields are populated from the Configuration Options in your Organization.</p>
                </v-col>
            </v-row>

            <v-row v-if="department_details_loading">
                <v-col cols="12" md="6">
                    <custom-fields-loading-skeleton/>
                </v-col>
            </v-row>

            <v-row v-else-if="selectedDepartment">
                <v-col
                    cols="12"
                    md="6"
                    v-for="(custom_field, index) in custom_fields"
                    :key="index"
                >
                    <custom-field
                        :custom-field="custom_field"
                        :owner-entity-id="selectedDepartment._id"
                        context="department"
                        :has-permission="hasEditDepartmentPermission"
                        @update="onCustomFieldUpdate"
                        :ref="'customFieldComponent_' + index"
                    />
                </v-col>
            </v-row>
        </v-container>

        <department-modal ref="editDepartmentModal"/>
        <unsaved-changes-dialog ref="unsavedChangesModal"/>
    </v-card>
</template>

<script>
import CustomFieldsLoadingSkeleton from "../../../components/custom_fields/CustomFieldsLoadingSkeleton";
import CustomField from "../../../components/custom_fields/CustomField";
import UnsavedChangesDialog from "../../../components/modals/UnsavedChangesDialog";
import DepartmentModal from "./DepartmentModal";
import StaffingDetailsPanels from "./Staffing Details/StaffingDetailsPanels";
import StaffingDetailsLoadingSkeleton from "./Staffing Details/StaffingDetailsLoadingSkeleton";

export default {
    name: "DepartmentWindow",
    components: {
        StaffingDetailsLoadingSkeleton,
        StaffingDetailsPanels, DepartmentModal, UnsavedChangesDialog, CustomField, CustomFieldsLoadingSkeleton},
    props: {
        selectedDepartment: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            api: new formHelper,
            department_details_loading: false,
            department: {},
            department_updated: false,
            custom_fields: [],
        }
    },
    computed: {
        department_name() {
            if (!this.selectedDepartment) {
                return 'Department'
            }

            if (typeof this.department.name === 'undefined' || !this.department.name) {
                return this.selectedDepartment.name
            }

            return this.department.name
        },

        department_description() {
            if (typeof this.department.description === 'undefined' || !this.department.description) {
                return
            }

            return this.department.description
        },
        hasAuthStaffGroupPermission () {
            return this.$userHasPermission(this.$user, this.$config.permissions.DEPARTMENTS.AUTHORIZE_STAFF_GROUP)
        },
        hasEditDepartmentPermission () {
            return this.$userHasPermission(this.$user, this.$config.permissions.DEPARTMENTS.EDIT)
        },
    },
    methods: {
        resetWindowData() {
            this.department_updated = false
        },

        onBackToLocation() {
            if (this.hasUnsavedData()) {
                this.showUnsavedChangesDialog()
            } else {
                this.goBackToLocationWindow()
            }
        },

        goBackToLocationWindow() {
            this.$emit('back:location', {department_updated: this.department_updated})
            this.resetWindowData()
        },

        onEditDepartment() {
            this.$refs.editDepartmentModal.open({department_id: this.selectedDepartment._id})
                .then(({action}) => {
                    if (action === 'updated') {
                        this.department_updated = true
                        this.loadDepartmentDetails()
                    }
                })
                .catch(() => {})
        },

        loadDepartmentDetails() {
            this.department_details_loading = true

            this.api.get('/department/' + this.selectedDepartment._id)
                .then(({data}) => {
                    const {
                        department,
                        staffing_details,
                        custom_fields,
                        staff_groups_for_location,
                        managers_for_location
                    } = data

                    this.department = department
                    this.custom_fields = custom_fields

                    this.$store.dispatch('setCurrentDepartment', department)
                    this.$store.dispatch('setStaffingDetailsByStaffGroup', staffing_details)
                    this.$store.dispatch('setManagersInCurrentLocation', managers_for_location)
                    this.$store.dispatch('setStaffGroupsInCurrentLocation', staff_groups_for_location)

                })
                .finally(() => {
                    this.department_details_loading = false
                })
        },

        onCustomFieldUpdate({custom_field_id, value}) {
            if (!this.hasEditDepartmentPermission) {
                return
            }

            this.custom_fields = this.custom_fields.map(custom_field => {
                if (custom_field._id === custom_field_id) {
                    return {...custom_field, value: value}
                }

                return custom_field
            })

            this.department_updated = true
        },

        onAddStaffGroup() {
            this.$store.dispatch('addEmptyStaffingDetailGroup')
            this.$refs.staffingDetailsPanels.expandLastPanel()
        },

        hasUnsavedCustomFields() {
            let unsaved_custom_fields = 0
            for (let i = 0; i < this.custom_fields.length; i++) {
                let reference = this.$refs['customFieldComponent_' + i][0]

                if (reference.unsaved_changes) {
                    unsaved_custom_fields++
                }
            }

            return unsaved_custom_fields
        },

        hasUnsavedStaffingDetails() {
            const persisted_staffing_details_by_staff_group = this.getStaffingDetailsCompareArray(this.$store.getters.persistedStaffingDetailsByStaffGroup)
            const current_staffing_details_by_staff_group = this.getStaffingDetailsCompareArray(this.$store.getters.staffingDetailsByStaffGroup)

            return !_.isEqual(persisted_staffing_details_by_staff_group, current_staffing_details_by_staff_group)
        },

        getStaffingDetailsCompareArray(staffing_details_by_staff_group) {
            return staffing_details_by_staff_group.map(item => {
                let staffing_details = []
                if (item.staffing_details.length) {
                    staffing_details = item.staffing_details.map(staffing_detail => {
                        let id = typeof staffing_detail._id !== 'undefined' && staffing_detail._id ? staffing_detail._id : null
                        let managers = staffing_detail.managers.map(manager => ({id: manager.id}))

                        return {
                            _id: id,
                            job_title_id: staffing_detail.job_title_id,
                            staff_group_id: staffing_detail.staff_group_id,
                            managers: managers
                        }
                    })
                }

                return {
                    staff_group_id: item.staff_group_id,
                    staffing_details: staffing_details
                }
            })
        },

        hasUnsavedData() {
            return this.hasUnsavedCustomFields() || this.hasUnsavedStaffingDetails()
        },

        showUnsavedChangesDialog() {
            this.$refs.unsavedChangesModal.open()
                .then(() => {
                    this.loadDepartmentDetails()
                    this.goBackToLocationWindow()
                })
                .catch(() => {})
        }
    },
    watch: {
        selectedDepartment: {
            immediate: true,
            handler(department) {
                if (department && typeof department._id !== 'undefined') {
                    this.loadDepartmentDetails()
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
