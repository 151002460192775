<template>
    <v-tooltip
        content-class="utilization-rate-tooltip"
        right>
        <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
                <v-btn
                    icon
                >
                    <v-icon color="primary_text">mdi-information</v-icon>
                </v-btn>
            </div>
        </template>
        <div>
            <p class="text--paragraph">
                <b>Utilization Rate</b> is calculated as the number of hours a Staff Member is scheduled, divided by
                their
                total available hours in that period.
            </p>
            <p class="text--paragraph">
                For example, a Staff Member scheduled for 30 hours per week, but available for 40 hours per week would
                have a <b>Utilization Rate</b> of 75%.
            </p>
        </div>
    </v-tooltip>
</template>

<script>
export default {
    name: "UtilizationRateTooltip"
}
</script>

<style scoped>
.utilization-rate-tooltip {
    opacity: 1;
    font-size: 12px;
    width: 364px;
    padding: 10px 15px;
    background: #DFE0FF;
    border-radius: 6px;
    border: 1px solid #000000;
    color: #000;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .2), 0 2px 2px rgba(0, 0, 0, .14), 0 1px 5px rgba(0, 0, 0, .12);
}
</style>
